import React, { Component } from 'react';
import moment from 'moment';
import Select from 'react-select';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { getPastConferences, startExportLogsTask } from 'api/calllogs';
import { getHealthSystems } from 'api/users';
import { Table, Grid, Badge, Modal } from 'components';
import Pagination from 'components/Common/Pagination';
import CallDetails from 'components/CallLogs/CallDetails';
import { calculateDuration, formatDate, getDateDifference, unixToDate, utcToLocalTime } from 'infrastructure/helpers/dateHelper';
import { ObjectType, CallStatus, DeviceListLevel, CallTypes, ParticipantState, UserRoles, CallTypesMessage } from 'constants/enums';
import { getUserProfile, getUserRole } from 'infrastructure/auth';
import { getHealthSystemHospitals, getHospitalRooms } from 'api/organization';
import classNames from 'classnames';

class PastCallsList extends Component {
	state = {
		logs: [],
		loading: true,
		pageSize: { value: 10, label: '10' },
		pageIndex: 0,
		totalCount: 0,
		from: undefined,
		to: undefined,
		selectedHealthSystem: null,
		selectedHospital: null,
		selectedRoom: null,
		healthSystems: [],
		hospitals: [],
		rooms: [],
		currentLevel: DeviceListLevel.ORGANIZATION,
		currentTeamId: null,
		showCallQueueModal: false,
		showCallDetailsModal: false,
		showListOfRoomsModal: false,
		currentCallLogQueue: [],
		selectedConference: {},
		isExportLogsModalOpen: false,
		hasDateError: false,
		isExportLogsModalLoading: false,
		hasExportLogsModalErrors: false,
	};

	toDateInput = React.createRef();

	userProfile = getUserProfile();

	maximumRangeOfDays = 90;

	async componentDidMount() {
		const healthSystems = await getHealthSystems();
		const healthSystemsOptions = healthSystems.map(hs => {
			return { value: hs.id, label: hs.name };
		});

		if (getUserRole() === UserRoles.ADMIN) {
			const end = new Date();
			const start = new Date();
			start.setMonth(start.getMonth() - 1);
			const params = {
				pageSize: this.state.pageSize?.value || 10,
				pageIndex: this.state.pageIndex || 0,
				start: this.getDate(start.setHours(0, 0, 0, 0)),
				end: this.getDate(end.setHours(23, 59, 59, 0)),
			};
			const result = await getPastConferences(params);
			let stateObj = { loading: false };
			if (!result.error) {
				const logs = this.getLogs(result.conferences);
				stateObj = {
					...stateObj,
					...{
						logs,
						totalCount: result.totalCount,
						pageIndex: result.pageIndex,
						healthSystems: healthSystemsOptions,
						from: start,
						to: end,
					},
				};
			}

			this.setState(stateObj);
		} else {
			this.filterByLevel(healthSystems[0].id, DeviceListLevel.HEALTHSYSTEM);

			const hospitals = await getHealthSystemHospitals(healthSystems[0].id);
			let hospitalOptions = [];
			if (!hospitals.error) {
				hospitalOptions = this.transformTeamArray(hospitals);
			}
			this.setState({
				selectedHealthSystem: { value: healthSystems[0].id, label: healthSystems[0].name },
				currentLevel: DeviceListLevel.HEALTHSYSTEM,
				currentTeamId: healthSystems[0].id,
				healthSystems: healthSystemsOptions,
				hospitals: hospitalOptions,
			});
		}
	}

	getCallType = (callType, initiator) => {
		switch (callType) {
			case CallTypes.AUDIO && initiator === 'Virtual Care Provider':
				return CallTypesMessage.TALK_TO_PATIENT;
			case CallTypes.AUDIO && initiator === 'Patient':
				return CallTypesMessage.TALK_TO_NURSE;
			case CallTypes.VIDEO:
				return CallTypesMessage.VIDEO_CALL;
			case CallTypes.SECURITYCAM:
				return CallTypesMessage.PATIENT_VIEW;
			case CallTypes.MONITORING:
				return CallTypesMessage.MONITORING;
			default:
				return undefined;
		}
	};

	isSuccessfulCall(status) {
		if (status === CallStatus.FAILED.type || status === CallStatus.MISSED.type || status === CallStatus.ABORTED.type) return false;

		return true;
	}

	getLogs(conferences) {
		let filtered = conferences.filter(conference => {
			return typeof this.getVirtualCareProvider(conference.participants, conference.status) !== 'undefined' && this.getPatientRoom(conference.participants);
		});

		return filtered.map(conference => {
			let room = this.getPatientRoom(conference.participants);
			let vcp = this.getVirtualCareProvider(conference.participants, conference.status);
			let status = this.getCallStatus(conference.status);
			let startedAt = moment(utcToLocalTime(conference.startedAt)).format('MM/DD/YYYY-hh:mm A');
			let endedAt = moment(utcToLocalTime(conference.endedAt)).format('MM/DD/YYYY-hh:mm A');
			let initiator = vcp.isInitiator ? 'Virtual Care Provider' : 'Patient';

			return {
				id: conference.id,
				initiator: initiator,
				provider: room.isInitiator && !this.isSuccessfulCall(conference.status) ? 'N/A' : vcp.name,
				room: room.name,
				startedAt: startedAt,
				endedAt: endedAt,
				origin: vcp.isInitiator ? 'Outgoing' : 'Incoming',
				status: status,
				callType: this.getCallType(conference.callType, initiator),
				duration: conference.callDuration,
				usedAudio: { room: room.usedAudio, vcp: vcp.usedAudio },
				usedVideo: { room: room.usedVideo, vcp: vcp.usedVideo },
				usedScreen: { room: room.usedScreen, vcp: vcp.usedScreen },
				participants: conference.participants,
				queue: room.isInitiator ? conference.participants.filter(participant => participant.objectType === ObjectType.USER) : [],
			};
		});
	}

	getMonitoredRooms(participants) {
		return participants.reduce((acc, value) => {
			if (value.objectType === ObjectType.HELLO_DEVICE) {
				return `${acc}${acc && ','}${value.name}`;
			}
			return acc;
		}, '');
	}

	getTableRows() {
		if (this.state.logs.length === 0) return [];
		return this.state.logs.map(log => {
			return {
				conferenceId: log.id,
				// initiator: log.initiator,
				provider: log.provider,
				room:
					log.callType === CallTypesMessage.MONITORING ? (
						<span data-cy='listOfRooms' style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => this.openListOfRoomsModal(log)}>
							View All
						</span>
					) : (
						log.room
					),
				date: (
					<div style={{ fontSize: '12px' }}>
						<span style={{ display: 'block' }}>{log.startedAt}</span>
						<span style={{ display: 'block' }}>{log.endedAt}</span>
					</div>
				),
				// origin: log.origin,
				status: this.getStatusBadge(log.status),
				duration: log.duration,
				callType: log.callType,
				details: (
					<span data-cy='callDetails' style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => this.openCallDetailsModal(log)}>
						View Details
					</span>
				),
				// queue:
				// 	log.queue.length > 0 ? (
				// 		<span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => this.openCallLogQueueModal(log.queue)}>
				// 			View All
				// 		</span>
				// 	) : (
				// 		'N/A'
				// 	),
			};
		});
	}

	openCallLogQueueModal(participants) {
		this.setState({
			showCallQueueModal: true,
			currentCallLogQueue: participants,
		});
	}

	openCallDetailsModal(conference) {
		this.setState({
			showCallDetailsModal: true,
			selectedConference: conference,
		});
	}

	startExportTask = async () => {
		this.setState({
			isExportLogsModalLoading: true,
		});
		let type;
		switch (this.state.currentLevel) {
			case DeviceListLevel.ORGANIZATION:
				type = null;
				break;
			case DeviceListLevel.HEALTHSYSTEM:
				type = this.state.selectedHealthSystem.value;
				break;
			case DeviceListLevel.HOSPITAL:
				type = this.state.selectedHospital.value;
				break;
			case DeviceListLevel.ROOM:
				type = this.state.selectedRoom.value;
				break;
			default:
				type = null;
		}

		const { error } = await startExportLogsTask({
			level: this.state.currentLevel,
			id: type,
			start: this.state.from,
			end: this.state.to,
		});

		this.setState({
			isExportLogsModalLoading: false,
			hasExportLogsModalErrors: !!error,
			isExportLogsModalOpen: !!error,
		});
	};

	getVirtualCareProvider(participants, status) {
		if (status === CallStatus.SUCCESSFUL.type && participants.length > 2) {
			return participants.find(participant => {
				return (
					participant.objectType === ObjectType.USER &&
					participant.isInitiator &&
					(participant.state === ParticipantState.CONNECTED.type ||
						participant.state === ParticipantState.LEFT_CALL.type ||
						participant.state === ParticipantState.CONNECTING.type ||
						participants.state === ParticipantState.DISRUPTED.type)
				);
			});
		}

		return participants.find(participant => {
			return participant.objectType === ObjectType.USER;
		});
	}

	getPatientRoom(participants) {
		return participants.find(participant => {
			return participant.objectType === ObjectType.HELLO_DEVICE;
		});
	}

	getCallStatus(status) {
		switch (status) {
			case CallStatus.UNDEFINED.type: {
				return CallStatus.UNDEFINED.message;
			}
			case CallStatus.SUCCESSFUL.type: {
				return CallStatus.SUCCESSFUL.message;
			}
			case CallStatus.PARTIALLYSUCCESSFUL.type: {
				return CallStatus.PARTIALLYSUCCESSFUL.message;
			}
			case CallStatus.FAILED.type: {
				return CallStatus.FAILED.message;
			}
			case CallStatus.DISRUPTED.type: {
				return CallStatus.DISRUPTED.message;
			}
			case CallStatus.PARTIALLYDISRUPTED.type: {
				return CallStatus.PARTIALLYDISRUPTED.message;
			}
			case CallStatus.MISSED.type: {
				return CallStatus.MISSED.message;
			}
			case CallStatus.ABORTED.type: {
				return CallStatus.ABORTED.message;
			}
			case CallStatus.DROPPED.type: {
				return CallStatus.DROPPED.message;
			}
			default:
				return undefined;
		}
	}

	getStatusBadge(status) {
		return <Badge text={status} variant={status === CallStatus.SUCCESSFUL.message ? 'green' : 'red'} />;
	}

	getDate(d) {
		return d ? new Date(d).getTime() / 1000 : undefined;
	}

	onChange(pageSize, pageIndex, start, end) {
		this.setState({ loading: true }, async () => {
			const params = {
				...this.getFilterParamsBasedOnLevel(this.state.currentTeamId, this.state.currentLevel),
				pageSize: pageSize ? pageSize.value : this.state.pageSize.value,
				pageIndex: pageIndex || 0,
				start: start ? this.getDate(start) : this.getDate(this.state.from),
				end: end ? this.getDate(end) : this.getDate(this.state.to),
			};
			const result = await getPastConferences(params);
			let stateObj = { loading: false };
			if (!result.error) {
				const logs = this.getLogs(result.conferences);

				stateObj = {
					...stateObj,
					...{
						logs,
						pageSize,
						pageIndex,
						totalCount: result.totalCount,
					},
				};
			}
			this.setState(stateObj);
		});
	}

	showFromMonth() {
		const { from, to } = this.state;
		if (!from) {
			return;
		}
		if (moment(to).diff(moment(from), 'months') < 2) {
			this.toDateInput.current.getDayPicker().showMonth(from);
		}
	}

	handleFromChange = from => {
		const diff = getDateDifference(from, this.state.to);
		this.setState({
			hasDateError: diff > this.maximumRangeOfDays,
			from,
		});
	};

	handleToChange = to => {
		const diff = getDateDifference(this.state.from, to);
		if (diff > this.maximumRangeOfDays) {
			this.setState({
				hasDateError: true,
				to,
			});
		} else {
			this.setState({ to, loading: true, hasDateError: false }, this.showFromMonth);
			this.onChange(this.state.pageSize, 0, this.state.from?.setHours(0, 0, 0, 0), to.setHours(23, 59, 59));
		}
	};

	handleResetClick = () => {
		this.setState({ from: undefined, to: undefined });
	};

	getFilterParamsBasedOnLevel = (value, type) => {
		let params = {};

		const foundRoom = this.state.rooms.find(item => item.value === value);

		switch (type) {
			case DeviceListLevel.HEALTHSYSTEM: {
				params = {
					healthSystemId: value,
				};
				break;
			}
			case DeviceListLevel.HOSPITAL: {
				params = {
					regionId: this.state.hospitals.find(item => item.value === value).regionId,
					healthSystemId: this.state.selectedHealthSystem.value,
					hospitalId: value,
				};
				break;
			}
			case DeviceListLevel.ROOM: {
				params = {
					departmentId: foundRoom.departmentId,
					floorId: foundRoom.floorId,
					regionId: this.state.hospitals.find(item => item.value === this.state.selectedHospital.value).regionId,
					healthSystemId: this.state.selectedHealthSystem.value,
					hospitalId: this.state.selectedHospital.value,
					roomId: value,
				};
				break;
			}
			default:
				params = {};
		}

		return params;
	};

	async filterByLevel(value, type) {
		this.setState({
			loading: true,
			pageIndex: 0,
		});

		const params = {
			...this.getFilterParamsBasedOnLevel(value, type),
			pageSize: this.state.pageSize?.value || 10,
			pageIndex: 0,
			start: this.state.from ? new Date(this.state.from).getTime() / 1000 : undefined,
			end: this.state.to ? new Date(this.state.to).getTime() / 1000 : undefined,
		};

		const result = await getPastConferences(params);
		let stateObj = { loading: false };
		if (!result.error) {
			const logs = this.getLogs(result.conferences);
			stateObj = { ...stateObj, ...{ logs, totalCount: result.totalCount } };
		}
		this.setState(stateObj);
	}

	async onHealthSystemSelected(value) {
		this.setState({
			selectedHealthSystem: value,
			selectedHospital: null,
			selectedRoom: null,
			hospitals: [],
			rooms: [],
			currentLevel: DeviceListLevel.HEALTHSYSTEM,
			currentTeamId: value.value,
		});

		this.filterByLevel(value.value, DeviceListLevel.HEALTHSYSTEM);

		let hospitals = await getHealthSystemHospitals(value.value);
		let hospitalOptions = [];
		if (!hospitals.error) {
			hospitalOptions = this.transformTeamArray(hospitals);
		}
		this.setState({ hospitals: hospitalOptions });
	}

	transformTeamArray = arr =>
		arr.map(h => ({
			value: h.id,
			label: h.name,
			...(h.regionId && {
				regionId: h.regionId,
			}),
			...(h.departmentId && {
				departmentId: h.departmentId,
			}),
			...(h.floorId && {
				floorId: h.floorId,
			}),
		}));

	async onHospitalSelected(value) {
		this.setState({
			selectedHospital: value,
			selectedRoom: null,
			rooms: [],
			currentLevel: DeviceListLevel.HOSPITAL,
			currentTeamId: value.value,
		});

		this.filterByLevel(value.value, DeviceListLevel.HOSPITAL);

		const { error, rooms } = await getHospitalRooms(this.state.selectedHealthSystem.value, value.value);

		if (!error) {
			const roomOptions = this.transformTeamArray(rooms);
			this.setState({ rooms: roomOptions });
		}
	}

	async onRoomSelected(value) {
		this.setState({
			selectedRoom: value,
			currentLevel: DeviceListLevel.ROOM,
			currentTeamId: value.value,
		});

		this.filterByLevel(value.value, DeviceListLevel.ROOM);
	}

	showListOfUnavailableNurses() {
		const headers = [{ title: 'Name' }, { title: 'Status' }];

		const unavailableNurses = this.state.currentCallLogQueue.map(nurse => {
			return {
				name: nurse.name,
				status: this.getParticipantState(nurse.state),
			};
		});

		return <Table isLoading={this.state.loading} headers={headers} rows={unavailableNurses} />;
	}

	getParticipantState(state) {
		switch (state) {
			case ParticipantState.CONNECTING.type: {
				return ParticipantState.CONNECTING.message;
			}
			case ParticipantState.CONNECTED.type: {
				return ParticipantState.CONNECTED.message;
			}
			case ParticipantState.BUSY.type: {
				return ParticipantState.BUSY.message;
			}
			case ParticipantState.DECLINED.type: {
				return ParticipantState.DECLINED.message;
			}
			case ParticipantState.OFFLINE.type: {
				return ParticipantState.OFFLINE.message;
			}
			case ParticipantState.LEFT_CALL.type: {
				return ParticipantState.LEFT_CALL.message;
			}
			case ParticipantState.NOT_ANSWERING.type: {
				return ParticipantState.NOT_ANSWERING.message;
			}
			case ParticipantState.CANT_CONNECT.type: {
				return ParticipantState.CANT_CONNECT.message;
			}
			case ParticipantState.DISRUPTED.type: {
				return ParticipantState.DISRUPTED.message;
			}
			case ParticipantState.REMOVED.type: {
				return ParticipantState.REMOVED.message;
			}
			default: {
				return 'N/A';
			}
		}
	}

	openListOfRoomsModal = conference => {
		this.setState({
			selectedConference: conference,
			showListOfRoomsModal: true,
		});
	};

	showListOfRooms = () => {
		const headers = [{ title: 'Room' }, { title: 'Status' }, { title: 'Added at' }, { title: 'Ended at' }, { title: 'Duration' }];
		const { selectedConference } = this.state;
		let rooms = [];
		if (selectedConference && selectedConference.participants) {
			rooms = selectedConference.participants.reduce((acc, participant) => {
				if (participant.objectType === ObjectType.HELLO_DEVICE) {
					const timeJoinedDate = utcToLocalTime(participant.timeJoined);
					const endedTimeDate = unixToDate(participant.endedTime);
					acc.push({
						room: participant.name,
						status: this.getParticipantState(participant.state),
						timeJoined: participant.timeJoined ? moment(timeJoinedDate).format('MM/DD/YYYY-hh:mm A') : 'N/A',
						endedTime: participant.endedTime ? moment(endedTimeDate).format('MM/DD/YYYY-hh:mm A') : 'N/A',
						duration: participant.timeJoined && participant.endedTime ? calculateDuration(timeJoinedDate, endedTimeDate) : 'N/A',
					});
				}
				return acc;
			}, []);
		}
		return <Table isLoading={this.state.loading} headers={headers} rows={rooms} tableEmptyText='No rooms at the moment.' />;
	};

	calendarInitialDate = () => new Date(new Date().setMonth(new Date().getMonth() - 1));

	onStartDateClick = from => {
		if (this.state.from) {
			this.setState({ from, to: null });
		}
		this.toDateInput.current.getInput().focus();
	};

	getDisabledDates = () => {
		let after = new Date();
		if (this.state.from) {
			const newDate = new Date(this.state.from);
			newDate.setDate(newDate.getDate() + 89);

			if (newDate < after) {
				after = newDate;
			}
		}
		if (this.state.to) {
			const newDate = new Date(this.state.to);
			newDate.setDate(newDate.getDate() - 89);
		}
		return { after, before: this.state.from };
	};

	render() {
		const tableHeaders = [
			{ title: 'ID' },
			// { title: 'Call Initiator' },
			{ title: 'Virtual Care Provider' },
			{ title: 'Room' },
			{ title: 'Date/Time' },
			// { title: 'Call Origin' },
			{ title: 'Call Status' },
			{ title: 'Call Duration' },
			{ title: 'Call Type' },
			{ title: 'Details' },
			// { title: 'Queue' },
		];
		const { from, to } = this.state;
		const modifiers = { start: from, end: to };
		const DropdownIndicator = () => {
			return <i className='material-icons-outlined'>arrow_drop_down</i>;
		};
		return (
			<div>
				<Table isLoading={this.state.loading} headers={tableHeaders} rows={this.state.loading ? [] : this.getTableRows()}>
					<Grid columns='repeat(6, 1fr)' gridGap='10px' vertAlign='center' justifyContent='start'>
						<Select
							value={this.state.selectedHealthSystem}
							placeholder='Health System'
							classNamePrefix='custom-select'
							options={this.state.healthSystems}
							components={{ DropdownIndicator }}
							onChange={value => this.onHealthSystemSelected(value)}
						/>
						<Select
							value={this.state.selectedHospital}
							placeholder='Hospital'
							classNamePrefix='custom-select'
							options={this.state.hospitals}
							components={{ DropdownIndicator }}
							onChange={value => this.onHospitalSelected(value)}
						/>
						<Select
							value={this.state.selectedRoom}
							placeholder='Room'
							classNamePrefix='custom-select'
							options={this.state.rooms}
							components={{ DropdownIndicator }}
							onChange={value => this.onRoomSelected(value)}
						/>
						<div>
							<div className='InputFromTo'>
								<i className='material-icons'>date_range</i>
								<DayPickerInput
									value={from}
									placeholder='--/--/----'
									format='LL'
									formatDate={formatDate}
									dayPickerProps={{
										initialMonth: this.calendarInitialDate(),
										selectedDays: [from, { from, to }],
										disabledDays: this.getDisabledDates(),
										toMonth: to,
										modifiers,
										numberOfMonths: 2,
										onDayClick: e => this.onStartDateClick(e),
									}}
									onDayChange={this.handleFromChange}
								/>{' '}
								-{' '}
								<span className='InputFromTo-to'>
									<DayPickerInput
										ref={this.toDateInput}
										value={to}
										placeholder='--/--/----'
										format='LL'
										formatDate={formatDate}
										dayPickerProps={{
											initialMonth: this.calendarInitialDate(),
											selectedDays: [from, { from, to }],
											disabledDays: this.getDisabledDates(),
											modifiers,
											month: from,
											fromMonth: from,
											numberOfMonths: 2,
										}}
										onDayChange={this.handleToChange}
									/>
									<i className='material-icons'>arrow_drop_down</i>
								</span>
							</div>
							{this.state.hasDateError && <p style={{ color: 'red', fontSize: 12 }}>Date difference cant be more than {this.maximumRangeOfDays} days</p>}
						</div>
						<div
							className={classNames('button download', this.state.to && this.state.from && !this.state.hasDateError ? '' : 'disabled')}
							data-cy='exportAsCSV'
							onClick={() => {
								this.setState({ isExportLogsModalOpen: true });
							}}>
							<i className='material-icons'>attach_email</i>Export to CSV
						</div>
					</Grid>
				</Table>
				<Pagination
					totalCount={this.state.totalCount}
					pageSize={this.state.pageSize}
					pageIndex={this.state.pageIndex}
					onChange={(pageSize, pageIndex) => this.onChange(pageSize, pageIndex)}
				/>
				<Modal
					display={this.state.showCallQueueModal}
					position='center'
					hideActionButtons={true}
					onModalSubmit={null}
					onModalClose={() => this.setState({ showCallQueueModal: false, currentCallLogQueue: [] })}>
					<form>
						<h3>Queue of nurses that were called</h3>
						{this.showListOfUnavailableNurses()}
					</form>
				</Modal>
				<Modal
					modalSelector='callDetailsModal'
					display={this.state.showCallDetailsModal}
					position='center'
					hideActionButtons={true}
					onModalSubmit={null}
					onModalClose={() => this.setState({ showCallDetailsModal: false, selectedConference: {} })}>
					<CallDetails selectedConference={this.state.selectedConference} />
				</Modal>
				<Modal
					modalSelector='listOfRoomsModal'
					display={this.state.showListOfRoomsModal}
					position='center'
					hideActionButtons={true}
					onModalSubmit={null}
					onModalClose={() => this.setState({ showListOfRoomsModal: false, selectedConference: {} })}>
					<form>
						<h3>List of Rooms</h3>
						{this.state.showListOfRoomsModal && this.showListOfRooms()}
					</form>
				</Modal>
				<Modal
					display={this.state.isExportLogsModalOpen}
					position='center'
					isLoading={this.state.isExportLogsModalLoading}
					onModalSubmit={this.startExportTask}
					submitButtonText='Continue'
					onModalClose={() => this.setState({ isExportLogsModalOpen: false, isExportLogsModalLoading: false, hasExportLogsModalErrors: false })}>
					<form>
						<h3>Export CSV Logs</h3>
						{!this.state.hasExportLogsModalErrors && (
							<p>The CSV export will be created in the background. Once finished, it will be sent to {this.userProfile.email} in an attachment.</p>
						)}
						{this.state.hasExportLogsModalErrors && <p>Something went wrong. Please try again later</p>}
					</form>
				</Modal>
			</div>
		);
	}
}

export default PastCallsList;
