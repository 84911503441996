import { IDENTITY_CONFIG, METADATA_OIDC } from 'constants/auth-constants';
import { localApi, APP_CONFIG } from 'constants/global-variables';
// @ts-ignore
import { Log, UserManager, WebStorageStateStore } from 'oidc-client';
import { getRoles } from 'api/roles';
import { getHealthSystems, checkIfMemberExists } from 'api/users';
import { UserRoles, UserTypes } from 'constants/enums';
import { getStorage } from './helpers/commonHelpers';

Log.logger = console;
// @ts-ignore
Log.level = Log.DEBUG;
let storageType = window !== window.parent ? 'sessionStorage' : 'localStorage';
let hasHashRefId = false;
if (window.location.hash) {
	const hash = window.location.hash.substring(1);
	if (hash) {
		const refId = hash.split('=');
		if (refId && refId.length && refId.length === 2) {
			hasHashRefId = true;
		}
	}
}
const currentUrlParams = new URL(window.location.href).searchParams;

if ((currentUrlParams && currentUrlParams.get('refToken')) || hasHashRefId) {
	storageType = 'sessionStorage';
}

const userManager = new UserManager({
	...IDENTITY_CONFIG,
	userStore: new WebStorageStateStore({ store: window[storageType] }),
	metadata: {
		...METADATA_OIDC,
	},
});

function clearLocalStorage() {
	getStorage().removeItem('access_token');
	getStorage().removeItem('id_token');
	getStorage().removeItem('userId');
	getStorage().removeItem('userProfile');
	getStorage().removeItem('userRole');
	getStorage().removeItem('memberExists');
	getStorage().removeItem('userRoleId');
	getStorage().removeItem('currentHealthSystemInfo');
}

export function setUser(data, userInfo, userRole, memberExistsInCompany, userRoleId) {
	getStorage().setItem('userId', data.sub);
	getStorage().setItem('userProfile', JSON.stringify(userInfo));
	getStorage().setItem('userRole', userRole);
	getStorage().setItem('userRoleId', userRoleId);
	getStorage().setItem('memberExists', memberExistsInCompany);
}

export function setTokens(authResult) {
	getStorage().setItem('access_token', authResult.accessToken);
	getStorage().setItem('id_token', authResult.idToken);
}

export const getHealthSystemInfo = () => {
	return JSON.parse(getStorage().getItem('currentHealthSystemInfo'));
};
async function setCurrentHealthSystem() {
	let allHealthSystems = await getHealthSystems();
	if (allHealthSystems.length !== 0) {
		let currentHealthSystemInfo = {
			currentHealthSystemId: allHealthSystems[0].id,
			currentHealthSystemName: allHealthSystems[0].name,
			currentRegionId: allHealthSystems[0].regions[0].id,
			ehrId: allHealthSystems[0].ehrId,
			ehrTypeName: allHealthSystems[0].ehrTypeName,
			tenantId: allHealthSystems[0].tenantId,
		};
		getStorage().setItem('currentHealthSystemInfo', JSON.stringify(currentHealthSystemInfo));
	}
}

function navigateToApp() {
	const redirectUri = getStorage().getItem('redirectUri') ? getStorage().getItem('redirectUri') : '/';
	window.location.replace(redirectUri);
}

async function fetchUserProfile() {
	try {
		const response = await localApi.get('/api/profile');
		return { data: response.data, err: false };
	} catch (ex) {
		return { err: true };
	}
}

export function signinRedirectCallback() {
	userManager.signinRedirectCallback();
}

export function logout() {
	userManager.signoutRedirect({
		id_token_hint: getStorage().getItem('id_token'),
	});
	userManager.clearStaleState();
}

export function signoutRedirectCallback() {
	userManager.signoutRedirectCallback().then(() => {
		if (sessionStorage.getItem('userRole') !== UserRoles.GUEST) {
			clearLocalStorage();
			window.location.replace(IDENTITY_CONFIG.public_uri);
		}
	});

	userManager.clearStaleState();
}

export function isAuthenticated() {
	const accessToken = sessionStorage.getItem('userRole') === UserRoles.GUEST ? sessionStorage.getItem('access_token') : getStorage().getItem('access_token');
	return !!accessToken;
}

export function memberExists() {
	const exists = sessionStorage.getItem('userRole') === UserRoles.GUEST ? sessionStorage.getItem('memberExists') : getStorage().getItem('memberExists');

	return exists === 'true';
}

export async function signinRedirect(refToken = null) {
	if (sessionStorage.getItem('userRole') === UserRoles.GUEST) {
		return;
	}
	if (window.location.pathname !== '/talk-to-patient') {
		getStorage().setItem('redirectUri', window.location.pathname);
	}

	const args = {};
	if (window.location.hash || refToken) {
		if (refToken) {
			args.acr_values = `sol_ref:${refToken}`;
			args.prompt = 'login';
		} else {
			const hash = window.location.hash.substring(1);
			if (hash) {
				const refId = hash.split('=');
				if (refId && refId.length && refId.length === 2) {
					args.acr_values = `sol_ref:${refId[1]}`;
					args.prompt = 'login';
				}
			}
		}
	}
	await userManager.signinRedirect(args);
}

export function signinSilentCallback() {
	userManager.signinSilentCallback();
}

export function createSigninRequest() {
	return userManager.createSigninRequest();
}

export async function getUser() {
	const user = await userManager.getUser();
	if (!user) {
		return userManager.signinRedirectCallback();
	}
	return user;
}

export function getAccessToken() {
	const accessToken = sessionStorage.getItem('userRole') === UserRoles.GUEST ? sessionStorage.getItem('access_token') : getStorage().getItem('access_token');

	if (!accessToken) {
		throw new Error('Attempted to access unloaded access token');
	}

	return accessToken;
}

export function getUserId() {
	const userId = sessionStorage.getItem('userRole') === UserRoles.GUEST ? sessionStorage.getItem('userId') : getStorage().getItem('userId');
	if (!userId) {
		throw new Error('Attempted to access unloaded userId');
	}

	return userId;
}

export function hasUserProfile() {
	const profile = sessionStorage.getItem('userRole') === UserRoles.GUEST ? sessionStorage.getItem('userProfile') : getStorage().getItem('userProfile');

	return !!profile;
}

export function getUserProfile() {
	const profile = sessionStorage.getItem('userRole') === UserRoles.GUEST ? sessionStorage.getItem('userProfile') : getStorage().getItem('userProfile');

	if (!profile) {
		throw new Error('Attempted to access unloaded userInfo');
	}

	return JSON.parse(profile);
}

export function setUserProfile(newProfile) {
	getStorage().setItem('userProfile', JSON.stringify(newProfile));
}

export function getUserRole() {
	const userRole = sessionStorage.getItem('userRole') === UserRoles.GUEST ? sessionStorage.getItem('userRole') : getStorage().getItem('userRole');
	if (!userRole) {
		return null;
	}

	switch (userRole) {
		case UserRoles.ADMIN:
			return UserRoles.ADMIN;
		case UserRoles.NURSE:
			return UserRoles.NURSE;
		case UserRoles.VIRTUALSITTER:
			return UserRoles.VIRTUALSITTER;
		case UserRoles.SUPERUSER:
			return UserRoles.SUPERUSER;
		case UserRoles.GUEST:
			return UserRoles.GUEST;
		case UserRoles.PATIENT:
			return UserRoles.PATIENT;
		default:
			return null;
	}
}

export function getUserRoleId() {
	const userRoleId = sessionStorage.getItem('userRole') === UserRoles.GUEST ? +sessionStorage.getItem('userRoleId') : +getStorage().getItem('userRoleId');

	return userRoleId || null;
}

function parseJwt(token) {
	const base64Url = token.split('.')[1];
	const base64 = base64Url.replace('-', '+').replace('_', '/');
	return JSON.parse(window.atob(base64));
}

export async function setUserInfo() {
	/* eslint-disable consistent-return */
	let accessToken = getStorage().getItem('access_token');
	const data = parseJwt(accessToken);
	let { data: userProfile, err } = await fetchUserProfile();

	if (err) {
		return err;
	}

	let exists = await checkIfMemberExists();
	let userRole = UserRoles.NURSE;
	let userRoleId = UserTypes.VIRTUALCAREPROVIDER;

	if (exists) {
		let userRoles = await getRoles(APP_CONFIG.companyId, data.sub);
		userRoles.forEach(role => {
			if (role.name === UserRoles.ADMIN) {
				userRole = UserRoles.ADMIN;
				userRoleId = UserTypes.ADMIN;
			} else if (role.name === UserRoles.VIRTUALSITTER) {
				userRole = UserRoles.VIRTUALSITTER;
				userRoleId = UserTypes.VIRTUALSITTER;
			} else if (role.name === UserRoles.SUPERUSER) {
				userRole = UserRoles.SUPERUSER;
				userRoleId = UserTypes.SUPERUSER;
			} else if (role.name === UserRoles.PATIENT) {
				userRole = UserRoles.PATIENT;
				userRoleId = UserTypes.PATIENT;
			}
		});
	}

	setUser(data, userProfile, userRole, exists, userRoleId);

	if (exists && !getStorage().getItem('currentHealthSystemInfo')) {
		await setCurrentHealthSystem();
	}
	return undefined;
}

export async function signoutSilent(refToken = null) {
	if (sessionStorage.getItem('userRole') === UserRoles.GUEST) {
		return;
	}

	const response = await userManager.createSignoutRequest({
		id_token_hint: getStorage().getItem('id_token'),
	});
	clearLocalStorage();
	const iframe = document.createElement('iframe');
	iframe.src = response.url;
	document.body.appendChild(iframe);
	iframe.className = 'signout-frame';
	iframe.onload = async () => {
		await userManager.clearStaleState();
		if (refToken) {
			getStorage().setItem('redirectUri', window.location.pathname);
			getStorage().setItem('shouldSignOut', 'true');
			await signinRedirect(refToken);
		}
	};
}

export function signinSilent() {
	userManager
		.signinSilent({ scope: IDENTITY_CONFIG.scope, response_type: IDENTITY_CONFIG.response_type })
		.then(user => {
			Log.logger.info('signed in', user);
		})
		.catch(err => {
			if (sessionStorage.getItem('userRole') === UserRoles.GUEST) {
				return;
			}

			Log.logger.error('signinSilent error thrown.', err);

			if (err.message === 'login_required') {
				Log.logger.info('Session on identity has expired. Login is required');
				Log.logger.info('Invoking signoutRedirectCallback()...');
				signoutRedirectCallback();
			} else if (err.message === 'Frame window timed out') {
				Log.logger.info('Frame window timed out');
				Log.logger.info('Invoking signoutRedirectCallback()...');
				signoutRedirectCallback();
			}
		});
}

const userLoadedEvent = user => {
	getStorage().setItem('access_token', user.access_token);
	getStorage().setItem('id_token', user.id_token);

	setUserInfo().then(() => {
		if (window.location.href.indexOf('signin-oidc') !== -1 && sessionStorage.getItem('userRole') !== UserRoles.GUEST) {
			navigateToApp();
		}
	});
};

const silentRenewErrorEvent = e => {
	Log.logger.info('silent renew error', e.message);
};

const accessTokenExpiredEvent = () => {
	Log.logger.info('token expired');
	signinSilent();
};

const accessTokenExpiringEvent = () => {
	Log.logger.info('token expiring');
	signinSilent();
};

userManager.events.addUserLoaded(userLoadedEvent);
userManager.events.addSilentRenewError(silentRenewErrorEvent);
userManager.events.addAccessTokenExpired(accessTokenExpiredEvent);
userManager.events.addAccessTokenExpiring(accessTokenExpiringEvent);

export function unbindUserManagerEvents() {
	userManager.events.removeUserLoaded(userLoadedEvent);
	userManager.events.removeSilentRenewError(silentRenewErrorEvent);
	userManager.events.removeAccessTokenExpired(accessTokenExpiredEvent);
	userManager.events.removeAccessTokenExpiring(accessTokenExpiringEvent);
	userManager.events.unload();
}
