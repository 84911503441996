import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LeftNavigation from 'components/LeftNavigation';
import Grid from 'components/Grid';
import { getUserRole } from 'infrastructure/auth';
import { UserRoles } from 'constants/enums';
import { actionCreators as organizationActionCreators } from 'state/organization/actions';
import { actionCreators as mainLayoutActionCreators } from 'state/mainLayout/actions';
import { amwellIconLink } from 'constants/global-variables';

const Layout = props => {
	const dispatch = useDispatch();
	const layoutState = useSelector(state => state.mainLayout);
	const updateLeftNavigationVisibility = () => dispatch(mainLayoutActionCreators.toggleLeftNavigation());
	const updateTreeVisibility = () => dispatch(organizationActionCreators.toggleTreeVisibility(true));

	const onLeftNavigationItemClicked = (item, event) => {
		if (item.role === 'toggleLeftNavigation') {
			event.preventDefault();
			updateLeftNavigationVisibility();
		}
		if (item.role === 'toggleTreeVisibility') {
			event.preventDefault();
			updateTreeVisibility();
		}
	};

	const getLeftNavigationItems = () => {
		let leftNavigationItems = [];

		switch (getUserRole()) {
			case UserRoles.SUPERUSER: {
				leftNavigationItems = [
					{
						icon: 'panorama_fish_eye',
						linkTo: '/health-system',
						title: 'Organization',
						img: `${amwellIconLink}organization.svg`,
						activeImg: `${amwellIconLink}department.svg`,
						role: 'toggleTreeVisibility',
					},
					{
						icon: 'person',
						linkTo: '/users',
						title: 'Users',
						color: 'violet-1',
					},
					{
						icon: 'assessment',
						linkTo: '/calllogs',
						title: 'Call Logs',
						color: 'violet-2',
					},
					// {
					// 	icon: 'build',
					// 	linkTo: '/configurations',
					// 	title: 'Configurations',
					// 	color: 'violet-1',
					// },
				];
				break;
			}
			case UserRoles.ADMIN: {
				leftNavigationItems = [
					{
						icon: 'panorama_fish_eye',
						linkTo: '/health-system',
						title: 'Organization',
						img: `${amwellIconLink}organization.svg`,
						activeImg: `${amwellIconLink}department.svg`,
						role: 'toggleTreeVisibility',
					},
					{
						icon: 'person',
						linkTo: '/users',
						title: 'Users',
						color: 'violet-1',
					},
					{
						icon: 'assessment',
						linkTo: '/calllogs',
						title: 'Call Logs',
						color: 'violet-2',
					},
					{
						icon: 'build',
						linkTo: '/configurations',
						title: 'Configurations',
						color: 'orange-1',
					},
				];
				break;
			}
			case UserRoles.NURSE:
				leftNavigationItems = [
					{
						icon: 'panorama_fish_eye',
						linkTo: '/health-system',
						title: 'Organization',
						img: `${amwellIconLink}organization.svg`,
						activeImg: `${amwellIconLink}department.svg`,
						role: 'toggleTreeVisibility',
					},
				];

				leftNavigationItems.push({
					icon: 'remove_red_eye',
					linkTo: '/monitoring',
					title: 'Monitoring',
					img: null,
					activeImg: null,
					role: 'toggleTreeVisibility',
				});

				break;
			case UserRoles.VIRTUALSITTER: {
				leftNavigationItems = [
					{
						icon: 'remove_red_eye',
						linkTo: '/monitoring',
						title: 'Monitoring',
						role: 'toggleTreeVisibility',
					},
				];
				break;
			}
			case UserRoles.PATIENT: {
				leftNavigationItems = [
					{
						icon: 'panorama_fish_eye',
						linkTo: '/alert-history',
						title: 'Alert History',
						img: `${amwellIconLink}alerts-blue.svg?v2`,
						activeImg: `${amwellIconLink}alerts-white.svg?v2`,
						role: 'toggleTreeVisibility',
					},
				];
				break;
			}
			default: {
				leftNavigationItems = [];
			}
		}

		return leftNavigationItems;
	};

	return (
		<Grid columns={layoutState.isLeftNavigationExpanded ? '250px 1fr' : '90px 1fr'} stretch='100vh' className='main-layout-wrapper'>
			<LeftNavigation expanded={layoutState.isLeftNavigationExpanded} links={getLeftNavigationItems()} onItemClicked={onLeftNavigationItemClicked} />
			<div className='app-wrapper'>
				<Grid className={props.isSecondColumnCollapsed ? 'collapsed-second-column' : ''} columns={props.twoColumns ? '1fr 3fr' : '1fr'} stretch='100%'>
					{props.children}
				</Grid>
			</div>
		</Grid>
	);
};

export default Layout;
