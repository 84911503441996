import React from 'react';
import { Grid, Button } from 'components';
import { ConferenceEndReason, ParticipantState } from 'constants/enums';
import { screenSize } from 'infrastructure/helpers/commonHelpers';

const CallEndReason = props => (
	<div style={{ textAlign: 'center' }}>
		<Grid className='incoming-calls end-call' columns='1fr' rows='1fr' horizAlign='center' vertAlign='center' stretch='100vh'>
			<div>
				{props.reason === ConferenceEndReason.PARTICIPANT_BUSY && (
					<p data-test-id='callState-busy' className='incoming-call-text'>
						{props.onPatientBusyNurse && (
							<>
								{props.onPatientBusyNurse.state === ParticipantState.CONNECTING.type && `This device has initiated a call. Try again later.`}
								{[ParticipantState.CONNECTED.type, ParticipantState.RECONNECTING.type].includes(props.onPatientBusyNurse.state) &&
									`This device is currently on a call with ${props.onPatientBusyNurse.name}. Try again later.`}
							</>
						)}
						{!props.onPatientBusyNurse && `This device is currently on a call. Try again later.`}
					</p>
				)}

				{props.reason === ConferenceEndReason.PARTICIPANT_OFFLINE && (
					<p data-test-id='callState-offline' className='incoming-call-text'>
						This device is offline. Try again later.
					</p>
				)}

				{props.reason === ConferenceEndReason.OWNER_LEFT && <p className='incoming-call-text'>This meeting has been ended by the meeting host.</p>}

				{props.reason === ConferenceEndReason.PARTICIPANT_LEFT && (
					<div>
						<p className='incoming-call-text'>You have left the meeting.</p>
						<div>
							<Button onClick={props.conferenceLink} text='Rejoin' size={screenSize.isSmallScreen ? '' : 'large'} />
						</div>
					</div>
				)}

				{props.reason === ConferenceEndReason.INITIATOR_LEFT && (
					<p data-test-id='callState-leftCall' className='incoming-call-text'>
						Call ended.
					</p>
				)}

				{props.reason === ConferenceEndReason.PARTICIPANT_DECLINED && (
					<p data-test-id='callState-leftCall' className='incoming-call-text'>
						Call has been declined.
					</p>
				)}

				{props.reason === ConferenceEndReason.PARTICIPANT_NOT_ANSWERING && (
					<p data-test-id='callState-leftCall' className='incoming-call-text'>
						No answer. Please try again later.
					</p>
				)}

				{props.reason === ConferenceEndReason.ABORTED && (
					<p data-test-id='callState-leftCall' className='incoming-call-text'>
						Call has ended.
					</p>
				)}

				{props.reason === ConferenceEndReason.DROPPED && (
					<p data-test-id='callState-leftCall' className='incoming-call-text'>
						Failed to reconnect to the network. Please try again.
					</p>
				)}

				{props.reason === ConferenceEndReason.PARTICIPANT_PASSWORD_CHANGED && (
					<p data-test-id='callState-leftCall' className='incoming-call-text'>
						The password for this account has been changed. Please login using the new credentials.
					</p>
				)}
				{props.reason === ConferenceEndReason.PARTICIPANT_IDLE && (
					<p data-test-id='callState-leftCall' className='incoming-call-text'>
						You have been idle for too long. Call is ended.
					</p>
				)}

				{props.reason === ConferenceEndReason.TERMINATED_BY_ADMINISTRATOR && (
					<p data-test-id='callState-leftCall' className='incoming-call-text'>
						This session was ended by a Amwell admin.
					</p>
				)}

				{props.reason === ConferenceEndReason.REMOVED && (
					<p data-test-id='callState-leftCall' className='incoming-call-text'>
						You've been removed from this conference.
					</p>
				)}

				{props.reason === ConferenceEndReason.PARTICIPANT_INVITE_FAILED && (
					<p data-test-id='callState-leftCall' className='incoming-call-text'>
						Failed to get participant information. Please try again.
					</p>
				)}

				{props.reason === ConferenceEndReason.PARTICIPANT_INVITE_DENIED && (
					<p data-test-id='callState-leftCall' className='incoming-call-text'>
						You don't have sufficient permissions to call this participant. Please contact your administrator.
					</p>
				)}
			</div>
		</Grid>
	</div>
);

export default CallEndReason;
