export const GeneralErrorCodes = {
	NotFound: 1000,
	ServerError: 1001,
	ValidationError: 1003,
	BadRequest: 1004,
	Unauthorized: 1005,
	Forbidden: 1006,
};

export const OrganizationErrorCodes = {
	UserExists: 3000,
	CompanyNotFound: 3001,
	MemberNotFound: 3002,
	InsufficientPermissions: 3003,
	TeamNotFound: 3004,
	ChannelNotFound: 3005,
	ActionNotPermitted: 3006,
	TeamMemberNotFound: 3007,
	InviteNotFound: 3008,
	UserNotFound: 3009,
	TeamMemberExists: 3010,
	InviteCancelled: 3011,
	InviteAlreadyAccepted: 3012,
	InviteExpired: 3013,
};

export const CallTypes = {
	AUDIO: 1,
	VIDEO: 2,
	SCREENSHARE: 3,
	SECURITYCAM: 4,
	LIVEBROADCAST: 5,
	MONITORING: 6,
};

export const TvBrands = {
	UNKNOWN: -1,
	SAMSUNG: 0,
	LG: 1,
	CEC_TV: 2,
};

export const ConfigHistoryTypes = {
	UserIdle: '0',
	Devices: '1',
	Network: '2',
};

export const VirtualCareProviderRoles = {
	VirtualNurse: 1,
	VirtualCaseManager: 2,
	VirtualSitter: 3,
	VirtualIntensivist: 4,
};

export const ObjectType = {
	USER: 0,
	HELLO_DEVICE: 1,
	SIP_USER: 999,
};

export const CallStatus = {
	UNDEFINED: {
		type: 0,
		message: 'Undefined',
	},
	SUCCESSFUL: {
		type: 1,
		message: 'Successful',
	},
	PARTIALLYSUCCESSFUL: {
		type: 2,
		message: 'PartiallySuccessful',
	},
	FAILED: {
		type: 3,
		message: 'Failed',
	},
	DISRUPTED: {
		type: 4,
		message: 'Disrupted',
	},
	PARTIALLYDISRUPTED: {
		type: 5,
		message: 'Partially Disrupted',
	},
	MISSED: {
		type: 6,
		message: 'Missed',
	},
	ABORTED: {
		type: 7,
		message: 'Aborted',
	},
	DROPPED: {
		type: 8,
		message: 'Dropped',
	},
};

export const InviteStatuses = {
	SENT: {
		type: 1,
		message: 'Sent',
	},
	ACCEPTED: {
		type: 2,
		message: 'Accepted',
	},
	PENDING: {
		type: 3,
		message: 'Pending',
	},
	CANCELLED: {
		type: 4,
		message: 'Cancelled',
	},
	FAILED: {
		type: 5,
		message: 'Failed',
	},
};

export const Roles = {
	ADMIN: { id: 2, value: 'Admin' },
	VIRTUALCAREPROVIDER: { id: 6, value: 'Virtual Care Provider' },
	VIRTUALSITTER: { id: 7, value: 'Virtual Sitter' },
	SUPERUSER: { id: 8, value: 'Super user' },
};

export const InviteTypes = {
	USER: {
		type: 1,
		message: 'User',
	},
	GUEST: {
		type: 2,
		message: 'Guest',
	},
	ADMIN: {
		type: 3,
		message: 'Admin',
	},
	VIRTUALCAREPROVIDER: {
		type: 4,
		message: 'Virtual Care Provider',
	},
	VIRTUALSITTER: {
		type: 5,
		message: 'Virtual Sitter',
	},
	SUPERUSER: {
		type: 6,
		message: 'Super User',
	},
	ADUSER: {
		type: 7,
		message: 'AD User',
	},
};

export const SectorTypes = {
	HEALTHSYSTEM: 'health system',
	REGION: 'region',
	HOSPITAL: 'hospital',
	DEPARTMENT: 'department',
	FLOOR: 'floor',
	ROOM: 'room',
};

export const PresenceStatusType = {
	AVAILABLE: 1,
	UNAVAILABLE: 2,
};

export const PresenceStatusTypeLabels = {
	1: 'Available',
	2: 'Unavailable',
};

export const CallStatuses = {
	ACCEPTED: 0,
	DECLINED: 0,
};

export const UserTypes = {
	OWNER: 1,
	ADMIN: 2,
	VIRTUALCAREPROVIDER: 6,
	VIRTUALSITTER: 7,
	SUPERUSER: 8,
	GUEST: 9,
	PATIENT: 13,
};

export const UserRoles = {
	ADMIN: 'Admin',
	NURSE: 'VirtualCareProvider',
	VIRTUALSITTER: 'VirtualSitter',
	SUPERUSER: 'SuperUser',
	GUEST: 'Guest',
	PATIENT: 'Patient',
};

export const ZoomDirection = {
	INCREASE: '+',
	DECREASE: '-',
	RESET: 0,
};

export const CameraTiltDirection = {
	UP: '1',
	DOWN: '2',
	RIGHT: '3',
	LEFT: '4',
};

export const CameraTiltAction = {
	START: 'start',
	STOP: 'stop',
};

export const DeviceStatus = {
	ONLINE: 'online',
	OFFLINE: 'offline',
};

export const DeviceListLevel = {
	HEALTHSYSTEM: 0,
	REGION: 1,
	HOSPITAL: 2,
	DEPARTMENT: 3,
	FLOOR: 4,
	ROOM: 5,
	ORGANIZATION: 6,
};

export const SerialTVCommands = {
	INITIAL_TV_POWER: 0,
	POWER: {
		POWER_ON: 1,
		POWER_OFF: 2,
	},
	HDMI: {
		SWITCH_HDMI1: 3,
		SWITCH_HDMI2: 4,
		SWITCH_HDMI3: 5,
	},
	TV_CHANNELS: 6,
	VOLUME: 'volume',
};

export const HarkEvents = {
	STOPPED_SPEAKING: 'stopped_speaking',
	VOLUME_CHANGE: 'volume_change',
};

export const MicStatus = {
	ON: 1,
	OFF: 0,
};

export const CameraStatus = {
	ON: 1,
	OFF: 0,
};

export const ParticipantState = {
	CONNECTING: {
		type: 0,
		message: 'Connecting',
	},
	CONNECTED: {
		type: 1,
		message: 'Connected',
	},
	BUSY: {
		type: 2,
		message: 'Busy',
	},
	DECLINED: {
		type: 3,
		message: 'Declined',
	},
	OFFLINE: {
		type: 4,
		message: 'Offline',
	},
	LEFT_CALL: {
		type: 5,
		message: 'Left the call',
	},
	NOT_ANSWERING: {
		type: 6,
		message: 'Not answering',
	},
	CANT_CONNECT: {
		type: 7,
		message: 'Cant connect',
	},
	DISRUPTED: {
		type: 8,
		message: 'Disrupted',
	},
	RECONNECTING: {
		type: 9,
		message: 'Reconnecting',
	},
	DISCONNECTED: {
		type: 10,
		message: 'Disconnected',
	},
	REMOVED: {
		type: 11,
		message: 'Removed',
	},
	REMOVING: {
		type: 12,
		message: 'Removing',
	},
	INVITE_FAILED: {
		type: 98,
		message: 'Invite failed',
	},
	INVITE_DENIED: {
		type: 99,
		message: 'Invite denied',
	},
};

export const InviteParticipantFailureReason = {
	INVITE_DENIED: 'inviteParticipant.denied',
	FAILED_TO_GET_INFO: 'inviteParticipant.failedToGetInfo',
};

export const ParticipantRemoveReason = {
	UNDEFINED: 0,
	PARTICIPANT_LEAVE: 1,
	DISCONNECTED: 2,
	PARTICIPANT_FORCED_LEAVE: 3,
	CLEAR_PARTICIPANT_OLD_CONFERENCES: 4,
	PARTICIPANT_CLEANUP: 5,
	PASSWORD_CHANGED: 6,
	IDLE: 7,
	CONFERENCE_TERMINATED_BY_ADMINISTRATOR: 8,
	DISCONNECTED_PARTICIPANT_CLEANUP: 9,
	SOMEONE_ELSE_ANSWERED: 10,
	DISCONNECTED_BY_CALL: 11,
	FAILED_TO_GET_INFO: 12,
};

export const ConferenceEndReason = {
	UNDEFINED: 0,
	PARTICIPANT_OFFLINE: 1,
	PARTICIPANT_BUSY: 2,
	INITIATOR_LEFT: 3,
	PARTICIPANT_NOT_ANSWERING: 4,
	PARTICIPANT_DECLINED: 5,
	PARTICIPANT_LEFT: 6,
	OWNER_LEFT: 7,
	ABORTED: 8,
	DROPPED: 9,
	PARTICIPANT_PASSWORD_CHANGED: 10,
	PARTICIPANT_IDLE: 11,
	TERMINATED_BY_ADMINISTRATOR: 12,
	PARTICIPANT_INVITE_DENIED: 13,
	PARTICIPANT_INVITE_FAILED: 14,
	FAILED_TO_GET_INITIATOR_INFO: 991,
	REMOVED: 992,
	UNAUTHORIZED: 993,
	E2EE_FAILED: 994,
	ANSWERED_ELSEWHERE: 995,
	TRANSFERRED_TO_ANOTHER_CLIENT: 996,
	HAS_ACTIVE_CONFERENCE: 997,
	INIATOR_BUSY: 998,
	CLEANUP: 999,
};

export const StartConferenceFailureReason = {
	EXPIRED_TOKEN: 'startConferenceFailureReason.expiredToken',
	GET_INITIATOR_INFO_FAILED: 'startConferenceFailureReason.getInitiatorInfoFailed',
};

export const ChangePasswordUseCases = {
	passwordInfo:
		'Passwords are case-sensitive and must be at least 8 characters. A strong password should contain a mix of capital and lower-case letters, numbers and symbols.',
	currentPassEmpty: 'Please enter your current password, cannot be left blank.',
	newPassEmpty: 'Please enter a new password, it cannot be left blank.',
	confirmNewPassEmpty: 'Please re-enter password, it cannot be left blank.',
	minLengthRequired: 'New password must contain minimum 8 characters.',
	newPasswordsDoNotMatch: 'Passwords do not match, please re-enter.',
	newPassSameAsCurrent: 'New password is same as the current password.',
	newPassSaved: 'Your password has been changed.',
	newPassSet: 'Your password has been set.',
	currentPasswordIncorrect: 'Your current password was incorrectly typed.',
	strongPasswordCheck: 'Password should have at least one uppercase letter, one lowercase letter, one number and one special character:',
};

export const DeviceStatusMessages = {
	deviceOnCall: 'Device on another call.',
	deviceOnCallWithNurse: 'This device is being monitored by',
	deviceOffline: 'Device offline',
	initiatingMonitoring: 'Initiating Monitoring...',
	notAnswering: 'No answer. Please try again later.',
	leftCall: 'Failed to reconnect to the network. Please add this feed again.',
	disconnected: 'Patient is disconnected from the call.',
	reconnecting: 'Please wait…attempting to reconnect to the network.',
	removingParticipant: 'Ending session. Window will auto-close.',
	removed: 'This session was ended by a Amwell admin.',
	participantInviteDenied: "You don't have sufficient permissions to call this participant. Please contact your administrator.",
	participantInviteFailed: 'Failed to get participant information. Please try again.',
};

export const FeedColors = {
	red: '#e84118',
	blue: '#00a8ff',
	orange: '#ff9f43',
	yellow: '#fbc531',
	pink: '#f368e0',
	black: '#5b6b7b',
};

export const StreamError = {
	SCREEN_SHARE_CANCELED: {
		type: 0,
		message: 'Screen share canceled',
	},
	CANT_ACCESS_MEDIA_STREAM: {
		type: 1,
		message: `Can't access media stream`,
	},
	BROWSER_NOT_SUPPORTED: {
		type: 2,
		message: 'Browser not supported',
	},
	EXTENSION_NOT_INSTALLED: {
		type: 3,
		message: 'Extension not installed',
	},
	DEVICE_NOT_FOUND: {
		type: 4,
		message: 'Device not found',
	},
	STREAM_NOT_ALLOWED: {
		type: 5,
		message: 'Stream not allowed',
	},
	PERMISSION_DISMISSED: {
		type: 6,
		message: 'Permission dismissed',
	},
	NOT_READABLE_ERROR: {
		type: 7,
		message: 'Not readable error',
	},
	ONLY_SCREEN_FOUND: {
		type: 8,
		message: 'Only screen found',
	},
	SCREEN_SHARE_NOT_SUPPORTED: {
		type: 9,
		message: 'Screen share not supported',
	},
};

export const SocketState = {
	CONNECTED: {
		type: 0,
		message: 'Connected',
	},
	RECONNECTING: {
		type: 1,
		message: 'Reconnecting...',
	},
	DISCONNECTED: {
		type: 2,
		message: 'Connection is lost',
	},
};

export const SocketDisconnectReason = {
	SERVER_DISCONNECT: 'io server disconnect',
	CLIENT_DISCONNECT: 'io client disconnect',
	PING_TIMEOUT: 'ping timeout',
	TRANSPORT_CLOSE: 'transport close',
	TRANSPORT_ERROR: 'transport error',
};

export const DeviceCommands = {
	REBOOT: 0,
	SHUTDOWN: 1,
	ENABLE_METRICS: 2,
	FACTORY_RESET: 3,
	FORCE_UPDATE: 4,
	PULL_LOGS: 5,
	REBOOT_HUDDLE_CAM: 6,
	SWITCH_AUDIO_OUTPUT: 7,
};

export const ConferenceErrorType = {
	SIGNALING_ERROR: 0, // failures or errors during the signaling procedure
	MEDIA_SOURCE_ERROR: 1, // failure to get access to media device (mic, camera, screen-capture)
	SDP_GENERATION_ERROR: 2, // SDP generation error occur either when generating an offer or answer with incorrect configuration parameters
	NEGOTIATION_FAILURE: 3, // negotiation errors during SDP offer/answer, they mainly occur due ti differing media and network configuration between endpoints
	ICE_CONNECTION_FAILURE: 4, // ICE failures occur when NAT/firewall traversal fails
	TRANSPORT_FAILURE: 5, // call drops after the connection establishment
};

export const CameraType = {
	HELLO: 'Hello',
	HUDDLE: 'Huddle',
};

export const CameraZoomLevels = {
	HELLO_MAX: 5,
	HUDDLE_MAX: 20,
};

export const CameraEventTypes = {
	ZOOM: 'zoom',
	SWITCH: 'switch',
	TILT: 'tilt',
	HUDDLE_CONNECTED_STATE: 'huddleCamConnectionState',
	NIGHT_VISION: 'nightVision',
	HELLO_CAMERA_PRIVACY_STATE: 'helloCameraPrivacyState',
	HELLO_MIC_PRIVACY_STATE: 'helloMicPrivacyState',
	RECENTER: 'recenter',
};

export const CameraCommands = {
	ZOOM: 'zoom',
	SWITCH: 'switch',
	TILT: 'tilt',
	RECENTER: 'recenter',
};

export const MediaTypes = {
	CAMERA: 'camera',
};

export const MediaControlCommands = {
	ADD_BOOKMARK: 'addBookmark',
	EDIT_BOOKMARK: 'editBookmark',
	DELETE_BOOKMARK: 'removeBookmark',
	UPDATE_TO_CURRENT_POSITION: 'moveToBookmark',
	BOOKMARK_LIST: 'bookmarkList',
	MOVE_TO_CLICKED_POSITION: 'moveToClickedPosition',
};

export const RTCPeerConnectionEnum = {
	CONNECTION_STATE: {
		NEW: 'new',
		CONNECTING: 'connecting',
		CONNECTED: 'connected',
		DISCONNECTED: 'disconnected',
		FAILED: 'failed',
		CLOSED: 'closed',
	},
	SIGNALING_STATE: {
		STABLE: 'stable',
		HAVE_LOCAL_OFFER: 'have-local-offer',
		HAVE_REMOTE_OFFER: 'have-remote-offer',
		HAVE_LOCAL_PRANSWER: 'have-local-pranswer',
		HAVE_REMOTE_PRANSWER: 'have-remote-pranswer',
		CLOSED: 'closed',
	},
	ICE_CONNECTION_STATE: {
		NEW: 'new',
		CHECKING: 'checking',
		CONNECTED: 'connected',
		COMPLETED: 'completed',
		FAILED: 'failed',
		DISCONNECTED: 'disconnected',
		CLOSED: 'closed',
	},
	ICE_GATHERING_STATE: {
		NEW: 'new',
		GATHERING: 'gathering',
		COMPLETE: 'complete',
	},
};

export const JoinConferenceFailureReasonEnum = {
	UNHANDLED_EXCEPTION: 'joinConferenceFailureReason.unhandledException',
	NULL_CONFERENCE: 'joinConferenceFailureReason.nullConference',
	WRONG_PARTICIPANT_STATE: 'joinConferenceFailureReason.wrongParticipantState',
	FAILED_TO_GET_PARTICIPANT_INFO: 'joinConferenceFailureReason.failedToGetParticipantInfo',
};

export const StartConferenceFailureMessage = {
	[ConferenceEndReason.PARTICIPANT_INVITE_DENIED]: "You don't have sufficient permissions to call this participant. Please contact your administrator.",
	[ConferenceEndReason.PARTICIPANT_INVITE_FAILED]: 'Failed to get participant information. Please try again.',
	[ConferenceEndReason.FAILED_TO_GET_INITIATOR_INFO]: 'Failed to get your information. Please try again.',
};

export const JoinConferenceFailureMessage = {
	[JoinConferenceFailureReasonEnum.FAILED_TO_GET_PARTICIPANT_INFO]: 'Failed to get your information. Please try again.',
};

export const TeamTypes = {
	HEALTHSYSTEM: 4,
	REGION: 5,
	HOSPITAL: 6,
	DEPARTMENT: 7,
	FLOOR: 8,
};

export const TerminateRequestFailureReasonEnum = {
	UNHANDLED_EXCEPTION: 'terminateRequestFailureReason.unhandledException',
	NULL_CONFERENCE: 'terminateRequestFailureReason.nullConference',
	TERMINATE_IN_PROGRESS: 'terminateRequestFailureReason.terminateInProgress',
	NULL_TERMINATE_REQUEST: 'terminateRequestFailureReason.nullTerminateRequest',
	NULL_CONNECTED_USER_PARTICIPANT: 'terminateRequestFailureReason.nullConnectedUserParticipant',
	NO_PERMISSION: 'terminateRequestFailureReason.noPermission',
};

export const TerminateRequestFailureReasonMessages = {
	[TerminateRequestFailureReasonEnum.UNHANDLED_EXCEPTION]: 'Something went wrong. Please retry again.',
	[TerminateRequestFailureReasonEnum.NULL_CONFERENCE]: 'This session has already been ended.',
	[TerminateRequestFailureReasonEnum.TERMINATE_IN_PROGRESS]: 'Termination for this session is initiated by another administrator.',
	[TerminateRequestFailureReasonEnum.NULL_CONNECTED_USER_PARTICIPANT]: 'Something went wrong. Please retry again.',
	[TerminateRequestFailureReasonEnum.NO_PERMISSION]: 'You have no permission to terminate this session.',
};

export const ClientTypes = {
	BANYAN: {
		type: 2,
		message: 'Banyan',
	},
	AMWELL: {
		type: 4,
		message: 'Amwell',
	},
};

export const AudioOutputDevice = {
	HELLO: 0,
	TV: 1,
};

export const HttpStatusCodes = {
	OK: 200,
	CREATED: 201,
	ACCEPTED: 202,
	NO_CONTENT: 204,
	SEE_OTHER: 303,
	NOT_MODIFIED: 304,
	BAD_REQUEST: 400,
	UNAUTHORIZED: 401,
	CONFLICT: 409,
};

export const RemoteParticipantAction = {
	REMOVE: 'removeParticipant',
	MAKE_HOST: 'makeHost',
};

export const ConferenceAction = {
	START: 'start',
	JOIN: 'join',
};

export const ExternalCallType = {
	VIDEO: '1',
	VIEW_PATIENT: '2',
};

/** @type {{ AUDIO_INPUT: MediaDeviceKind, VIDEO_INPUT: MediaDeviceKind, AUDIO_OUTPUT: MediaDeviceKind }} */
export const MediaDeviceKinds = {
	AUDIO_INPUT: 'audioinput',
	VIDEO_INPUT: 'videoinput',
	AUDIO_OUTPUT: 'audiooutput',
};

/**
 * @type {{ GRANTED: PermissionState, PROMPT: PermissionState, DENIED: PermissionState }}
 */
export const MediaDevicePermissionsState = {
	GRANTED: 'granted',
	PROMPT: 'prompt',
	DENIED: 'denied',
};

export const SettingTypes = {
	AUTO_ANSWER: 2,
	CAMERA_TYPE: 15,
};

export const CallTypesMessage = {
	TALK_TO_PATIENT: 'Talk To Patient',
	TALK_TO_NURSE: 'Talk To Nurse',
	VIDEO_CALL: 'Video Call',
	PATIENT_VIEW: 'Patient View',
	MONITORING: 'Monitoring',
};

export const TVTypes = {
	LG: 'LG (MPI)',
	SAMSUNG: 'Samsung (Exlink)',
};

export const DefaultCameraTypes = {
	HELLO: 1,
	HUDDLE: 2,
};

export const KeyCodes = {
	PERIOD: 190,
	NUMPAD_PERIOD: 110,
	MINUS: 189,
	NUMPAD_SUBTRACT: 109,
	NUMPAD_ADD: 107,
	PLUS: 187,
	LETTER_E: 69,
	NUMPAD_MINUS: 109,
	LETTER_A: 65,
	LETTER_Z: 90,
	ENTER: 13,
	SPACE: 32,
	BACK_SPACE: 8,
	LEFT_ARROW: 37,
	UP_ARROW: 38,
	RIGHT_ARROW: 39,
	DOWN_ARROW: 40,
};

export const DetectionTypes = {
	RAIL: 'rail',
	BED: 'bed',
	BABY: 'baby',
	PERSON: 'person',
	COMBINE: 'combine',
	FALL: 'fallDetected',
	SURGICAL_TOOLS_MISSING: 'surgicalToolsMissing',
	RAIL_DOWN: 'railDown',
	PERSON_SITTING: 'personSitting',
	PERSON_STANDING: 'personStanding',
	BABY_FACE_DOWN: 'babyFaceDown',
	PERSON_FACE_DOWN: 'personFaceDown',
	BABY_EXITS_CRIB: 'babyExitsCrib',
	SKELETON_AND_PRIVACY: 'skeletonAndPrivacy',
};

export const AiDetectionTypes = {
	OBJECT: 3,
	BABY: 4,
	PATIENT: 5,
	RAILS: 6,
	SKELETON: 7,
	SURGICAL_TOOLS: 8,
	ACTION_RECOGNITION: 9,
	PRIVACY: 10,
	FALL_ALERT: 11,
	_FaceDetection: 12, // Used By Hello
	_IoTMeasurements: 13, // Used By Hello
	_ScreamingIt: 14, // Used By Hello
	_Beamforming: 15, // Used By Hello
	PRIVACY_AND_SKELETON: 16,
	GETTING_OUT_OF_BED: 17,
	PERSON_FACE_DOWN: 18,
};

export const AlertTypes = {
	DANGER: 'danger',
	WARNING: 'warning',
	SUCCESS: 'success',
	INFORMATION: 'information',
};

export const PatientsAiSettings = {
	RAIL_DOWN: 1,
	PERSON_SITTING: 2,
	PERSON_STANDING: 3,
	PERSON_FACE_DOWN: 4,
	PERSON_LAYING_SIDE: 5,
	PERSON_LAYING_FRONT: 6,
	BABY_FACE_DOWN: 7,
	FALL_DETECTED: 8,
	PERSON_INACTIVE: 9,
	PERSON_GETTING_OUT_OF_BED: 10,
	SKELETON_PRIVATE_MODE: 11,
	PERSON_STANDING_WALKING: 12,
	BABY_CRYING: 13,
};

export const PatientWarnings = {
	HEART_RATE: 'heartRate',
	TEMPERATURE: 'temperature',
	OXYGEN: 'oxygen',
	PI: 'pi',
	DIABETES: 'diabetes',
	BLOOD_PRESSURE: 'bloodPressure',
	WEIGHT: 'weight',
	BODY_MASS_INDEX: 'bodyMassIndex',
	LEAN_BODY_MASS: 'leanBodyMass',
	BODY_WATER: 'bodyWater',
	MUSCLE_MASS: 'muscleMass',
	VISCERAL_FAT_GRADE: 'visceralFatGrade',
	STETHOSCOPE: 'stethoscope',
	STEPS: 'steps',
	SLEEP: 'sleepAnalysis',
	ELECTROCARDIOGRAPHY: 'electrocardiography',
	BLOOD_GLUCOSE: 'bloodGlucose',
	DISTANCE_WALKING_RUNNING: 'distanceWalkingRunning',
	FLIGHTS_CLIMBED: 'flightsClimbed',
	BODY_FAT: 'bodyFat',
	FALL_DETECTED: 'fallDetected',
	RESPIRATORY_RATE: 'respiratoryRate',
	HEIGHT: 'height',
};

export const WarningIndexes = {
	LOW: 1,
	EXTREMELY_LOW: 2,
	HIGH: 3,
	EXTREMELY_HIGH: 4,
};

export const AiAlertTypes = {
	RAIL_DOWN: 'railDown',
	PERSON_SITTING: 'personSitting',
	PERSON_STANDING: 'personStanding',
	PERSON_FACE_DOWN: 'personFaceDown',
	PERSON_LAYING_SIDE: 'personLayingSide',
	PERSON_LAYING_FRONT: 'personLayingFront',
	BABY_FACE_DOWN: 'babyFaceDown',
	FALL_DETECTION: 'fallDetected',
	SKELETON_AND_PRIVACY: 'skeletonAndPrivacy',
	PERSON_INACTIVE: 'personInactive',
	PERSON_GETTING_OUT_OF_BED: 'personGettingOutOfBed',
	PERSON_STANDING_WALKING: 'personStandingWalking',
	BABY_CRYING: 'babyCrying',
};

export const ManualAlertTypes = {
	FALL_DETECTED: 1,
	RISK_OF_FALL: 2,
	PATIENT_NEEDS_HELP: 3,
	PATIENT_REQUESTED_HELP: 4,
	CUSTOM: 5,
};

export const MeasurementActivityTypes = {
	MEASUREMENT_ALERT_SENT: 1,
	MEASUREMENT_ALERT_RECEIVED: 2,
	ADDED_AS_HOSPITAL_CARETAKER: 3,
	COMMENTED: 4,
	ACKNOWLEDGED: 5,
	MEASUREMENT_FAKE_ALERT: 6,
	MEASUREMENT_FORWARD_TO_NURSES: 7,
};

export const AiAlertActivityType = {
	AI_ALERT_SENT: 1,
	AI_ALERT_RECEIVED: 2,
	ADDED_AS_HOSPITAL_CARETAKER: 3,
	COMMENTED: 4,
	ACKNOWLEDGED: 5,
	AI_FAKE_ALERT: 6,
	AI_FORWARD_TO_NURSES: 7,
};

export const PatientProfileInfoTab = {
	PROFILE_PICTURE: 0,
	PERSONAL_INFORMATION: 1,
	ADDRESS: 2,
	CONTACT_INFORMATION: 3,
	HEALTH_INFORMATION: 4,
};

export const UnitCategoryTypes = {
	BLOOD_GLUCOSE: 1,
	DISTANCE: 2,
	TEMPERATURE: 3,
	HEIGHT: 4,
	WEIGHT: 5,
};

export const MeasurementTypes = {
	HEART_RATE: 'heartRate',
	OXYGEN: 'oxygen',
	PI: 'pi',
	DIABETES: 'diabetes',
	BLOOD_PRESSURE: 'bloodPressure',
	WEIGHT: 'weight',
	TEMPERATURE: 'temperature',
	SLEEP: 'sleepAnalysis',
	ELECTROCARDIOGRAPHY: 'electrocardiography', // not defined yet
	BLOOD_GLUCOSE: 'bloodGlucose',
	STETHOSCOPE: 'stethoscope',
	DISTANCE_WALKING_RUNNING: 'distanceWalkingRunning',
	FLIGHTS_CLIMBED: 'flightsClimbed',
	STEPS: 'steps',
	LEAN_BODY_MASS: 'leanBodyMass',
	BODY_FAT: 'bodyFat',
	BODY_MASS_INDEX: 'bodyMassIndex',
	FALLEN_TIMES: 'numberOfTimesFallen',
	RESPIRATORY_RATE: 'respiratoryRate',
	HEIGHT: 'height',
	STRESS_LEVEL: 'stressLevel',
	HRV_INDEX: 'hrv',
	BILIRUBIN: 'bilirubin',
	MUSCLE_MASS: 'muscleMass',
	VISCERAL_FAT_GRADE: 'visceralFatGrade',
	BODY_WATER: 'bodyWater',
	HDL_CHOLESTEROL: 'hdlCholesterol',
	TOTAL_CHOLESTEROL: 'totalCholesterol',
	WAIST_CIRCUMFERENCE: 'waistCircumference',
	STETHO: 'stetho',
	EAR: 'ear',
	LUNGS: 'lungs',
	HEART: 'heart',
	THROAT: 'throat',
	SKIN: 'skin',
	ECG: 'ecg',
	LUNGS_AIR: 'lungsAir',
	ABDOMEN: 'abdomen',
};

export const CareEventsForNurses = {
	HomeMedListCompleted: 6,
	AdmissionDataCompleted: 7,
	IPOCcompleted: 8,
	DischargeInstructionsCreated: 9,
	DischargeTeachingCompleted: 10,
	Performed2ndRNMedVerification: 11,
	NotifiedMD: 12,
	PerformedMentorship: 13,
	PerformedSepsisScreenNotification: 14,
	Completed24HourChartCheck: 15,
	PerformedClinicalRounding: 16,
	ProvidedUpdateToFamily: 17,
	AssistedWithCodeBlue: 18,
	OtherCareEventForNurse: 19,
};

export const CareEventsForSafetyCompanions = {
	RedirectedPatientToPreventFall: 20,
	NotifiedBedsideStaffToAssistPatientToPreventFall: 21,
	NotifiedBedsideStaffToAssistPatient: 22,
	CalledRapidResponse: 23,
	PreventedHarmToStaffMember: 24,
};

export const CareEventTypes = {
	Nurses: 1,
	SafetyCompanionsAndNurses: 2,
	RaiseAlert: 3,
};
