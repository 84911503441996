import { UserTypes, DeviceListLevel, SectorTypes, UserRoles, KeyCodes } from 'constants/enums';
import React, { useState } from 'react';
import queryString from 'query-string';
import { getUserProfile, getUserRole } from 'infrastructure/auth';
import { isMobile, isIOS } from 'react-device-detect';

export function isLoggedInUserAdmin() {
	const userProfile = getUserProfile();
	let isAdmin = userProfile.roles.some(role => {
		return role.id === UserTypes.ADMIN;
	});

	return isAdmin;
}

export const screenSize = {
	isSmallScreen: window.innerWidth < 992,
};

const hasRefTokenInQuery = () => {
	const { search, pathname } = window.location;
	const query = queryString.parse(search);

	if (!pathname.startsWith('/start-call') || !query.refToken) {
		return null;
	}

	return query.refToken.toString();
};

let chosenStorage;

export const getStorage = () => {
	if (chosenStorage) {
		return chosenStorage;
	}
	let _storage = window !== window.parent ? sessionStorage : localStorage;

	let hasHashRefId = false;
	if (window.location.hash) {
		const hash = window.location.hash.substring(1);
		if (hash) {
			const refId = hash.split('=');
			if (refId && refId.length && refId.length === 2) {
				hasHashRefId = true;
			}
		}
	}

	const shouldBeSessionStorage = hasRefTokenInQuery() || sessionStorage.getItem('ref_token') || hasHashRefId || sessionStorage.getItem('hasHashRefId');

	if (shouldBeSessionStorage) {
		chosenStorage = sessionStorage;
	} else {
		chosenStorage = _storage;
	}

	return chosenStorage;
};

export function getCurrentHealthSystemInfo() {
	let currentHealthSystem = getStorage().getItem('currentHealthSystemInfo');
	return JSON.parse(currentHealthSystem);
}

/**
 * Method will set the current selected room breadcrumb to localStorage
 * @param {String} currentRoomBreadcrumb
 */
export function setCurrentRoomBreadcrumb(currentRoomBreadcrumb) {
	getStorage().setItem('currentRoomBreadcrumb', currentRoomBreadcrumb);
}

/**
 * Method will get the current selected room breadcrumb from localStorage
 * @returns {String}
 */
export function getCurrentRoomBreadcrumb() {
	return getStorage().getItem('currentRoomBreadcrumb');
}

/**
 * Method will remove the current selected room breadcrumb from localStorage
 */
export function removeCurrentRoomBreadcrumb() {
	getStorage().removeItem('currentRoomBreadcrumb');
}

export const findDeviceById = (arr, id) =>
	arr.reduce((accumulator, item) => {
		if (accumulator) {
			return accumulator;
		}
		if (+item.helloDeviceId === +id) {
			return item;
		}
		if (item.subOptions) {
			return findDeviceById(item.subOptions, +id);
		}
	}, null);

export function findSectorById(arr, id) {
	return arr.reduce((accumulator, item) => {
		if (accumulator) {
			return accumulator;
		}
		// comparing whether the id given as argument is equal to any of the items declared in the array.
		if ([item.regionId, item.hospitalId, item.departmentId, item.floorId, item.roomId, item.id].includes(id)) {
			return item;
		}
		if (item.subOptions) {
			return findSectorById(item.subOptions, id);
		}
	}, null);
}

export function searchSectors(arr, name, sectors = []) {
	arr.forEach((item, index) => {
		if (item.isNewOption) {
			arr.splice(index, 1);
			return;
		}
		if (!name) {
			item.hidden = false;
			item.matchesSearch = false;
		} else if (item.name.toLowerCase().includes(name.toLowerCase())) {
			item.hidden = false;
			item.matchesSearch = true;
			sectors.push(item);
		} else {
			item.hidden = true;
			item.matchesSearch = false;
		}
		if (item.subOptions) {
			searchSectors(item.subOptions, name, sectors);
		}
	});
	return sectors;
}

export function showPath(tree, arr) {
	arr.forEach(item => {
		if (item.hospitalId) {
			let hospital = findSectorById(tree, item.hospitalId);
			hospital.hidden = false;
			hospital.matchesSearch = true;
		}

		if (item.departmentId) {
			let department = findSectorById(tree, item.departmentId);
			department.hidden = false;
			department.matchesSearch = true;
		}

		if (item.floorId) {
			let floor = findSectorById(tree, item.floorId);
			floor.hidden = false;
			floor.matchesSearch = true;
		}
	});
}

export function getFirstLetter(text) {
	return text
		.trim()
		.substring(0, 1)
		.toLowerCase();
}

export function decodeHtml(txt) {
	const Entities = require('html-entities').AllHtmlEntities;

	const entities = new Entities();

	return entities.decode(txt);
}

export function mapSectionsToRender(hospitals, sector, toggleEditSectorModal, initDeleteOrg) {
	let result = {
		hospitals: [],
		departments: [],
		floors: [],
		rooms: [],
	};
	if (!hospitals || hospitals.length === 0) {
		return result;
	}
	let selectedSector = findSectorById(hospitals, sector[`${sector.sectorType}Id`]);
	if (!selectedSector) {
		return result;
	}

	const userRole = getUserRole();
	if (sector.sectorType === SectorTypes.HOSPITAL) {
		selectedSector.subOptions.forEach(room => {
			result.rooms.push({
				name: room.name,
				id: room.roomId,
				edit: (userRole === UserRoles.ADMIN || userRole === UserRoles.SUPERUSER) && (
					<div className='wrapped'>
						<i
							className='material-icons-outlined boxed-icon'
							onClick={() =>
								toggleEditSectorModal({
									sectorName: room.name,
									sectorType: room.type,
									hospitalId: room.hospitalId,
									departmentId: room.departmentId,
									floorId: room.floorId,
									roomId: room.roomId,
								})
							}>
							edit
						</i>{' '}
						&nbsp;
						{userRole === UserRoles.ADMIN && (
							<i
								className='material-icons-outlined boxed-icon'
								onClick={() => initDeleteOrg({ level: DeviceListLevel.ROOM, id: room.roomId, name: room.name })}>
								delete
							</i>
						)}
					</div>
				),
			});
		});
	}

	if (sector.sectorType === SectorTypes.DEPARTMENT) {
		selectedSector.subOptions.forEach(room => {
			result.rooms.push({
				name: room.name,
				id: room.roomId,
				edit: (userRole === UserRoles.ADMIN || userRole === UserRoles.SUPERUSER) && (
					<div className='wrapped'>
						<i
							className='material-icons-outlined boxed-icon'
							onClick={() =>
								toggleEditSectorModal({
									sectorName: room.name,
									sectorType: room.type,
									hospitalId: room.hospitalId,
									departmentId: room.departmentId,
									floorId: room.floorId,
									roomId: room.roomId,
								})
							}>
							edit
						</i>{' '}
						&nbsp;
						{userRole === UserRoles.ADMIN && (
							<i
								className='material-icons-outlined boxed-icon'
								onClick={() => initDeleteOrg({ level: DeviceListLevel.ROOM, id: room.roomId, name: room.name })}>
								delete
							</i>
						)}
					</div>
				),
			});
		});
	}

	if (sector.sectorType === SectorTypes.FLOOR) {
		selectedSector.subOptions.forEach(room => {
			result.rooms.push({
				name: room.name,
				id: room.roomId,
				edit: (userRole === UserRoles.ADMIN || userRole === UserRoles.SUPERUSER) && (
					<div className='wrapped'>
						<i
							className='material-icons-outlined boxed-icon'
							onClick={() =>
								toggleEditSectorModal({
									sectorName: room.name,
									sectorType: room.type,
									hospitalId: room.hospitalId,
									departmentId: room.departmentId,
									floorId: room.floorId,
									roomId: room.roomId,
								})
							}>
							edit
						</i>{' '}
						&nbsp;
						{userRole === UserRoles.ADMIN && (
							<i
								className='material-icons-outlined boxed-icon'
								onClick={() => initDeleteOrg({ level: DeviceListLevel.ROOM, id: room.roomId, name: room.name })}>
								delete
							</i>
						)}
					</div>
				),
			});
		});
	}

	return result;
}

export function getParentSector(arr, element) {
	let parent = {};
	if (element.type === SectorTypes.ROOM) {
		parent = findSectorById(arr, element.hospitalId);
	}
	if (element.type === SectorTypes.DEPARTMENT) {
		parent = findSectorById(arr, element.hospitalId);
	}
	return parent;
}

export function isValidSector(currentSector, tree, sectorName) {
	let sameLevelSectors;
	if (currentSector.type === SectorTypes.HOSPITAL || currentSector.type === SectorTypes.DEPARTMENT) {
		sameLevelSectors = tree.filter(s => s.isNewOption === undefined);
		let hospitalExists = Object.entries(sameLevelSectors[0]).length > 0;
		if (!hospitalExists) {
			return true;
		}
	} else {
		sameLevelSectors = getParentSector(tree, currentSector).subOptions.filter(s => s.isNewOption === undefined);
	}

	return !sameLevelSectors.some(s => s.name.toLowerCase() === sectorName.toLowerCase());
}

export function getSectorPath(sector) {
	let result = {};
	if (!sector) {
		return result;
	}
	if (sector.type === SectorTypes.HOSPITAL) {
		result[sector.regionId] = {};
	}
	if (sector.type === SectorTypes.DEPARTMENT) {
		result[sector.regionId] = {};
		result[sector.regionId][sector.hospitalId] = {};
	}
	if (sector.type === SectorTypes.FLOOR) {
		result[sector.regionId] = {};
		result[sector.regionId][sector.hospitalId] = {};
		result[sector.regionId][sector.hospitalId][sector.departmentId] = {};
	}
	if (sector.type === SectorTypes.ROOM) {
		result[sector.regionId] = {};
		result[sector.regionId][sector.hospitalId] = {};
		result[sector.regionId][sector.hospitalId][sector.departmentId] = {};
		result[sector.regionId][sector.hospitalId][sector.departmentId][sector.floorId] = {};
	}
	return result;
}

export const buildTreeModel = (hospitals, assignMode) => {
	let treeData = [];
	let baseurl = '/health-system';
	let isMonitoring;
	let userRole = getUserRole();

	if (window.location.pathname.includes('monitoring')) {
		isMonitoring = true;
		baseurl = '/monitoring';
	}

	const tooltipOptions = hasDevice => {
		if (isMonitoring && hasDevice) {
			return 'Add to monitoring view';
		}

		if (assignMode && !hasDevice) {
			return 'Assign device';
		}
		return '';
	};

	hospitals.forEach((hospital, hospitalIndex) => {
		if (hospitalIndex === 0) {
			treeData.firstHospitalId = hospital.id;
		}
		treeData.push({
			name: hospital.name,
			icon: 'business',
			img: 'department.svg',
			link: `${baseurl}/${hospital.id}`,
			tooltip: [UserRoles.ADMIN, UserRoles.SUPERUSER].includes(userRole) ? 'Add Room' : false,
			type: 'hospital',
			subOptions: [],
			hospitalId: hospital.id,
			defaultDepartmentId: hospital.departments !== null && hospital.departments.length === 1 ? hospital.departments[0].id : null,
			breadcrumb: [
				{
					name: hospital.name,
					link: `${baseurl}/${hospital.id}`,
				},
			],
		});

		hospital.departments.forEach(department => {
			department.floors.forEach(floor => {
				floor.rooms.forEach((room, roomIndex) => {
					treeData[hospitalIndex].subOptions.push({
						name: room.name,
						icon: 'meeting_room',
						img: 'room.svg',
						link: `${baseurl}/${hospital.id}/department/${department.id}/floor/${floor.id}/room/${room.id}`,
						type: 'room',
						subOptions: [],
						roomId: room.id,
						roomIndex: roomIndex,
						floorId: floor.id,
						departmentId: department.id,
						hospitalId: hospital.id,
						tooltip: tooltipOptions(room.helloDeviceId),
						breadcrumb: [
							{
								name: room.name,
								link: `${baseurl}/${hospital.id}/department/${department.id}/floor/${floor.id}/room/${room.id}`,
							},
						],
						status: room.isOnline ? 'online' : 'offline',
						microphone: room.microphone,
						camera: room.camera,
						helloDeviceId: room.helloDeviceId,
						treeLocation: [
							{
								name: hospital.name,
								img: 'department.svg',
								type: 'department',
								link: `${baseurl}/${hospital.id}/`,
								subOptions: [
									{
										name: room.name,
										img: 'room.svg',
										link: `${baseurl}/${hospital.id}/department/${department.id}/floor/${floor.id}/room/${room.id}`,
										type: 'room',
									},
								],
							},
						],
					});
				});
			});
		});
	});
	return treeData;
};

export const getBusyDevices = hospitals => {
	const result = { busy: [] };
	hospitals.forEach(hospital => {
		hospital.departments.forEach(department => {
			department.floors.forEach(floor => {
				floor.rooms.forEach(room => {
					const connectedOrBusy = 3;
					if (room.callState === connectedOrBusy) {
						result.busy.push(+room.helloDeviceId);
					}
				});
			});
		});
	});
	return result;
};

export function getLevelDevices(option) {
	let devices = [];
	switch (option.type) {
		case 'hospital': {
			const departments = option.subOptions;
			departments.forEach(department => {
				const floors = department.subOptions;
				floors.forEach(floor => {
					const rooms = floor.subOptions;
					rooms.forEach(room => {
						if (room.helloDeviceId !== undefined) {
							devices.push(room.helloDeviceId);
						}
					});
				});
			});
			return devices;
		}
		case 'department': {
			const floors = option.subOptions;
			floors.forEach(floor => {
				const rooms = floor.subOptions;
				rooms.forEach(room => {
					if (room.helloDeviceId !== undefined) {
						devices.push(room.helloDeviceId);
					}
				});
			});
			return devices;
		}
		case 'floor': {
			const rooms = option.subOptions;
			rooms.forEach(room => {
				if (room.helloDeviceId !== undefined) {
					devices.push(room.helloDeviceId);
				}
			});
			return devices;
		}
		default:
			return devices;
	}
}

export function getDeviceAssignedRoom(tree, deviceId) {
	let option = {};

	tree.forEach(region => {
		const hospitals = region.subOptions;
		hospitals.forEach(hospital => {
			const departments = hospital.subOptions;
			departments.forEach(department => {
				const floors = department.subOptions;
				floors.forEach(floor => {
					const rooms = floor.subOptions;
					rooms.forEach(room => {
						if (room.helloDeviceId === deviceId) {
							option.roomName = room.name;
							option.floorName = floor.name;
							option.departmentName = department.name;
							option.hospitalName = hospital.name;
							option.regionName = region.name;
						}
					});
				});
			});
		});
	});

	return option;
}

export function getInitialsColor(letter) {
	const obj = {};
	if (!letter) {
		obj.color = `#4772f3`;
		obj.backgroundColor = `#d0e2fd`;
		return obj;
	}
	const letterToLowerCase = letter.toLowerCase();
	switch (letterToLowerCase) {
		case 'a':
		case 'n': {
			obj.color = `#4772f3`;
			obj.backgroundColor = `#d0e2fd`;
			break;
		}
		case 'b':
		case 'o': {
			obj.color = `#e361c9`;
			obj.backgroundColor = `#edd2fc`;
			break;
		}
		case 'c':
		case 'p': {
			obj.color = `#eb605e`;
			obj.backgroundColor = `#f9dcd2`;
			break;
		}
		case 'd':
		case 'q': {
			obj.color = `#15a6ff`;
			obj.backgroundColor = `#d2f0fc`;
			break;
		}
		case 'e':
		case 'r': {
			obj.color = `#44ccb5`;
			obj.backgroundColor = `#d2f7ec`;
			break;
		}
		case 'f':
		case 's': {
			obj.color = `#dd995b`;
			obj.backgroundColor = `#f7e4d2`;
			break;
		}
		case 'g':
		case 't': {
			obj.color = `#e2619c`;
			obj.backgroundColor = `#f9d2f8`;
			break;
		}
		case 'h':
		case 'u': {
			obj.color = `#73c140`;
			obj.backgroundColor = `#d2f7d6`;
			break;
		}
		case 'i':
		case 'v': {
			obj.color = `#8a64dd`;
			obj.backgroundColor = `#e2d2f9`;
			break;
		}
		case 'j':
		case 'w': {
			obj.color = `#d3f4e8`;
			obj.backgroundColor = `#41837a`;
			break;
		}
		case 'k':
		case 'x': {
			obj.color = `#b94557`;
			obj.backgroundColor = `#f4d3d3`;
			break;
		}
		case 'l':
		case 'y': {
			obj.color = `#fcfaef`;
			obj.backgroundColor = `#d2f7d6`;
			break;
		}
		case 'm':
		case 'z': {
			obj.color = `#70a8b5`;
			obj.backgroundColor = `#e8f5f9`;
			break;
		}
		default:
	}
	return obj;
}

export function onErrorInitialsSrc(e) {
	e.target.style.display = 'none';
	e.target.onerror = null;
}

/**
 * Use to re-render function components
 * @function useForceComponentUpdate
 * @returns {(value: number) => void}
 */
export function useForceComponentUpdate() {
	const [counter, setCounter] = useState(0);
	return () => setCounter(counter + 1);
}

/**
 * Use to re-render function components
 * @function isNullOrUndefined
 * @param {any} prop
 * @returns {boolean}
 */
export const isNullOrUndefined = prop => {
	return prop === null || prop === undefined;
};

/**
 * Snooze function execution for given seconds
 * @param {number} ms Milliseconds
 */
export const snooze = ms =>
	new Promise(resolve => {
		const timeout = setTimeout(() => {
			resolve();
			clearTimeout(timeout);
		}, ms);
	});

export const getMobileFullHeightStyle = () => {
	const menuHeight = 120;
	if (!isMobile || !isIOS) {
		return null;
	}

	return { height: `calc(${window.innerHeight}px - ${menuHeight}px)` };
};

export const getPort = url => {
	const parts = url.split(':');
	const port = parseInt(parts[parts.length - 1], 10);
	if (parts[0] === 'http' && (Number.isNaN(port) || parts.length < 3)) {
		return '';
	}
	if (parts[0] === 'https' && (Number.isNaN(port) || parts.length < 3)) {
		return '';
	}
	if (parts.length === 1 || Number.isNaN(port)) return '';
	return port;
};

export const handleOnKeyDownNumeric = (event, allowPeriod = false) => {
	if (
		[
			KeyCodes.MINUS,
			KeyCodes.NUMPAD_PERIOD,
			KeyCodes.NUMPAD_ADD,
			KeyCodes.NUMPAD_SUBTRACT,
			!allowPeriod && KeyCodes.PERIOD,
			KeyCodes.PLUS,
			KeyCodes.LETTER_E,
		].includes(event.which)
	) {
		event.preventDefault();
	}
};

export const stringToCamelCase = str => {
	return str
		.replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
			return index === 0 ? word.toLowerCase() : word.toUpperCase();
		})
		.replace(/\s+|\(|\)/g, '');
};

export const convertSecondsToHoursFormat = secondsString => {
	const seconds = parseInt(secondsString, 10);
	const hours = seconds / 3600;
	const hoursInteger = Math.floor(hours);
	const minutesInteger = Math.floor((hours - hoursInteger) * 60);
	return `${hoursInteger}h ${minutesInteger.toString().padStart(2, '0')}m`;
};

export const validateMaxLength = (event, maxLength) => {
	handleOnKeyDownNumeric(event);
	if (
		event.target.value.length === maxLength &&
		![KeyCodes.BACK_SPACE, KeyCodes.LETTER_A, KeyCodes.LEFT_ARROW, KeyCodes.UP_ARROW, KeyCodes.DOWN_ARROW, KeyCodes.RIGHT_ARROW, event.ctrlKey].includes(
			event.which
		)
	) {
		event.preventDefault();
	}
};
