import React, { useEffect, useRef } from 'react';
import { Select } from 'components';
import { amwellIconLink } from 'constants/global-variables';
import { MediaDevicePermissionsState } from 'constants/enums';

const CameraSettings = props => {
	const videoRef = useRef(null);

	const streamRef = useRef(null);

	const mountedRef = useRef(false);

	const stopActiveTracks = () => {
		const camTracks = streamRef.current?.getTracks();
		if (!camTracks) {
			return;
		}

		camTracks.forEach(track => track.stop());
	};

	useEffect(() => {
		mountedRef.current = true;
		if (props.isTechCheck) {
			props.setVideoLoading(true);
		}

		const video = props.activeCameraId
			? {
					deviceId: {
						exact: props.activeCameraId,
					},
			  }
			: true;
		const testCamera = () => {
			try {
				navigator.mediaDevices
					.getUserMedia({
						video,
					})
					.then(stream => {
						if (videoRef.current) {
							videoRef.current.srcObject = stream;
							videoRef.current.play();
							if (props.isTechCheck) {
								props.setVideoLoading(false);
							}
						}
						streamRef.current = stream;

						if (!mountedRef.current) {
							stopActiveTracks();
						}
					});
			} catch (error) {
				if (props.isTechCheck) {
					props.setVideoLoading(false);
				}
			}
		};

		if ((props.isTechCheck || props.activeCameraId) && props.camPermissionState === MediaDevicePermissionsState.GRANTED) {
			testCamera();
		}

		return () => {
			mountedRef.current = false;
			stopActiveTracks();
		};
	}, [props.activeCameraId, props.camPermissionState]);

	return (
		<>
			{props.isTechCheck && (
				<>
					<div className='input'>
						<p className='label custom-label'>
							<img src={`${amwellIconLink}select-cam.svg`} alt='icon' />
							Camera
						</p>
					</div>
					<video ref={videoRef} playsInline />
				</>
			)}
			<Select
				label='Select Camera'
				name='camera'
				items={props.cameraList}
				placeholder='Select Video'
				optionValueKey='label'
				onSelect={props.onCameraChange}
				defaultValue={props.defaultValue}
				disabled={props.selectDisabled}
			/>
			{props.children}
		</>
	);
};

export default CameraSettings;
