import React, { Component } from 'react';
import { AuthProvider } from 'providers/authProvider';
import { BrowserRouter } from 'react-router-dom';
import { Routes } from 'routes/routes';
import Socket from 'io-client/Socket';
import { isAuthenticated, logout, memberExists } from 'infrastructure/auth';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators as organizationActionCreators } from 'state/organization/actions';
import { Loader, Grid, Button, Alert } from 'components';
import { getCurrentHealthSystemInfo, getStorage } from 'infrastructure/helpers/commonHelpers';
import { onMessage } from 'infrastructure/helpers/notificationsChannel';
import { fetchNotificationCounter } from 'state/notifications/actions';
import IncomingCall from 'views/IncomingCall';
import { APP_CONFIG } from 'constants/global-variables';
import { SocketState } from 'constants/enums';
import queryString from 'query-string';
import UserIdleCheck from 'containers/UserIdleCheck';
import AppInsightsTelemetryProvider from 'components/AppInsightsTelemetryProvider';

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			startApplication: false,
			displayContentUpdatedAlert: false,
			displaySocketConnectionAlert: false,
			socketConnectionState: SocketState.CONNECTED,
		};

		this.versionTimeout = null;
		this.socketStateTimeout = null;
	}

	componentDidMount = async () => {
		// If tokens are provided and the url contains call
		// then set tokens along with the other data on localStorage
		// in order to bypass identity auth and get directly to call

		if (isAuthenticated()) {
			if (memberExists()) {
				let healthSystemExists = await this.checkIfHealthSystemExists();
				if (!healthSystemExists && this.props.organization.allHealthSystems.length !== 0) {
					let currentHealthSystemInfo = {
						currentHealthSystemId: this.props.organization.allHealthSystems[0].id,
						currentHealthSystemName: this.props.organization.allHealthSystems[0].name,
						currentRegionId: this.props.organization.allHealthSystems[0].regions[0].id,
						ehrId: this.props.organization.allHealthSystems[0].ehrId,
						ehrType: this.props.organization.allHealthSystems[0].ehrType,
						tenantId: this.props.organization.allHealthSystems[0].tenantId,
					};
					getStorage().setItem('currentHealthSystemInfo', JSON.stringify(currentHealthSystemInfo));
				}
			}
			onMessage(() => {
				this.props.fetchNotificationCounter();
			});
			this.startVersionTimeout();
		}

		const authResult = this.getTokensIfCall();

		let hasHashRefId = false;

		if (window.location.hash) {
			const hash = window.location.hash.substring(1);
			if (hash) {
				const refId = hash.split('=');
				if (refId && refId.length && refId.length === 2) {
					hasHashRefId = true;
				}
			}
		}

		if (authResult) {
			getStorage().setItem('ref_token', authResult.refToken);
		}

		if (hasHashRefId) {
			getStorage().setItem('hasHashRefId', true);
		}

		if (authResult?.refToken || hasHashRefId) {
			const redirectUri = getStorage().getItem('redirectUri');

			if (isAuthenticated()) {
				getStorage().clear();
				window.location.replace(redirectUri);
				if (authResult?.refToken) {
					getStorage().setItem('ref_token', authResult.refToken);
				}
				if (hasHashRefId) {
					getStorage().setItem('hasHashRefId', true);
				}
			}
		}

		this.setState({
			startApplication: true,
		});
	};

	checkIfHealthSystemExists = async () => {
		await this.props.organizationActions.getAllHealthSystems();
		let currentHealthSystemInfo = getCurrentHealthSystemInfo();
		if (currentHealthSystemInfo) {
			let { currentHealthSystemId } = getCurrentHealthSystemInfo();
			return this.props.organization.allHealthSystems.some(healthSystem => {
				return healthSystem.id === currentHealthSystemId;
			});
		}
		return false;
	};

	startVersionTimeout = () => {
		if (this.versionTimeout) {
			clearTimeout(this.versionTimeout);
		}

		const versionTimeoutInterval = +APP_CONFIG.versionCheckInterval || 10;
		this.versionTimeout = setTimeout(async () => {
			this.compareVersions();
		}, 1000 * 60 * versionTimeoutInterval);
	};

	compareVersions = async () => {
		try {
			const response = await fetch('/env/react-config.js');
			let configText = await response.text();

			configText = configText.replace(/'/g, `"`);

			const { REACT_APP_BUILD_NUMBER } = JSON.parse(configText.substring(configText.indexOf('{'), configText.lastIndexOf('}') + 1));

			if (APP_CONFIG.buildNumber && REACT_APP_BUILD_NUMBER && APP_CONFIG.buildNumber !== REACT_APP_BUILD_NUMBER) {
				this.setState({
					displayContentUpdatedAlert: true,
				});
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error(error);
		}

		this.startVersionTimeout();
	};

	onConnectionStateChange = newSocketConnectionState => {
		if (this.state.socketConnectionState.type === newSocketConnectionState.type) {
			return;
		}

		this.setState({
			displaySocketConnectionAlert: true,
			socketConnectionState: newSocketConnectionState,
		});
		if (newSocketConnectionState.type === SocketState.CONNECTED.type) {
			if (this.socketStateTimeout) {
				clearTimeout(this.socketStateTimeout);
			}
			this.socketStateTimeout = setTimeout(() => {
				this.setState({
					displaySocketConnectionAlert: false,
				});
			}, 2000);
		}
	};

	shouldDisableIncomingCalls = () => {
		const pathname = window.location.pathname.split('/');
		if (pathname.length > 1) {
			const isCallView = pathname[1] === 'call';

			return isCallView;
		}

		return false;
	};

	getTokensIfCall = () => {
		const { search, pathname } = window.location;
		const query = queryString.parse(search);

		if (!pathname.startsWith('/start-call') || !query.refToken) {
			return null;
		}

		return { refToken: query.refToken };
	};

	isCallView = () => {
		const pathName = window.location.pathname.toString().toLowerCase();
		return pathName.includes('talk-to-patient') || pathName.includes('view-patient');
	};

	render() {
		const { startApplication, socketConnectionState, displaySocketConnectionAlert, displayContentUpdatedAlert } = this.state;
		const currentHealthSystem = getCurrentHealthSystemInfo();
		const currentHealthSystemId = currentHealthSystem ? currentHealthSystem.currentHealthSystemId : null;

		if (startApplication === true || (!isAuthenticated() && !this.getTokensIfCall())) {
			return (
				<div className={this.props.organization.isDarkThemeEnabled === true ? 'dark-theme' : ''}>
					<AuthProvider>
						<Socket onConnectionStateChange={this.onConnectionStateChange} shouldDisableIncomingCalls={this.shouldDisableIncomingCalls}>
							<IncomingCall src='https://static.solaborate.com/americanwell/amwell-logo-small.svg' />
							<BrowserRouter basename='/'>
								<AppInsightsTelemetryProvider>{Routes}</AppInsightsTelemetryProvider>
							</BrowserRouter>
							<Alert
								display={!this.isCallView() && displayContentUpdatedAlert}
								fixed={true}
								persist={true}
								variant='dark'
								hideCloseButton={true}
								message={
									<div>
										New version of the app is available{' '}
										<Button
											onClick={() => {
												window.location.reload(true);
											}}
											size='small'
											text='Refresh'
										/>
									</div>
								}
								onClose={() => {
									this.setState({
										displayContentUpdatedAlert: false,
									});
								}}
							/>
							<div className='reconnecting'>
								<Alert
									display={!this.isCallView() && displaySocketConnectionAlert}
									fixed={true}
									persist={true}
									hideCloseButton={true}
									message={socketConnectionState.message}
									onClose={() => {
										this.setState({
											displaySocketConnectionAlert: false,
										});
									}}
								/>
							</div>
							<UserIdleCheck healthSystemId={currentHealthSystemId} />
						</Socket>
					</AuthProvider>
				</div>
			);
		}

		return (
			<div className={this.props.organization.isDarkThemeEnabled === true ? 'dark-theme' : ''}>
				<Grid rows='auto' width='100%' stretch='100vh' vertAlign='center' horizAlign='center'>
					<Loader />
				</Grid>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		organization: state.organization,
		notifications: state.notifications,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		organizationActions: bindActionCreators(organizationActionCreators, dispatch),
		fetchNotificationCounter: () => dispatch(fetchNotificationCounter()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
