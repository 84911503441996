/* eslint max-classes-per-file: ["error", 11] */
import { v4 } from 'uuid';
import { getIceServers } from 'api/iceServers';
import { getUserProfile, getUserRoleId } from 'infrastructure/auth';

import SocketEvents from 'constants/socket-events';
import {
	ParticipantState,
	CallTypes,
	ObjectType,
	JoinConferenceFailureReasonEnum,
	ConferenceEndReason,
	ParticipantRemoveReason,
	UserTypes,
	StartConferenceFailureReason,
	StartConferenceFailureMessage,
	JoinConferenceFailureMessage,
} from 'constants/enums';

import ConferenceInfo from 'calls/base/ConferenceInfo';
import FromUser from 'calls/base/FromUser';
import Emitter from 'calls/scripts/emitter';
import Participant from 'calls/scripts/participant';
import LocalParticipant from 'calls/scripts/local-participant';
import PatientParticipant from 'calls/scripts/patient-participant';
import ConferenceEvent from 'calls/scripts/conference-events.enum';
import ParticipantEvent from 'calls/scripts/participant-events.enum';
import { ConferenceError, WrongParticipantState, NullConference, FailedInvitationError } from 'calls/scripts/conference-errors';

import {
	SfuConnection,
	Connection,
	Listener,
	TrackType,
	Offer,
	Answer,
	IceCandidate,
	LocalTrackFactoryWithConstraints,
	SipConnection,
} from '@solaborate/webrtc';

import CallStats from 'infrastructure/callStats/callStats';
import { APP_CONFIG } from 'constants/global-variables';

import { callTypeToTrackTypes } from 'calls/helpers/conference-utils';
import { busySound, dropSound, stopOutgoingCallSound } from 'calls/scripts/call-sounds';
import RemoteTrackControllerImpl from 'calls/scripts/remote-track-controller';
import LocalTrackControllerStopTracks from 'calls/scripts/local-track-controller';
import { ParticipantBusy, ParticipantNotAnswering, ParticipantOffline } from 'calls/scripts/participant-state';
import LocalTrackFactoryWithConstraintsAndSignalingErrors from 'calls/scripts/local-track-factory-with-errors';
import ConnectionLogger from 'calls/scripts/connection-logger';
import ConnectionSignaling from 'calls/scripts/connection-signaling';
import { isMobileSafari, isSafari } from 'react-device-detect';
import { getStorage } from 'infrastructure/helpers/commonHelpers';

// used to set custom contraints MediaTrackContraints to localTrackFactory
const constraints = new Map();
constraints.set(TrackType.AUDIO, {
	autoGainControl: false,
});
constraints.set(TrackType.VIDEO, {
	width: { ideal: isSafari ? 640 : 1280 },
	height: { ideal: isSafari ? 360 : 720 },
});
constraints.set(TrackType.SCREEN, { width: { ideal: 1920 }, height: { ideal: 1080 } });
constraints.set(TrackType.SCREENAUDIO, true);

export default class Conference extends Emitter {
	/**
	 *
	 * @param {Object} socket
	 * @param {ConferenceInfo} data
	 * @param {import('@solaborate/webrtc').LocalTrackFactory} localTrackFactory
	 * @param {RTCIceServer[]} iceServers
	 * @param {boolean} [isInitiator]
	 */
	constructor(socket, data, localTrackFactory, iceServers, isInitiator = false) {
		super();
		this.socket = socket;
		this.callType = data.callType;
		this.id = data.conferenceId;
		this.name = data.conferenceName;
		this.conversationId = data.conversationId;
		this.inputDevices = data.inputDevices;
		this.isChat = data.isChat;
		this.isDirectToHello = data.isDirectToHello;
		this.isMeetingRoom = data.isMeetingRoom;
		this.isMultiparty = data.isMultiparty;
		this.isLocked = data.isLocked; // TODO WIP
		this.conferenceLink = data.conferenceLink;
		this.isUsingMediaServer = data.isUsingMediaServer;
		this.failedInvitationToParticipants = data.failedInvitationToParticipants;

		this.participants = new Map();
		this.trackDescriptions = new Map();
		this.outgoingCallTimeouts = new Map();

		this.localTrackFactory = localTrackFactory;
		this.iceServers = iceServers;

		this.notAnsweringTimeout = 60000;
		this.reconnectTimeout = null;
		this.reconnectTimeoutTime = 60000;

		this.callStats = new CallStats(this.id, data.participantId);
		this.useCallStats = APP_CONFIG.useCallStats;
		this.sendCallStatsInterval = APP_CONFIG.sendCallStatsInterval;

		this.isFrameWindow = !!data.refToken;

		this.dummyLocalTrackFactory = null;

		this.sipConnection = null;

		let connection = new Listener();
		if (this.isUsingMediaServer) {
			const signalingPublisher = new ConnectionSignaling(this.socket, data.conferenceId, data.participantId, 'publisher');
			const signalingSubscriber = new ConnectionSignaling(this.socket, data.conferenceId, data.participantId, 'subscriber');
			const logger = new ConnectionLogger(this.socket, data.conferenceId, data.participantId, 'MS');
			connection = new SfuConnection(signalingPublisher, signalingSubscriber, { iceServers, logger });
			Object.assign(window, { pcWithSfu: connection });

			connection.on(this.onPeerConnectionStateChanged);

			if (this.useCallStats) {
				this.callStats.collect({
					connection: connection,
					pushInterval: this.sendCallStatsInterval,
					remoteParticipantIds: () => [...this.participants.keys()],
				});
			}
		} else {
			// @ts-ignore
			connection.negotiate = async offerTracks => {
				this.participants.forEach(p => {
					p.connection.on(this.onPeerConnectionStateChanged);
					p.connection.negotiate(offerTracks);
				});
			};
		}

		const ltc = new LocalTrackControllerStopTracks(connection, this.localTrackFactory, socket, data.conferenceId, data.participantId);

		const { firstName, lastName, profilePicture, userId } = getUserProfile();
		this.localParticipant = new LocalParticipant(
			{
				id: data.participantId,
				name: `${firstName} ${lastName}`,
				objectId: userId,
				objectType: ObjectType.USER,
				picture: profilePicture ? profilePicture.url : '',
				isInitiator,
			},
			connection,
			ltc
		);

		// set local tracks from factory active tracks
		ltc.add([...this.localTrackFactory.tracks.keys()]).then(() => {
			if (!this.localTrackFactory.tracks.has(TrackType.AUDIO)) {
				this.createSafariDummyAudioTrack();
			}
		});

		data.participants.sort((a, b) => b.objectType - a.objectType);
		data.participants.forEach(p => {
			// fix: if no picture is assigned to user then assign this default one
			Object.assign(p, { picture: 'https://maxcdn.developershub.info/media/profile-pictures/150/150/duser.jpg' });
			this.participants.set(p.id, this.createParticipant(p));
		});
		this.bindSocketEvents();
		// @ts-ignore
		window.inviteParticipants = this.inviteParticipants;
	}

	bindSocketEvents() {
		this.socket.on(SocketEvents.Conference.ON_NEW_OFFER, this.onNewOffer);
		this.socket.on(SocketEvents.Conference.ON_NEW_SIP_OFFER, this.onNewSipOffer);
		this.socket.on(SocketEvents.Conference.ON_SIP_SESSION_ENDED, this.onSipSessionEnded);
		this.socket.on(SocketEvents.Conference.ON_NEW_ANSWER, this.onNewAnswer);
		this.socket.on(SocketEvents.Conference.ON_NEW_ICE_CANDIDATE, this.onNewIceCandidate);
		this.socket.on(SocketEvents.Conference.ON_NEW_PARTICIPANT, this.onNewParticipant);
		this.socket.on(SocketEvents.Conference.ON_PARTICIPANT_LEFT, this.onParticipantLeft);
		this.socket.on(SocketEvents.Conference.ON_UPDATE_PARTICIPANTS, this.onParticipantUpdate);
		this.socket.on(SocketEvents.Conference.ON_PARTICIPANT_BUSY, this.onParticipantBusy);
		this.socket.on(SocketEvents.Conference.ON_PARTICIPANT_DECLINED, this.onParticipantDeclined);
		this.socket.on(SocketEvents.Conference.ON_PARTICIPANT_NOT_ANSWERING, this.onParticipantNotAnswering);
		this.socket.on(SocketEvents.Conference.ON_PARTICIPANT_REMOVED, this.onParticipantRemoved);
		this.socket.on(SocketEvents.Conference.ON_PARTICIPANT_OFFLINE, this.onParticipantOffline);
		this.socket.on(SocketEvents.Conference.ON_INITIATOR_LEFT, this.onInitiatorLeft);
		this.socket.on(SocketEvents.Conference.ON_ENDED, this.onEnded);
		this.socket.on(SocketEvents.Conference.ON_TERMINATE_REQUEST, this.onTerminateRequest);
		this.socket.on(SocketEvents.Conference.ON_TRANSFERRED_TO_ANOTHER_CLIENT, this.onTransferedToAnotherClient);
		this.socket.on(SocketEvents.Conference.ON_SWITCH_TO_MEDIASERVER, this.onSwitchToMediaServer);
		this.socket.on(SocketEvents.Conference.ON_REMOVED, this.onConferenceRemoved);
		this.socket.on(SocketEvents.Conference.ON_TOGGLE_TRACK, this.onToggleTrack);
		this.socket.on(SocketEvents.Conference.ON_OWNER_CHANGED, this.onOwnerChanged);

		this.socket.on(SocketEvents.HelloDevice.ON_OFFLINE, this.onHelloOffline);
		this.socket.on(SocketEvents.HelloDevice.ON_BUSY, this.onHelloBusy);

		this.socket.on(SocketEvents.MediaServer.ON_OUTGOING_TAKE_ANSWER, this.onNewSfuAnswer);
		this.socket.on(SocketEvents.MediaServer.ON_INCOMING_TAKE_OFFER, this.onNewSfuOffer);

		this.socket.on(SocketEvents.Client.ON_AUTHENTICATED, this.onReconnected);
		this.socket.on(SocketEvents.Client.ON_DISCONNECT, this.onSocketDisconnected);
	}

	unBindSocketEvents() {
		this.socket.off(SocketEvents.Conference.ON_NEW_OFFER, this.onNewOffer);
		this.socket.off(SocketEvents.Conference.ON_NEW_SIP_OFFER, this.onNewSipOffer);
		this.socket.off(SocketEvents.Conference.ON_SIP_SESSION_ENDED, this.onSipSessionEnded);
		this.socket.off(SocketEvents.Conference.ON_NEW_ANSWER, this.onNewAnswer);
		this.socket.off(SocketEvents.Conference.ON_NEW_ICE_CANDIDATE, this.onNewIceCandidate);
		this.socket.off(SocketEvents.Conference.ON_NEW_PARTICIPANT, this.onNewParticipant);
		this.socket.off(SocketEvents.Conference.ON_PARTICIPANT_LEFT, this.onParticipantLeft);
		this.socket.off(SocketEvents.Conference.ON_UPDATE_PARTICIPANTS, this.onParticipantUpdate);
		this.socket.off(SocketEvents.Conference.ON_PARTICIPANT_BUSY, this.onParticipantBusy);
		this.socket.off(SocketEvents.Conference.ON_PARTICIPANT_DECLINED, this.onParticipantDeclined);
		this.socket.off(SocketEvents.Conference.ON_PARTICIPANT_NOT_ANSWERING, this.onParticipantNotAnswering);
		this.socket.off(SocketEvents.Conference.ON_PARTICIPANT_REMOVED, this.onParticipantRemoved);
		this.socket.off(SocketEvents.Conference.ON_PARTICIPANT_OFFLINE, this.onParticipantOffline);
		this.socket.off(SocketEvents.Conference.ON_INITIATOR_LEFT, this.onInitiatorLeft);
		this.socket.off(SocketEvents.Conference.ON_ENDED, this.onEnded);
		this.socket.off(SocketEvents.Conference.ON_TERMINATE_REQUEST, this.onTerminateRequest);
		this.socket.off(SocketEvents.Conference.ON_TRANSFERRED_TO_ANOTHER_CLIENT, this.onTransferedToAnotherClient);
		this.socket.off(SocketEvents.Conference.ON_SWITCH_TO_MEDIASERVER, this.onSwitchToMediaServer);
		this.socket.off(SocketEvents.Conference.ON_REMOVED, this.onConferenceRemoved);
		this.socket.off(SocketEvents.Conference.ON_TOGGLE_TRACK, this.onToggleTrack);
		this.socket.off(SocketEvents.Conference.ON_OWNER_CHANGED, this.onOwnerChanged);

		this.socket.off(SocketEvents.HelloDevice.ON_OFFLINE, this.onHelloOffline);
		this.socket.off(SocketEvents.HelloDevice.ON_BUSY, this.onHelloBusy);

		this.socket.off(SocketEvents.MediaServer.ON_OUTGOING_TAKE_ANSWER, this.onNewSfuAnswer);
		this.socket.off(SocketEvents.MediaServer.ON_INCOMING_TAKE_OFFER, this.onNewSfuOffer);

		this.socket.off(SocketEvents.Client.ON_AUTHENTICATED, this.onReconnected);
		this.socket.off(SocketEvents.Client.ON_DISCONNECT, this.onSocketDisconnected);
	}

	isParticipantStateActive = state => {
		return [ParticipantState.CONNECTED.type, ParticipantState.CONNECTING.type, ParticipantState.RECONNECTING.type].includes(state);
	};

	// #region conference actions

	/**
	 * private
	 */
	onReconnected = () => {
		if (this.reconnectTimeout) {
			clearTimeout(this.reconnectTimeout);
			this.reconnectTimeout = null;
		}

		const { tracks } = this.localParticipant.localTrackController;

		this.socket.emit(
			SocketEvents.Conference.PARTICIPANT_RECONNECT,
			{
				conferenceId: this.id,
				participantId: this.localParticipant.id,
				isAudio: !!tracks.get(TrackType.AUDIO),
				isVideo: !!tracks.get(TrackType.VIDEO),
				isScreen: !!tracks.get(TrackType.SCREEN),
			},
			data => {
				if (data.isActive && this.isParticipantStateActive(data.myState)) {
					this.onParticipantUpdate(data);
					return;
				}
				this.destroy();
				// emit ConferenceEvent.ON_ENDED for UI
				this.emit(ConferenceEvent.ON_ENDED, { reason: ConferenceEndReason.DROPPED });
			}
		);
	};

	onSocketDisconnected = () => {
		this.reconnectTimeout = setTimeout(() => {
			this.destroy();
			this.emit(ConferenceEvent.ON_ENDED, { reason: ConferenceEndReason.DROPPED });
		}, this.reconnectTimeoutTime);
	};

	/**
	 * private
	 */
	onSwitchToMediaServer = async () => {
		if (this.isUsingMediaServer) {
			console.log('already switched to ms, ignore');
			return;
		}
		// stop p2p stats
		this.callStats.stop();

		this.isUsingMediaServer = true;
		const signalingPublisher = new ConnectionSignaling(this.socket, this.id, this.localParticipant.id, 'publisher');
		const signalingSubscriber = new ConnectionSignaling(this.socket, this.id, this.localParticipant.id, 'subscriber');
		const logger = new ConnectionLogger(this.socket, this.id, this.localParticipant.id, 'MS');
		const sfuConnection = new SfuConnection(signalingPublisher, signalingSubscriber, { logger: logger, iceServers: this.iceServers });

		this.participants.forEach(p => {
			if (p.isSip) return;
			p.connection.clear();
		});
		sfuConnection.on(this.onPeerConnectionStateChanged);
		this.localParticipant.localTrackController.clearNegotiators();
		this.localParticipant.sfuConnection = sfuConnection;
		if (this.sipConnection) {
			this.localParticipant.localTrackController.addNegotiator(this.sipConnection);
		}
		this.localParticipant.localTrackController.addNegotiator(sfuConnection);

		if (this.useCallStats) {
			// use call stats for participant
			this.callStats.collect({
				connection: sfuConnection,
				pushInterval: this.sendCallStatsInterval,
				remoteParticipantIds: () => [...this.participants.keys()],
			});
		}

		this.participants.forEach(participant => {
			if (participant.isSip) return;
			const connection = this.localParticipant.sfuConnection.createConnection(participant.id);
			if (this.useCallStats) {
				// use call stats for participant
				this.callStats.collect({
					connection: connection,
					pushInterval: this.sendCallStatsInterval,
					remoteParticipantIds: [participant.id],
				});
			}
			participant.setConnection(connection);
		});

		await sfuConnection.connect();
	};

	/**
	 * Method used to create a participant instance
	 * @param {Object} participant
	 * @param {LocalTrackFactoryWithConstraintsAndSignalingErrors} trackFactory
	 * @returns {Participant}
	 */
	createParticipant = participant => {
		let ltc;
		let connection;
		if (participant.isSip) {
			if (!this.sipConnection) {
				const signaling = new ConnectionSignaling(this.socket, this.id, this.localParticipant.id, 'sip');
				const logger = new ConnectionLogger(this.socket, this.id, this.localParticipant.id, 'SIP');
				this.sipConnection = new SipConnection(signaling, { logger: logger, iceServers: this.iceServers, sendIceWithSdp: true });
				this.localParticipant.localTrackController.addNegotiator(this.sipConnection);
			}
			connection = this.sipConnection.createConnection(participant.id);
			ltc = this.localParticipant.localTrackController;
		} else if (this.isUsingMediaServer) {
			connection = this.localParticipant.sfuConnection.createConnection(participant.id);
		} else {
			const signaling = new ConnectionSignaling(this.socket, this.id, this.localParticipant.id, participant.id);
			const logger = new ConnectionLogger(this.socket, this.id, this.localParticipant.id, participant.id);
			connection = new Connection(signaling, { logger: logger, iceServers: this.iceServers });
			connection.sendEncodings.set(TrackType.VIDEO, [{ maxBitrate: 3000000 }]);

			if (this.callType !== CallTypes.MONITORING) {
				ltc = this.localParticipant.localTrackController;
				ltc.addNegotiator(connection);
				ltc.add([...this.localTrackFactory.tracks.keys()]);
			} else {
				ltc = new LocalTrackControllerStopTracks(connection, this.localTrackFactory, this.socket, this.id, this.localParticipant.id, participant.id);
			}
		}

		if (this.useCallStats) {
			// use call stats for participant
			this.callStats.collect({
				connection: connection,
				pushInterval: this.sendCallStatsInterval,
				remoteParticipantIds: [participant.id],
			});
		}

		const rtc = new RemoteTrackControllerImpl(connection, this.isUsingMediaServer, this.socket, this.id, this.localParticipant.id, participant.id);

		// TODO: there should be another parameter to mark the patient in the call is objectType is not enough
		if (participant.objectType === ObjectType.HELLO_DEVICE) {
			return new PatientParticipant(this.socket, this.id, participant, this.localParticipant.id, connection, ltc, rtc);
		}

		return new Participant(participant, this.localParticipant.id, connection, ltc, rtc);
	};

	/**
	 * Method used to invite participant by objectId or email
	 * @typedef InviteResponse
	 * @property {string} id
	 * @property {string} name
	 * @property {number} objectId
	 * @property {number} objectType
	 * @property {string} reason
	 * @param {Object[]} participants
	 * @param {String} participants[].id
	 * @param {Number} participants[].objectId
	 * @param {Number} participants[].objectType
	 * @param {String[]} emailList
	 * @param {String[]} phoneNumberList
	 * @param {String} siteName
	 * @return {Promise<{failedInvitationToParticipants: InviteResponse[]}>}
	 */
	inviteParticipants = (participants, emailList = [], phoneNumberList = [], siteName = '') => {
		// TODO: provide a mechanism to wait for a certain amount of time for that participant to accept/join the/in call
		// this.startOutgoingCallTimeout(participants); // can be called also before calling inviteParticipants...
		return this.socket.emitWithPromise(SocketEvents.Conference.INVITE_PARTICIPANTS, {
			participants: participants,
			emailList: emailList,
			conferenceId: this.id,
			participantId: this.localParticipant.id,
			phoneNumberList: phoneNumberList,
			siteName: siteName,
		});
	};

	/**
	 *
	 * @param {Map<TrackType, MediaTrackConstraintSet>} newConstraints
	 */
	changeMediaConstraints = async newConstraints => {
		newConstraints.forEach((constraint, type) => {
			constraints.set(type, { ...(constraints.get(type) ?? {}), ...constraint });
		});

		const oldTracks = [...newConstraints.keys()].map(type => this.localParticipant.localTrackController.tracks.get(type)).filter(track => track);

		if (oldTracks.length === 0) {
			return;
		}

		oldTracks.forEach(track => track.stop());
		await this.localParticipant.localTrackController.add(oldTracks.map(t => t.type));
	};

	toggleMobileCameraSwitch = async () => {
		const videoTrack = this.localParticipant.localTrackController.tracks.get(TrackType.VIDEO);
		if (!videoTrack) {
			return;
		}

		const { facingMode } = videoTrack.track.getSettings();

		if (!facingMode) {
			return;
		}

		const newConstraints = new Map();

		newConstraints.set(TrackType.VIDEO, { facingMode: { exact: facingMode === 'user' ? 'environment' : 'user' } });

		this.changeMediaConstraints(newConstraints);
	};

	/**
	 * Method used to start outgoing call timeouts for participants being invited
	 * @param {*} participants
	 */
	startOutgoingCallTimeout = participants => {
		participants.forEach(participant => {
			// start a timeout to wait for the participant to join
			// clear the timeout if the participant join - onNewParticipant
			// if timeout is executed then emit ParticipantNotAnswering to change UI
			const timeout = setTimeout(() => {
				this.participants.get(participant.id).state = ParticipantState.NOT_ANSWERING.type;
				this.emit(
					ConferenceEvent.ON_NEW_PARTICIPANT_STATE,
					new ParticipantNotAnswering({
						conferenceId: '',
						participantId: participant.id,
						conferenceEnded: false,
					})
				);
			}, this.notAnsweringTimeout);
			this.outgoingCallTimeouts.set(participant.id, timeout);
		});
	};

	/**
	 * Method used to toggle local participant track
	 * @param {TrackType} type
	 * @param {boolean} enable
	 */
	toggleLocalParticipantTrack = async (type, enable) => {
		if (enable) {
			await this.localParticipant.localTrackController.add(type);
		} else {
			// stop -> only stops the track
			this.localParticipant.localTrackController.tracks.get(type).stop();
			// remove -> only removes it from RTCPeerConnection
			const remove = [type];
			if (type === TrackType.SCREEN) {
				const screenAudioTrack = this.localParticipant.localTrackController.tracks.get(TrackType.SCREENAUDIO);
				if (screenAudioTrack) {
					screenAudioTrack.stop();
					remove.push(TrackType.SCREENAUDIO);
				}
			}
			await this.localParticipant.localTrackController.remove(remove);
			if (type === TrackType.AUDIO) {
				this.localParticipant.emit(ParticipantEvent.VOLUME_CHANGED, 0);
			}
		}

		this.emit(ConferenceEvent.ON_CHANGED_STATE);
	};

	/**
	 * Method used to send remove participant event
	 * @param {string} actioneeParticipantId
	 */
	sendRemoveParticipantEvent(actioneeParticipantId) {
		this.socket.emit(SocketEvents.Conference.REMOVE_PARTICIPANT, {
			conferenceId: this.id,
			participantId: this.localParticipant.id,
			actioneeParticipantId,
		});
	}

	/**
	 * Method used to leave a conference
	 * @param {Number} [reason]
	 */
	leave(reason) {
		const leaveData = {
			participantId: this.localParticipant.id,
			conferenceId: this.id,
		};
		Object.assign(leaveData, reason != null && { leaveReason: reason });
		this.socket.emit(SocketEvents.Conference.LEAVE, leaveData);
		this.destroy();
	}

	/**
	 * Safari mobile will not autoplay media elements unless there's an active local media stream.
	 * We need to always create a dummy local media stream regardless of the user joining with or without audio,
	 * in order for autoplay to work properly
	 */
	async createSafariDummyAudioTrack() {
		if (!isMobileSafari) {
			return;
		}

		this.dummyLocalTrackFactory = new LocalTrackFactoryWithConstraints(constraints);
		await this.dummyLocalTrackFactory.createTracks([TrackType.AUDIO]);
	}

	/**
	 * Method used to destroy the conference
	 * -> destroy local tracks
	 * -> destroy socket listeners
	 * -> close participant peer connections
	 * -> destroy call stats
	 */
	async destroy() {
		this.localTrackFactory.destroy();

		if (this.dummyLocalTrackFactory) {
			this.dummyLocalTrackFactory.destroy();
		}

		if (this.useCallStats) {
			await this.callStats.stop();
		}

		this.unBindSocketEvents();

		if (this.sipConnection) {
			this.sipConnection.close();
		}
		this.sipConnection = null;

		this.participants.forEach(({ connection }) => {
			connection.close();
		});

		if (this.isUsingMediaServer && !this.localParticipant.sfuConnection.isClosed()) {
			this.localParticipant.sfuConnection.close();
		}

		if (getUserRoleId() === UserTypes.GUEST) {
			['access_token', 'userId', 'userProfile', 'userRoleId', 'memberExists'].forEach(key => sessionStorage.removeItem(key));
			this.socket.doDisconnect();
		}

		if (this.reconnectTimeout) {
			clearTimeout(this.reconnectTimeout);
			this.reconnectTimeout = null;
		}

		this.hasEnded = true;
	}

	/**
	 * Method used to remove a participant by id
	 * @param {string} actioneeParticipantId
	 */
	removeParticipant = actioneeParticipantId => {
		const participant = this.participants.get(actioneeParticipantId);
		if (!this.localParticipant.isInitiator || !participant) {
			return;
		}

		participant.state = ParticipantState.REMOVING.type;
		participant.emit(ParticipantEvent.STATE_CHANGED, participant.state);
		this.sendRemoveParticipantEvent(actioneeParticipantId);
	};

	deleteParticipantAfterTimeout = participantId => {
		setTimeout(() => {
			this.participants.delete(participantId);
			this.emit(ConferenceEvent.ON_CHANGED_STATE, { action: ParticipantEvent.REMOVED, participantId });
		}, 3000);
	};

	sendChangeStreamConfigEvent = config => {
		if (!this.isUsingMediaServer) {
			return;
		}

		this.socket.emit(SocketEvents.MediaServer.INCOMING_SEND_CONFIG, {
			conferenceId: this.id,
			subscriberParticipantId: this.localParticipant.id,
			config,
		});
	};

	/**
	 * Transfer ownership to participantId
	 * @param participantId: string
	 */
	transferOwnership = async participantId => {
		if (!this.localParticipant.isInitiator) {
			throw 'not initiator';
		}
		const response = await this.socket.emitWithPromise(SocketEvents.Conference.TRANSFER_OWNERSHIP, {
			conferenceId: this.id,
			participantId: participantId,
		});
		if (!response.ok) {
			throw response.failureReason;
		}

		this.localParticipant.isInitiator = false;
		this.emit(ConferenceEvent.ON_CHANGED_STATE);
		return response;
	};
	// #endregion

	// #region socket listeners
	/**
	 * Method used to init a sfu connection
	 * @private
	 */
	onInitSfuOffer = async () => {
		await this.localParticipant.sfuConnection.connect([]);
	};

	/**
	 * Method used to check new ownership of conference
	 * @private
	 * @param {Object} data
	 * @param {string} data.previousInitiatorParticipantId
	 * @param {string} data.newInitiatorId
	 */
	onOwnerChanged = async ({ newInitiatorId, previousInitiatorParticipantId }) => {
		if (this.localParticipant.id === newInitiatorId) {
			this.localParticipant.isInitiator = true;
		}
		const oldInitiator = this.participants.get(previousInitiatorParticipantId);
		let newInitiator = this.participants.get(newInitiatorId);
		if (!newInitiator) {
			newInitiator = this.localParticipant;
		}
		this.emit(ConferenceEvent.ON_CHANGED_STATE, { action: ParticipantEvent.OWNER_CHANGED, oldInitiator: oldInitiator, newInitiator: newInitiator });
	};

	/**
	 * Method used to forward a sfu offer
	 * @private
	 */
	onNewSfuOffer = async offer => {
		this.localParticipant.sfuConnection.subscribing.signaling.on(new Offer(offer.sdp));
	};

	/**
	 * @private
	 */
	onNewSfuAnswer = answer => {
		this.localParticipant.sfuConnection.publishing.signaling.on(new Answer(answer.sdp));
	};

	/**
	 * @private
	 */
	onNewSipOffer = offer => {
		Object.assign(offer.sdp, {
			tracks: {
				'audio-0': {
					type: TrackType.AUDIO,
				},
			},
		});
		this.sipConnection.connection.signaling.on(new Offer(offer.sdp));
	};

	/**
	 * @private
	 */
	onSipSessionEnded = () => {
		if (!this.sipConnection) return;
		this.sipConnection.close();
		this.localParticipant.localTrackController.removeNegotiator(this.sipConnection);
		this.sipConnection = null;
	};

	/**
	 * @private
	 */
	onNewOffer = offer => {
		if (this.isUsingMediaServer) return;
		this.participants.get(offer.participantId).connection.signaling.on(new Offer(offer.sdp));
	};

	/**
	 * @private
	 */
	onNewAnswer = answer => {
		if (this.isUsingMediaServer) return;
		this.participants.get(answer.participantId).connection.signaling.on(new Answer(answer.sdp));
	};

	/**
	 * @private
	 */
	onNewIceCandidate = iceCandidate => {
		if (this.isUsingMediaServer) return;
		if (iceCandidate.candidate.sdp) {
			// eslint-disable-next-line no-param-reassign
			iceCandidate.candidate.candidate = iceCandidate.candidate.sdp;
		}
		this.participants.get(iceCandidate.participantId).connection.signaling.on(new IceCandidate(iceCandidate.candidate));
	};

	/**
	 * Method used to handle on new participant event
	 * @private
	 * @param {Object} data
	 * @param {Object} data.participant
	 */
	onNewParticipant = async ({ participant: newParticipant }) => {
		let participant = this.participants.get(newParticipant.id);
		if (!participant) {
			// user might have been invited
			participant = this.createParticipant(newParticipant);
			// temp fix: added default picture because amwell users do not have a picture
			Object.assign(participant, {
				picture: 'https://maxcdn.developershub.info/media/profile-pictures/150/150/duser.jpg',
			});
			this.participants.set(newParticipant.id, participant);
		}

		if (!this.isUsingMediaServer && !newParticipant.isSip) {
			const types = callTypeToTrackTypes(this.callType);
			// connect and generate the initial offer
			await participant.connection.connect(types.remoteTrackTypes);
		}

		// emit new participant event to update the UI
		this.emit(ConferenceEvent.ON_NEW_PARTICIPANT, participant);
	};

	/**
	 * Method used to handle on participant update event
	 * @param {Object} data
	 * @param {Object} data.participants
	 * @private
	 */
	onParticipantUpdate = ({ participants }) => {
		participants.forEach(newParticipant => {
			let participant = this.participants.get(newParticipant.id);
			if (!this.isParticipantStateActive(newParticipant.state)) {
				if (participant) {
					participant.state = newParticipant.state;
					this.onParticipantLeft({
						participantId: participant.id,
						conferenceId: this.id,
						conferenceEnded: false,
					});
				}
				return;
			}
			if (!participant) {
				// user might have been invited
				participant = this.createParticipant(newParticipant);
				this.participants.set(newParticipant.id, participant);
				this.emit(ConferenceEvent.ON_NEW_PARTICIPANT, participant);
			}

			// TODO change this when amwell users(web&hello) have the ability to upload a personal photo
			Object.assign(newParticipant, { picture: 'https://maxcdn.developershub.info/media/profile-pictures/150/150/duser.jpg' });
			// fix: shotPTZ is by default false, and on SecurityCam it should be
			if (participant.constructor === PatientParticipant) {
				Object.assign(newParticipant, { showPTZ: participant.showPTZ ?? newParticipant.callType === CallTypes.SECURITYCAM });
			}
			Object.assign(participant, newParticipant);
		});
	};

	/**
	 * Method used to handle on participant left event
	 * @private
	 * @param {object} data
	 * @param {string} data.participantId
	 * @param {string} data.conferenceId
	 * @param {boolean} data.conferenceEnded
	 * @param {number} data.reason
	 */
	onParticipantLeft = data => {
		/**
		 * 	change the participant state to LEFT_CALL
		 * 	call participant destroy to destroy the RTCPeerConnection
		 * 	emit ConferenceEvent.ON_NEW_PARTICIPANT_STATE event
		 * 	if calltype is not monitoring -> set a timeout to delete the participant from this.participants
		 */
		const participant = this.participants.get(data.participantId);
		// check needed because signaling is sending this event even after conference ended
		if (!participant || ![ParticipantState.CONNECTING.type, ParticipantState.CONNECTED.type, ParticipantState.RECONNECTING.type].includes(participant.state)) {
			return;
		}
		if (data.reason) {
			participant.leaveReason = data.reason;
		}
		participant.state = ParticipantState.LEFT_CALL.type;
		participant.emit(ParticipantEvent.STATE_CHANGED, participant.state);
		participant.destroy();
		if (this.callType && this.callType !== CallTypes.MONITORING) {
			this.deleteParticipantAfterTimeout(participant.id);
		}
	};

	/**
	 * Method used to handle participant busy event
	 * @private
	 * @param {object} data
	 * @param {string} data.conferenceId
	 * @param {string} data.participantId
	 * @param {number} data.objectId
	 * @param {number} data.objectType
	 * @param {boolean} data.conferenceEnded
	 * @param {object} data.activeConferences
	 */
	onParticipantBusy = data => {
		/**
		 * 	change the participant state to BUSY
		 * 	emit ConferenceEvent.ON_NEW_PARTICIPANT_STATE event
		 * 	if calltype is not monitoring -> set a timeout to delete the participant from this.participants
		 */
		const participant = this.participants.get(data.participantId);
		if (!participant || ![ParticipantState.CONNECTING.type, ParticipantState.CONNECTED.type].includes(participant.state)) {
			return;
		}

		participant.state = ParticipantState.BUSY.type;
		participant.emit(ParticipantEvent.STATE_CHANGED, participant.state);
		if (participant.callType && participant.callType !== CallTypes.MONITORING) {
			this.deleteParticipantAfterTimeout(participant.id);
		}
	};

	/**
	 * Method used to handle on participant decline event
	 * @private
	 * @param {object} data
	 * @param {string} data.conferenceId
	 * @param {string} data.participantId
	 * @param {boolean} data.conferenceEnded
	 */
	onParticipantDeclined = data => {
		/**
		 * 	change the participant state to DECLINED
		 * 	emit ConferenceEvent.ON_NEW_PARTICIPANT_STATE event
		 * 	if calltype is not monitoring -> set a timeout to delete the participant from this.participants
		 */
		const participant = this.participants.get(data.participantId);
		if (!participant || ![ParticipantState.CONNECTING.type, ParticipantState.CONNECTED.type].includes(participant.state)) {
			return;
		}

		stopOutgoingCallSound();
		participant.state = ParticipantState.DECLINED.type;
		participant.emit(ParticipantEvent.STATE_CHANGED, participant.state);
		if (participant.callType && participant.callType !== CallTypes.MONITORING) {
			this.deleteParticipantAfterTimeout(participant.id);
		}
	};

	/**
	 * Method used to handle on participant not answering event
	 * @private
	 * @param {object} data
	 * @param {string} data.conferenceId
	 * @param {string} data.participantId
	 * @param {boolean} data.conferenceEnded
	 */
	onParticipantNotAnswering = data => {
		/**
		 * 	change the participant state to NOT_ANSWERING
		 * 	emit ConferenceEvent.ON_NEW_PARTICIPANT_STATE event
		 * 	if calltype is not monitoring -> set a timeout to delete the participant from this.participants
		 */
		const participant = this.participants.get(data.participantId);
		if (!participant || ![ParticipantState.CONNECTING.type, ParticipantState.CONNECTED.type].includes(participant.state)) {
			return;
		}

		participant.state = ParticipantState.NOT_ANSWERING.type;
		participant.emit(ParticipantEvent.STATE_CHANGED, participant.state);
		if (participant.callType && participant.callType !== CallTypes.MONITORING) {
			this.deleteParticipantAfterTimeout(participant.id);
		}
	};

	/**
	 * Method used to handle on participant removed event
	 * @private
	 * @param {object} data
	 * @param {string} data.conferenceId
	 * @param {string} data.participantId
	 * @param {boolean} data.actioneeParticipantId
	 */
	onParticipantRemoved = data => {
		/**
		 * 	change the participant state to REMOVED
		 * 	call participant destroy to destroy the RTCPeerConnection
		 * 	emit ConferenceEvent.ON_NEW_PARTICIPANT_STATE event
		 * 	set a timeout to delete the participant from this.participants
		 */
		const participant = this.participants.get(data.actioneeParticipantId);
		if (
			!participant ||
			![ParticipantState.CONNECTING.type, ParticipantState.CONNECTED.type, ParticipantState.REMOVING.type, ParticipantState.LEFT_CALL.type].includes(
				participant.state
			)
		) {
			return;
		}

		participant.state = ParticipantState.REMOVED.type;
		participant.emit(ParticipantEvent.STATE_CHANGED, participant.state);
		participant.removeReason = data.reason;
		participant.destroy();
		if (participant.callType && participant.callType !== CallTypes.MONITORING && !this.localParticipant.isInitiator) {
			this.deleteParticipantAfterTimeout(participant.id);
		} else {
			if (participant.removeReason !== ParticipantRemoveReason.CONFERENCE_TERMINATED_BY_ADMINISTRATOR) {
				this.participants.delete(participant.id);
			}
			this.emit(ConferenceEvent.ON_CHANGED_STATE, { action: ParticipantEvent.REMOVED, participantId: participant.id });
		}
	};

	/**
	 * Method used to handle on participant offline event
	 * @private
	 * @param {Object} data
	 * @param {String} data.conferenceId
	 * @param {String} data.participantId
	 * @param {Number} data.objectId
	 * @param {Number} [data.helloDeviceId]
	 * @param {Number} data.objectType
	 * @param {Boolean} data.conferenceEnded
	 */
	onParticipantOffline = data => {
		/**
		 * 	change the participant state to OFFLINE
		 * 	emit ConferenceEvent.ON_NEW_PARTICIPANT_STATE event
		 * 	if calltype is not monitoring -> set a timeout to delete the participant from this.participants
		 */
		const participant = this.participants.get(data.participantId);
		if (!participant || ![ParticipantState.CONNECTING.type, ParticipantState.CONNECTED.type].includes(participant.state)) {
			return;
		}

		participant.state = ParticipantState.OFFLINE.type;
		participant.emit(ParticipantEvent.STATE_CHANGED, participant.state);
		if (participant.callType && participant.callType !== CallTypes.MONITORING) {
			this.deleteParticipantAfterTimeout(participant.id);
		}
	};

	/**
	 * Method used to handle on hello offline event
	 * @private
	 * @param {Object} data
	 * @param {String} data.conferenceId
	 * @param {String} data.participantId
	 * @param {Number} data.objectId
	 * @param {Number} [data.helloDeviceId]
	 * @param {Number} data.objectType
	 * @param {Boolean} data.conferenceEnded
	 */
	onHelloOffline = data => {
		/**
		 * TODO
		 * My idea to implement this is:
		 * IF the participant state is not equal to CONNECTING or CONNECTED
		 * 		return undefined;
		 * ELse
		 * 		change the participant state to OFFLINE
		 * 		emit ConferenceEvent.ON_NEW_PARTICIPANT_STATE event
		 * 		if calltype is not monitoring -> set a timeout to delete the participant from this.participants after 2-3 seconds
		 */
		if (data.participantId) {
			this.participants.get(data.participantId).state = ParticipantState.OFFLINE.type;
		} else {
			const { id } = [...this.participants.values()].find(p => p.objectId === data.helloDeviceId);
			this.participants.get(id).state = ParticipantState.OFFLINE.type;
		}
		this.emit(ConferenceEvent.ON_NEW_PARTICIPANT_STATE, new ParticipantOffline(data));
	};

	/**
	 * Method used to handle on hello busy event
	 * @private
	 * @param {Object} data
	 * @param {String} data.conferenceId
	 * @param {String} data.participantId
	 * @param {Number} data.objectId
	 * @param {Number} data.objectType
	 * @param {Boolean} data.conferenceEnded
	 * @param {Object} data.activeConferences
	 */
	onHelloBusy = data => {
		/**
		 * TODO
		 * My idea to implement this is:
		 * IF the participant state is not equal to CONNECTING or CONNECTED
		 * 		return undefined;
		 * ELse
		 * 		change the participant state to BUSY
		 * 		emit ConferenceEvent.ON_NEW_PARTICIPANT_STATE event
		 * 		if calltype is not monitoring -> set a timeout to delete the participant from this.participants after 2-3 seconds
		 */
		this.participants.get(data.participantId).state = ParticipantState.BUSY.type;
		this.emit(ConferenceEvent.ON_NEW_PARTICIPANT_STATE, new ParticipantBusy(data));
	};

	/**
	 * Method used to handle on conference ended event
	 * @private
	 */
	onEnded = async data => {
		// TODO
		// play a sound despite the data.reason
		if (
			[
				ConferenceEndReason.PARTICIPANT_OFFLINE,
				ConferenceEndReason.PARTICIPANT_BUSY,
				ConferenceEndReason.PARTICIPANT_NOT_ANSWERING,
				ConferenceEndReason.PARTICIPANT_DECLINED,
				ConferenceEndReason.PARTICIPANT_INVITE_DENIED,
				ConferenceEndReason.PARTICIPANT_INVITE_FAILED,
				ConferenceEndReason.FAILED_TO_GET_INITIATOR_INFO,
			].includes(data.reason)
		) {
			stopOutgoingCallSound();
			await busySound();
		} else {
			if (ConferenceEndReason.TERMINATED_BY_ADMINISTRATOR) {
				stopOutgoingCallSound();
			}
			await dropSound();
		}
		this.destroy();

		// emit ConferenceEvent.ON_ENDED for UI
		this.emit(ConferenceEvent.ON_ENDED, data);
	};

	/**
	 * Method used to handle on terminate request event
	 * @private
	 */
	onTerminateRequest = data => {
		// TODO
		// emit SocketEvents.Conference.TERMINATE_REQUEST_ACCEPT
		// if ack has ok property to true
		//		call this.destroy
		// 		emit ConferenceEvent.ON_ENDED for UI changes with endReason ConferenceEndReason.TERMINATED_BY_ADMINISTRATOR
		// else
		//		log the terminate failure reason

		// TODO
		// propose feature -> display modal to be asked if you want to accpect the termination by administrator
		// reason -> you could be talking something important to the patient in that moment
		// the modal should be shown for 10-20 sekonds
		// if there are no user actions that it will be automaticlly accepted
		// the user can decline the terminate request
		this.emit(ConferenceEvent.ON_ENDED, { reason: ConferenceEndReason.TERMINATED_BY_ADMINISTRATOR });
	};

	/**
	 * Method used to handle on initiator left
	 * @private
	 */
	onInitiatorLeft = data => {
		// TODO
		// call this.destroy and emit on_ended event with endReason INITIATOR_LEFT
		this.emit(ConferenceEvent.ON_ENDED, { reason: ConferenceEndReason.INITIATOR_LEFT });
	};

	/**
	 * @private
	 */
	onTransferedToAnotherClient = () => {
		// TODO
		// used for a bugfix
		// in case of this event just call this.destroy
		// emit conference ended for UI changes with endReason ABORTED.
	};

	/**
	 * On participant removed from conference
	 */
	onConferenceRemoved = () => {
		if (this.localParticipant.isInitiator) {
			return;
		}

		this.destroy();

		this.emit(ConferenceEvent.ON_REMOVED);
	};

	/**
	 * On toggle participant track
	 * @param {{participantId: string, actioneeParticipantId: string, conferenceId: string, muted: boolean, type: number}} ev
	 */
	onToggleTrack = ev => {
		if (this.localParticipant.isInitiator) {
			return;
		}

		const trackType = Object.values(TrackType)[ev.type - 1];

		this.toggleLocalParticipantTrack(trackType, !ev.muted);
	};

	/**
	 * @param {object} state
	 * @param {object} state.event
	 * @param {RTCPeerConnection} state.event.target
	 */
	onPeerConnectionStateChanged = ({ event }) => {
		if (!event?.target) {
			return;
		}

		this.emit(ConferenceEvent.ON_CONNECTION_STATE_CHANGED, {
			disconnected: event.target.iceConnectionState === 'disconnected' || event.target.iceConnectionState === 'failed',
		});
	};

	/**
	 * This method is used to check if Hello Device has an active Monitoring Session ongoing
	 * @param {number} objectId Hello participant objectId
	 */
	checkDeviceIsBeingMonitored = async objectId => {
		const response = await this.socket.emitWithPromise(SocketEvents.HelloDevice.CHECK_DEVICE_IN_CONFERENCE, { objectId, objectType: ObjectType.HELLO_DEVICE });
		return response.activeConferences?.some(conference => conference.callType === CallTypes.MONITORING);
	};
	// #endregion

	// #region static methods
	static abortConference = false;

	/**
	 * Method used to start a conference
	 * @static
	 * @param {any} socket
	 * @param {number} callType
	 * @param {ConferenceParticipant[]} participants
	 * @param {string} conferenceName
	 * @param {object} selectedMediaDevices
	 * @param {string} conversationId
	 * @returns {Promise<Conference>}
	 */
	static async start(socket, callType, participants, conferenceName, selectedMediaDevices = null, conversationId = null) {
		if (Conference.abortConference) {
			Conference.setAbortConference(false);
			throw new Error('Conference terminated before start!');
		}
		let endReason;
		// get the data for the local user starting the conference
		const { firstName, lastName, jobTitle, profilePicture } = getUserProfile();
		const name = `${firstName} ${lastName}`;
		const profilePictureUrl = profilePicture?.url;
		const friendlyUrl = `${firstName}-${lastName}`;
		const fromUser = new FromUser(name, jobTitle, profilePictureUrl, friendlyUrl);

		// build the conference data
		const conferenceId = v4();
		const participantId = v4();
		const inputDevices = []; // TODO
		const isChat = !!conversationId;
		const isDirectToHello = !!participants.find(p => p.objectType === ObjectType.HELLO_DEVICE);
		const isMeetingRoom = true;
		const isMultiparty = participants.length > 1 || callType === CallTypes.MONITORING; // TODO
		const isLocked = false; // callType === CallTypes.AUDIO; // TODO
		const isUsingMediaServer = false; // TODO do a request to check if user is eligible to use media server

		// create an instance of conference info
		const conferenceInfo = new ConferenceInfo(
			callType,
			conferenceId,
			conferenceName,
			conversationId,
			participantId,
			participants,
			inputDevices,
			fromUser,
			isChat,
			isDirectToHello,
			isMeetingRoom,
			isMultiparty,
			isLocked,
			isUsingMediaServer
		);

		const refToken = getStorage().getItem('ref_token');
		if (refToken) {
			conferenceInfo.refToken = refToken;
			getStorage().removeItem('ref_token');
		}

		if (selectedMediaDevices) {
			Object.keys(selectedMediaDevices).forEach(key => {
				if (key.includes('output')) {
					return;
				}

				const device = selectedMediaDevices[key];
				if (!device) {
					return;
				}

				const type = key.split('input')[0];
				constraints.set(type, { ...(constraints.get(type) ?? {}), ...{ deviceId: { exact: device.deviceId } } });
			});
		}

		const localTrackFactory = new LocalTrackFactoryWithConstraintsAndSignalingErrors(
			constraints,
			socket,
			conferenceInfo.conferenceId,
			conferenceInfo.participantId
		);

		if (callType !== CallTypes.MONITORING) {
			const types = callTypeToTrackTypes(callType);
			// prepare tracks to display for our local user
			await localTrackFactory.createTracks(types.localTrackTypes);
		}

		const iceServers = await getIceServers();
		if (iceServers.error) {
			throw new Error('Failed to get ice servers!');
		}
		let endedRejection = null;
		let endedPromise = new Promise((_, reject) => {
			endedRejection = reject;
		});

		const onEndedHandler = data => {
			endReason = data.reason;
			localTrackFactory.destroy();
			endedRejection(new FailedInvitationError(StartConferenceFailureMessage[endReason], endReason));
		};
		// This is a very specific case where we need to listen to this event
		// since listeners are being unbound before ack
		socket.on(SocketEvents.Conference.ON_ENDED, onEndedHandler);
		// When participant info fails and ack is not returned, the ended promise
		// will always be executed since conference.start will never return ack
		// hence why we race the two promises
		const response = await Promise.race([endedPromise, socket.emitWithPromise(SocketEvents.Conference.START, conferenceInfo)]);

		if (response.hasActiveConference) {
			localTrackFactory.destroy();
			throw new ConferenceError('There is an active confrence with this user id');
		}

		if (response.exists) {
			const { conference } = response;

			conference.participantId = v4();
			conference.callType = conference.initialCallType;
			conference.conferenceId = conference.id;
			conference.refToken = conferenceInfo.refToken;

			return Conference.join(socket, conference);
		}

		if (response.failureReason === StartConferenceFailureReason.GET_INITIATOR_INFO_FAILED) {
			localTrackFactory.destroy();
			throw new FailedInvitationError(
				StartConferenceFailureMessage[ConferenceEndReason.FAILED_TO_GET_INITIATOR_INFO],
				ConferenceEndReason.FAILED_TO_GET_INITIATOR_INFO
			);
		}

		conferenceInfo.isUsingMediaServer = response.isUsingMediaServer;
		conferenceInfo.participants = response.participants;
		conferenceInfo.conferenceLink = response.conferenceLink;
		conferenceInfo.failedInvitationToParticipants = response.failedInvitationToParticipants;
		socket.off(SocketEvents.Conference.ON_ENDED, onEndedHandler);

		// prepare the conference and socket listeners
		const conference = new Conference(socket, conferenceInfo, localTrackFactory, iceServers, true);

		if (conferenceInfo.failedInvitationToParticipants) {
			conferenceInfo.failedInvitationToParticipants.forEach(p => conference.participants.delete(p.id));
		}

		if (Conference.abortConference) {
			Conference.setAbortConference(false);
			conference.leave();
			throw new Error('Conference terminated before start is completed!');
		}

		if (conference.isUsingMediaServer) {
			await conference.onInitSfuOffer();
		}

		return conference;
	}

	/**
	 * Method used to join in a conference
	 * @static
	 * @param {any} socket
	 * @param {ConferenceInfo} incomingConferenceInfo
	 * @returns {Promise<Conference>}
	 */
	static async join(socket, incomingConferenceInfo) {
		if (!incomingConferenceInfo) {
			throw new Error('Incoming conference info was not found.');
		}

		// create an instance of conference info
		const newConferenceInfo = new ConferenceInfo(
			incomingConferenceInfo.callType,
			incomingConferenceInfo.conferenceId,
			incomingConferenceInfo.conferenceName,
			incomingConferenceInfo.conversationId,
			incomingConferenceInfo.participantId,
			incomingConferenceInfo.participants.filter(p => p.id !== incomingConferenceInfo.participantId),
			[],
			null,
			incomingConferenceInfo.isChat,
			incomingConferenceInfo.isDirectToHello,
			incomingConferenceInfo.isMeetingRoom,
			incomingConferenceInfo.isMultiparty,
			incomingConferenceInfo.isLocked,
			incomingConferenceInfo.isUsingMediaServer,
			incomingConferenceInfo.failedInvitationToParticipants,
			incomingConferenceInfo.conferenceLink,
			incomingConferenceInfo.refToken
		);

		// prepare local track factory
		const localTrackFactory = new LocalTrackFactoryWithConstraintsAndSignalingErrors(
			constraints,
			socket,
			incomingConferenceInfo.conferenceId,
			incomingConferenceInfo.participantId
		);

		const types = callTypeToTrackTypes(incomingConferenceInfo.callType);
		// prepare tracks to display for our local user
		await localTrackFactory.createTracks(incomingConferenceInfo.localTrackTypes || types.localTrackTypes);

		// prepare ice servers
		const iceServers = await getIceServers();
		if (iceServers.error) {
			throw new Error('Failed to get ice servers!');
		}

		// prepare the conference and socket listeners
		const response = await socket.emitWithPromise(SocketEvents.Conference.JOIN, {
			callType: incomingConferenceInfo.callType,
			conferenceId: incomingConferenceInfo.conferenceId,
			participantId: incomingConferenceInfo.participantId,
		});

		// call conference.destroy in case of an error
		if (response.ok === false) {
			switch (response.failureReason) {
				case JoinConferenceFailureReasonEnum.FAILED_TO_GET_PARTICIPANT_INFO:
					throw new FailedInvitationError(JoinConferenceFailureMessage[response.failureReason], ConferenceEndReason.PARTICIPANT_INVITE_FAILED);
				case JoinConferenceFailureReasonEnum.WRONG_PARTICIPANT_STATE:
					throw new WrongParticipantState('');
				case JoinConferenceFailureReasonEnum.UNHANDLED_EXCEPTION:
					throw new ConferenceError('');
				case JoinConferenceFailureReasonEnum.NULL_CONFERENCE:
					throw new NullConference('');
				default:
					throw new ConferenceError('');
			}
		}

		// participant id can be changed from signaling
		newConferenceInfo.participantId = response.participantId;
		newConferenceInfo.isUsingMediaServer = response.isUsingMediaServer;

		// remove participants that left while we where joining.
		newConferenceInfo.participants = newConferenceInfo.participants.filter(p => response.participants.find(presp => presp.id === p.id));

		const conference = new Conference(socket, newConferenceInfo, localTrackFactory, iceServers);

		if (conference.isUsingMediaServer) {
			await conference.onInitSfuOffer();
		}

		// fire onNewParticipant event for participants that joined at the same time
		response.participants.forEach(p => {
			if (!conference.participants.has(p.id)) {
				conference.onNewParticipant({ participant: p });
			}
		});
		return conference;
	}

	/**
	 * Set abortConference variable if conference needs to be aborted while starting
	 * @param {boolean} abort
	 */
	static setAbortConference(abort) {
		Conference.abortConference = abort;
	}
	// #endregion
}
