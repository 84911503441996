/* eslint max-classes-per-file: ["error", 3] */
import { StateConnected, StateConnecting, TrackAdded } from '@solaborate/webrtc/lib/Connection';
import { ParticipantState } from 'constants/enums';
import ParticipantEvent from './participant-events.enum';
import Emitter from './emitter';

export default class Participant extends Emitter {
	/**
	 *
	 * @param {Object} data
	 * @param {string} data.id
	 * @param {string} data.name
	 * @param {Object} data.state
	 * @param {number} data.objectId
	 * @param {number} data.objectType
	 * @param {string} data.picture
	 * @param {boolean} data.isSip
	 * @param {number} data.callType
	 */
	constructor(data, localParticipantId, connection, localTrackController, remoteTrackController) {
		super();
		this.id = data.id;
		this.name = data.name;
		this.state = data.state;
		this.objectId = data.objectId;
		this.objectType = data.objectType;
		this.picture = data.picture || 'https://maxcdn.developershub.info/media/profile-pictures/150/150/duser.jpg'; // backup image
		this.isSip = data.isSip || false;
		this.callType = data.callType;
		this.localParticipantId = localParticipantId;
		this.connection = connection;
		this.localTrackController = localTrackController;
		this.remoteTrackController = remoteTrackController;

		this.state = ParticipantState.CONNECTING.type;

		this.connection.on(this.stateChanged);

		// TODO missing varaibles
		// this.conferenceId = null; // add here, remove from PatientParticipant
		// this.isInvitedByHost = null;
		// this.isNew = null;
		// this.sendIceWithSdp = false;
	}

	/**
	 *
	 * @param {import('@solaborate/webrtc/lib/TrackController').Negotiator} newConnection
	 */
	setConnection = newConnection => {
		const p2pConnection = this.connection;
		this.connection = newConnection;

		this.remoteTrackController.switchNegotiator(newConnection);

		if (!p2pConnection) {
			return;
		}

		p2pConnection.off(this.stateChanged);
		this.connection.on(this.stateChanged);

		// conference sometimes ends without tracks being replaced
		// close p2p if ms connection closes
		const msClose = this.connection.close.bind(this.connection);
		this.connection.close = () => {
			msClose();
			p2pConnection.close();
		};

		p2pConnection.off(this.stateChanged);
		let activeTracks = Array.from(this.remoteTrackController.tracks.values());
		console.log('p2p active tracks:', this, activeTracks);
		if (activeTracks.length === 0) {
			console.log('no active tracks p2p close');
			p2pConnection.close();
		} else {
			const trackListener = ev => {
				if (ev.constructor === TrackAdded) {
					activeTracks = activeTracks.filter(t => t.type !== ev.track.type);
					if (activeTracks.length === 0) {
						console.log('all tracks replaced with sfu, closing p2p connection');
						this.remoteTrackController.off(trackListener);
						setTimeout(() => {
							console.log('p2p close');
							p2pConnection.close();
						}, 5000);
					}
				}
			};
			this.remoteTrackController.on(trackListener);
		}
	};

	/**
	 * @param {import('@solaborate/webrtc/lib/Connection').StateChangeEvent} state
	 */
	stateChanged = state => {
		if (state.constructor === StateConnected) {
			this.state = ParticipantState.CONNECTED.type;
			this.emit(ParticipantEvent.STATE_CHANGED, this.state);
		} else if (state.constructor === StateConnecting) {
			this.state =
				state.event.target.iceConnectionState === 'disconnected' || state.event.target.iceConnectionState === 'failed'
					? ParticipantState.RECONNECTING.type
					: ParticipantState.CONNECTING.type;

			this.emit(ParticipantEvent.STATE_CHANGED, this.state);
		}
	};

	/**
	 * Method used to close connection, stop tracks...
	 */
	destroy() {
		this.connection.close();
	}
}
