import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from 'state/organization/actions';
import { withRouter } from 'react-router-dom';
import { Button, Table, Tabs, DescriptionBox, Modal, Form, Input, Alert } from 'components';
import { getDeviceList, deleteDevice, sendCommand, getLatestAppRelease, forceUpdateDevice, getDeviceSetting, updateDeviceSetting } from 'api/devices';
import { deleteOrgUnit } from 'api/organization';
import { DeviceListLevel, DeviceCommands, UserRoles, SettingTypes } from 'constants/enums';
import EditSectorModal from 'components/Common/EditSectorModal';
import { APP_CONFIG } from 'constants/global-variables';
import { findSectorById, mapSectionsToRender, getParentSector } from 'infrastructure/helpers/commonHelpers';
import { CSVLink } from 'react-csv';
import { getUserRole } from 'infrastructure/auth';
import RebootLogExport from 'components/RebootLogExport';

class Department extends Component {
	state = {
		devices: [],
		isDepartmentModalOpen: false,
		selectedDevice: {},
		selectedOrg: {},
		isDeleteDeviceModalOpen: false,
		isRebootDeviceModalOpen: false,
		rebootReason: '',
		isUpdateDeviceModalOpen: false,
		isDeleteOrgUnitModalOpen: false,
		sectorData: {},
		isEditSectorModalOpen: false,
		parentSectorName: '',
		parentSectorType: '',
		isDeleteDeviceModalLoading: false,
		latestAppRelease: null,
		isDeviceAutoAnswerModalOpen: false,
		isAutoAnswerSettingLoading: false,
		alert: {
			message: false,
			messageAlert: '',
			variantAlert: '',
		},
	};

	async componentDidMount() {
		this.loadDepartment();
	}

	componentDidUpdate = prevProps => {
		const { departmentId } = this.props.match.params;
		if (
			departmentId !== prevProps.match.params.departmentId ||
			(!prevProps.showAssignDeviceModalData.assignCompleted && this.props.showAssignDeviceModalData.assignCompleted)
		) {
			this.loadDepartment();
		}
	};

	exportAsCsv() {
		return this.state.devices.map(({ serialNumber, macAddress, appVersion, firmwareRevision }) => ({
			serialNumber,
			macAddress,
			appVersion,
			osVersion: firmwareRevision,
		}));
	}

	getCurrentDepartment() {
		let hospitals = this.props.treeData.tree;
		let department = findSectorById(hospitals, this.props.match.params.departmentId);
		return {
			sectorName: department ? department.name : '',
			sectorId: department ? department.departmentId : '',
			departmentName: department ? department.name : '',
			hospitalId: department ? department.hospitalId : '',
			departmentId: department ? department.departmentId : '',
			sectorType: department ? department.type : '',
		};
	}

	loadDepartment = async () => {
		const devices = await getDeviceList(DeviceListLevel.DEPARTMENT, this.props.match.params.departmentId);
		const latestAppRelease = await getLatestAppRelease(APP_CONFIG.deviceAppId);
		this.setState({
			devices,
			latestAppRelease,
		});
	};

	toggleEditSectorModal = data => {
		this.setState(
			{
				isEditSectorModalOpen: !this.state.isEditSectorModalOpen,
				roomId: data ? data.roomId : null,
			},
			() => {
				if (this.state.isEditSectorModalOpen) {
					const sector = findSectorById(this.props.treeData.tree, data[data.sectorType + 'Id']);
					if (!sector) {
						this.toggleAlert('message', 'Sector is not found..', 'error');
						return;
					}
					this.props.setCurrentSectorLocation(sector.treeLocation);
					const sectorParent = getParentSector(this.props.treeData.tree, sector);
					this.setState({
						sectorType: data.sectorType,
						sectorName: data.sectorName,
						sectorData: data,
						sectorLocation: sector.treeLocation,
						parentSectorName: sectorParent.name,
						parentSectorType: sectorParent.type,
					});
				}
			}
		);
	};

	toggleAlert = (type, messageAlert, variantAlert) => {
		this.setState(prevState => ({
			...prevState.alert,
			[type]: true,
			messageAlert,
			variantAlert,
		}));

		setTimeout(() => {
			this.setState({
				[type]: false,
			});
		}, 3000);
	};

	initDeleteOrg = data => {
		this.setState({
			selectedOrg: data,
		});
		this.toggleDeleteOrgUnitModal();
	};

	unAssignDevice = async () => {
		const {
			selectedDevice: { deviceId },
		} = this.state;

		if (deviceId) {
			this.setState({
				isDeleteDeviceModalLoading: true,
			});

			await deleteDevice(deviceId);
		}

		this.loadDepartment();
		this.toggleDeleteDeviceModal();
		this.props.getTreeData();

		this.setState({
			isDeleteDeviceModalLoading: false,
		});
	};

	rebootDevice = async () => {
		const {
			selectedDevice: { deviceId },
		} = this.state;

		if (deviceId) {
			await sendCommand(deviceId, DeviceCommands.REBOOT, this.state.rebootReason);
		}

		this.toggleRebootDeviceModal();
	};

	forceUpdateDevice = async () => {
		const {
			selectedDevice: { deviceId, appVersion },
			latestAppRelease: { install_url: appInstallUrl, short_version: latestAppVersion },
		} = this.state;

		if (appVersion && latestAppVersion && appVersion !== latestAppVersion) {
			await forceUpdateDevice({
				deviceId,
				dynamicData: appInstallUrl,
			});
		}

		this.toggleUpdateDeviceModal();
	};

	deleteOrgUnitSubmit = async () => {
		const response = await deleteOrgUnit(this.state.selectedOrg.level, this.state.selectedOrg.id);
		if (!response.error) {
			this.toggleDeleteOrgUnitModal();
			this.props.getTreeData();
			if (this.state.selectedOrg.level === DeviceListLevel.DEPARTMENT) {
				this.props.history.push('/health-system');
				this.props.updateBreadcrumb([]);
			}
		}
	};

	moveToParentSector = currentSectorId => {
		let selectedSector = findSectorById(this.props.treeData.tree, currentSectorId);
		let parentSector = getParentSector(this.props.treeData.tree, selectedSector);
		parentSector.isSelected = true;
		if (selectedSector.breadcrumb && selectedSector.breadcrumb.length) this.props.history.push(selectedSector.breadcrumb[0].link);
	};

	toggleDeleteDeviceModal = (selectedDevice = {}) => {
		this.setState({
			selectedDevice,
			isDeleteDeviceModalOpen: !this.state.isDeleteDeviceModalOpen,
		});
	};

	toggleRebootDeviceModal = (selectedDevice = {}) => {
		this.setState({
			selectedDevice,
			isRebootDeviceModalOpen: !this.state.isRebootDeviceModalOpen,
			rebootReason: '',
		});
	};

	openAutoAnswerSettingModal = async deviceId => {
		this.setState({
			isAutoAnswerSettingLoading: true,
			isDeviceAutoAnswerModalOpen: true,
		});
		let { deviceSettings, error } = await getDeviceSetting(deviceId, SettingTypes.AUTO_ANSWER);
		if (error) {
			this.toggleAlert('message', 'Failed to load Auto answer setting!', 'error');
			return;
		}
		let isAutoAnswerOn = false;
		if (deviceSettings.length) {
			isAutoAnswerOn = deviceSettings[0].value === 'true';
		}
		this.setState({
			selectedDevice: { deviceId, isAutoAnswerOn },
			isAutoAnswerSettingLoading: false,
		});
	};

	closeAutoAnswerSettingModal = () => {
		this.setState({
			selectedDevice: {},
			isDeviceAutoAnswerModalOpen: false,
			isAutoAnswerSettingLoading: false,
		});
	};

	updateDeviceSettings = async () => {
		const { isAutoAnswerOn, deviceId } = this.state.selectedDevice;
		const status = !isAutoAnswerOn;
		const { error } = await updateDeviceSetting(deviceId, status.toString(), SettingTypes.AUTO_ANSWER);
		if (error) {
			this.toggleAlert('message', 'Could not be saved!', 'error');
		} else {
			this.setState(
				prevState => ({
					selectedDevice: {
						...prevState.selectedDevice,
						isAutoAnswerOn: !prevState.selectedDevice.isAutoAnswerOn,
					},
				}),
				() => this.toggleAlert('message', 'Changes Saved', 'dark')
			);
		}
	};

	toggleUpdateDeviceModal = (selectedDevice = {}) => {
		this.setState({
			selectedDevice,
			isUpdateDeviceModalOpen: !this.state.isUpdateDeviceModalOpen,
		});
	};

	toggleDeleteOrgUnitModal = () => {
		this.setState({
			isDeleteOrgUnitModalOpen: !this.state.isDeleteOrgUnitModalOpen,
		});
	};

	toggleDepartmentModal = () => {
		this.setState({
			isDepartmentModalOpen: !this.state.isDepartmentModalOpen,
		});
	};

	displayDevices = () => {
		if (!this.state.devices.length) {
			return [];
		}
		const userRole = getUserRole();
		return this.state.devices.map(({ serialNumber, solHelloDeviceId, firmwareRevision, appVersion, osVersion, macAddress }) => {
			const { short_version: shortVersion, min_os: minOS } = this.state.latestAppRelease;
			const deviceOsVersion = +osVersion;
			const minOsVersion = +minOS;
			return {
				serialNumber,
				macAddress,
				appVersion: (
					<div className='app-version'>
						{appVersion}
						{/* <If condition={shortVersion === appVersion || deviceOsVersion < minOsVersion}>
							<Then>
								<span>{appVersion}</span>
								{deviceOsVersion < minOsVersion && <small>Newer version is available, but device OS is not compatible.</small>}
							</Then>
							<Else>
								<button
									type='button'
									data-tooltip='Click here to force update'
									data-position='top'
									onClick={() => this.toggleUpdateDeviceModal({ deviceId: solHelloDeviceId, serialNumber, appVersion })}>
									{appVersion} <i className='material-icons-outlined'>update</i>
								</button>
								<small>Newer version is available</small>
							</Else>
						</If> */}
					</div>
				),
				firmwareRevision,
				actions: (
					<div className='wrapped'>
						<i
							className='material-icons-outlined boxed-icon'
							data-cy='autoAnswerBtn'
							data-tooltip='Auto answer'
							data-position='top'
							style={{ background: '#D4AB60', userSelect: 'none', WebkitUserSelect: 'none' }}
							onClick={() => this.openAutoAnswerSettingModal(solHelloDeviceId)}>
							video_call
						</i>
						<i
							className='material-icons-outlined boxed-icon'
							data-cy='rebootDeviceBtn'
							id={solHelloDeviceId}
							data-tooltip='Reboot device'
							data-position='top'
							style={{ background: '#718093' }}
							onClick={() => this.toggleRebootDeviceModal({ deviceId: solHelloDeviceId, serialNumber })}>
							refresh
						</i>
						&nbsp;
						<RebootLogExport solHelloDeviceId={solHelloDeviceId} serialNumber={serialNumber} />
						&nbsp;
						{(userRole === UserRoles.ADMIN || userRole === UserRoles.SUPERUSER) && (
							<>
								&nbsp;
								<i
									className='material-icons boxed-icon'
									data-cy='reassignDeviceBtn'
									id={solHelloDeviceId}
									data-tooltip='Reassign Device'
									data-position='top'
									style={{ backgroundColor: 'var(--blue-2)' }}
									onClick={() => this.props.toggleAssignDeviceModal({ deviceId: solHelloDeviceId, show: true })}>
									swap_vert
								</i>
								&nbsp;
								<i
									className='material-icons-outlined boxed-icon'
									data-cy='unassignDeviceBtn'
									id={solHelloDeviceId}
									data-tooltip='Unassign device'
									data-position='top'
									onClick={() => this.toggleDeleteDeviceModal({ deviceId: solHelloDeviceId, serialNumber })}>
									delete
								</i>
							</>
						)}
					</div>
				),
			};
		});
	};

	updateTree = () => {
		this.props.getTreeData();
		this.toggleEditSectorModal();
	};

	getActiveDevices() {
		return this.state.devices.filter(device => device.isOnline);
	}

	render() {
		const department = this.getCurrentDepartment();
		const sections = mapSectionsToRender(this.props.treeData.tree, department, this.toggleEditSectorModal, this.initDeleteOrg);
		const userRole = getUserRole();
		return (
			<div className='organization'>
				<h3>
					<span>{department.departmentName}</span>
					{[UserRoles.ADMIN, UserRoles.SUPERUSER].includes(userRole) && (
						<>
							<Button text='Edit Department Details' onClick={() => this.toggleEditSectorModal(department)} />

							<Button
								buttonSelector='deleteDepartmentBtn'
								text='Delete Department'
								variant='red'
								onClick={() => this.initDeleteOrg({ level: DeviceListLevel.DEPARTMENT, id: department.departmentId, name: department.departmentName })}
							/>
						</>
					)}
					{this.state.devices.length > 0 && (
						<CSVLink
							data-cy='exportAsCSVBtn'
							className='button white'
							data={this.exportAsCsv()}
							filename={`${department.departmentName}-${(+new Date()).toString()}.csv`}>
							Export as CSV
						</CSVLink>
					)}
				</h3>
				<DescriptionBox
					data={[
						{ title: 'Rooms in this department', description: sections.rooms.length },
						{ title: 'Enrolled devices', description: this.state.devices ? this.state.devices.length : 0 },
					]}
				/>
				<Tabs
					links={[{ link: 'Devices' }, { link: 'Rooms', active: true }]}
					components={[
						<Table
							headers={[{ title: 'Serial Number' }, { title: 'MAC Address' }, { title: 'App Version' }, { title: 'OS Version' }, { title: '' }]}
							rows={this.displayDevices()}
							isEditable={false}
						/>,
						<Table headers={[{ title: 'Room Names' }]} rows={sections.rooms} isEditable={false} />,
					]}
				/>
				<Modal
					modalSelector='unassignDeviceModal'
					display={this.state.isDeleteDeviceModalOpen}
					isLoading={this.state.isDeleteDeviceModalLoading}
					position='center'
					submitButtonText='Unassign'
					onModalSubmit={this.unAssignDevice}
					onModalClose={this.toggleDeleteDeviceModal}>
					<Form title='Unassign device' onSubmit={event => event.preventDefault()}>
						<p>Are you sure you want to unassign device {this.state.selectedDevice.serialNumber}? Neither you or patient won't be able to make any calls.</p>
					</Form>
				</Modal>

				<Modal
					modalSelector='rebootDeviceModal'
					display={this.state.isRebootDeviceModalOpen}
					position='center'
					submitButtonText='Reboot'
					onModalSubmit={this.rebootDevice}
					onModalClose={this.toggleRebootDeviceModal}>
					<Form title='Reboot device' onSubmit={event => event.preventDefault()}>
						<p>Why do you want to reboot device {this.state.selectedDevice.serialNumber}?</p>
						<Input
							type='text'
							value={this.state.rebootReason}
							validationOptions={{}}
							placeholder='Describe the reboot reason. . .'
							onChange={e => this.setState({ rebootReason: e.target.value })}
							inputSelector='rebootReasonInput'
						/>
					</Form>
				</Modal>

				<Modal
					display={this.state.isUpdateDeviceModalOpen}
					position='center'
					submitButtonText='Update'
					onModalSubmit={this.forceUpdateDevice}
					onModalClose={this.toggleUpdateDeviceModal}>
					<Form title='Force update device' onSubmit={event => event.preventDefault()}>
						<p>Are you sure you want to force update device {this.state.selectedDevice.serialNumber}?</p>
					</Form>
				</Modal>

				<Modal
					modalSelector='deleteSectorModal'
					display={this.state.isDeleteOrgUnitModalOpen}
					position='center'
					submitButtonText='Delete'
					onModalSubmit={this.deleteOrgUnitSubmit}
					onModalClose={this.toggleDeleteOrgUnitModal}>
					<Form title='Warning' onSubmit={event => event.preventDefault()}>
						<p>
							Are you sure you want to delete {this.state.selectedOrg.name}? Any devices related to it will be deleted and neither Virtual Care Providers or
							patients won't be able to make any calls.
						</p>
					</Form>
				</Modal>
				{this.state.isEditSectorModalOpen && (
					<EditSectorModal
						onToggleRoomAlert={this.toggleAlert}
						onEditSector={this.updateTree}
						onModalClose={this.toggleEditSectorModal}
						sectorLocation={this.state.sectorLocation}
						isEditSectorModalOpen={this.state.isEditSectorModalOpen}
						sectorData={this.state.sectorData}
						parentSectorName={this.state.parentSectorName}
						parentSectorType={this.state.parentSectorType}
						roomId={this.state.roomId}
					/>
				)}
				<Modal
					modalSelector='toggleDeviceAutoAnswerSettingModal'
					display={this.state.isDeviceAutoAnswerModalOpen}
					position='right'
					hideCloseButton={true}
					submitButtonText=''
					isLoading={this.state.isAutoAnswerSettingLoading}
					onModalClose={this.closeAutoAnswerSettingModal}>
					<Form title='Enable Auto-answer' onSubmit={event => event.preventDefault()}>
						<div className='auto-answer-container'>
							<p>Auto-answer</p>
							{this.state.selectedDevice?.isAutoAnswerOn ? (
								<i className='material-icons' data-cy='autoAnswerOn' onClick={this.updateDeviceSettings} data-tooltip='On' data-position='center'>
									toggle_on
								</i>
							) : (
								<i className='material-icons-outlined' data-cy='autoAnswerOff' onClick={this.updateDeviceSettings} data-tooltip='Off' data-position='center'>
									toggle_off
								</i>
							)}
						</div>
						<p>If you enable this option, as soon as a provider makes a call, the device will ring for a few seconds and it will auto answer.</p>
					</Form>
				</Modal>
				<Alert display={this.state.message} fixed={true} hideCloseButton={true} message={this.state.messageAlert} variant={this.state.variantAlert} />
			</div>
		);
	}
}

export default connect(
	state => state.organization,
	dispatch => bindActionCreators(actionCreators, dispatch)
)(withRouter(Department));
