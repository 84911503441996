export const CallLogsActionTypes = {
	FETCHING_CALL_LOGS: 'FETCHING_CALL_LOGS',
	FETCHING_CALL_LOGS_SUCCESS: 'FETCHING_CALL_LOGS_SUCCESS',
	FETCHING_CALL_LOGS_FAILURE: 'FETCHING_CALL_LOGS_FAILURE',
};

export const OverviewActionTypes = {
	toggleUsersModal: 'TOGGLE_USERS_MODAL',
	toggleDeviceModal: 'TOGGLE_DEVICE_MODAL',
	toggleSwitchHealthSystemModal: 'TOGGLE_HEALTH_SYTEM_MODAL',
	toggleAssignDeviceModal: 'TOGGLE_ASSIGN_DEVICE_MODAL',
	toggleLeftNavigation: 'TOGGLE_LEFT_NAVIGATION',
	addNewRoom: 'ADD_NEW_ROOM',
	updateBreadcrumb: 'UPDATE_BREADCRUMB',
	setAllHealthSystems: 'SET_ALL_HEALTH_SYSTEMS',
	setHealthSystem: 'SET_HEALTH_SYSTEM',
	setTreeData: 'SET_TREE_DATA',
	createNewDepartment: 'CREATE_NEW_DEPARTMENT',
	createNewHospital: 'CREATE_NEW_HOSPITAL',
	setHospitalTree: 'SET_HOSPITAL_TREE',
	setCurrentHealthSystemInfo: 'SET_CURRENT_HEALTH_SYTEM_INFO',
	setCurrentHospitalId: 'SET_CURRENT_HOSPITAL_ID',
	setCurrentDepartmentId: 'SET_CURRENT_DEPARTMENT_ID',
	setCurrentHSLogo: 'SET_CURRENT_HS_LOGO',
	setUserInfo: 'SET_USER_INFO',
	setCurrentSectorLocation: 'SET_CURRENT_SECTOR_LOCATION',
	setDarkTheme: 'TOGGLE_DARK_THEME',
	toggleTreeVisibility: 'TOGGLE_TREE_VISIBILITY',
};

export const UserPresenceActionTypes = {
	FETCHING_USER_PRESENCE: 'FETCHING_USER_PRESENCE',
	FETCH_USER_PRESENCE_SUCCESS: 'FETCH_USER_PRESENCE_SUCCESS',
	FETCH_USER_PRESENCE_FAILURE: 'FETCH_USER_PRESENCE_FAILURE',

	UPDATING_USER_PRESENCE: 'UPDATING_USER_PRESENCE',
	UPDATE_USER_PRESENCE_SUCCESS: 'UPDATE_USER_PRESENCE_SUCCESS',
	UPDATE_USER_PRESENCE_FAILURE: 'UPDATE_USER_PRESENCE_FAILURE',
};

export const NotificationsCounterActionTypes = {
	FETCHING_NOTIFICATIONS_COUNTER: 'FETCHING_NOTIFICATIONS_COUNTER',
	FETCH_NOTIFICATIONS_COUNTER_SUCCESS: 'FETCH_NOTIFICATIONS_COUNTER_SUCCESS',
	FETCH_NOTIFICATIONS_COUNTER_FAILURE: 'FETCH_NOTIFICATIONS_COUNTER_FAILURE',
};

export const PatientAiSettingTypes = {
	SET_PATIENT_AI_SETTINGS: 'SET_PATIENT_AI_SETTINGS',
};

export const DeviceActionTypes = {
	SET_DEVICE_BUSY: 'SET_DEVICES_BUSY',
	REMOVE_DEVICE_BUSY: 'REMOVE_DEVICES_BUSY',
	SET_BULK_DEVICES_BUSY: 'SET_BULK_DEVICES_BUSY',
	REMOVE_BULK_DEVICES_BUSY: 'REMOVE_BULK_DEVICES_BUSY',
};
