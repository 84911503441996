/* eslint max-classes-per-file: ["error", 5] */
export class ConferenceError {
	/**
	 *
	 * @param {string} message
	 */
	constructor(message) {
		this.message = message;
	}
}

export class HasActiveConferenceError extends ConferenceError {}
export class WrongParticipantState extends ConferenceError {}
export class NullConference extends ConferenceError {}
export class FailedInvitationError extends ConferenceError {
	/**
	 *
	 * @param {string} message
	 * @param {number} reason
	 */
	constructor(message, reason) {
		super(message);
		if (reason) {
			this.reason = reason;
		}
	}
}
