import React, { Component } from 'react';
import { v4 } from 'uuid';
import { Modal, Form, Badge, Input, Loader } from 'components';
import { searchUsersToInvite } from 'api/users';
import { ObjectType, UserRoles } from 'constants/enums';
import ConferenceParticipant from 'calls/base/ConferenceParticipant';
import { isEmail } from 'calls/helpers/conference-utils';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { amwellIconLink } from 'constants/global-variables';
import { dialOut, getSipInfo } from 'api/sipProvider';
import { getUserRole } from 'infrastructure/auth';

export default class InviteParticipantsModal extends Component {
	state = {
		searchBox: '',
		isSearchLoading: false,
		invitedUsers: [],
		searchedUsers: [],
		emails: [],
		phoneNumbers: [],
		emailInput: '',
		showCopiedMessage: false,
		phone: '',
		dialOutPhone: '',
		country: '',
		sipErrorMessage: '',
		dialOutTestNumber: '',
		sipError: '',
		isDialOutLoading: false,
		isDialOutDisabled: false,
	};

	keyCodes = {
		ENTER: 13,
	};

	dialOutDisabledTimer = null;

	async componentDidMount() {
		if (this.props.localParticipant.isInitiator && getUserRole() !== UserRoles.GUEST) {
			const sipInfo = await getSipInfo(this.props.conferenceId);
			if (sipInfo.error) {
				this.setState({ sipError: 'Phone dialing is not available at the moment.' });
			}
		}
	}

	componentWillUnmount() {
		if (this.dialOutDisabledTimer) {
			clearTimeout(this.dialOutDisabledTimer);
		}
	}

	onSearchUsersToInvite = async e => {
		const searchBox = e.target.value;
		this.setState({
			isSearchLoading: true,
			searchBox,
		});
		if (searchBox) {
			await this.setSearchedUsers(searchBox);
		}
		this.setState({
			isSearchLoading: false,
		});
	};

	setSearchedUsers = async pattern => {
		const response = await searchUsersToInvite(pattern, false);
		this.setState({
			searchedUsers: response.results.filter(x => !this.state.invitedUsers.some(u => u.id === x.id)),
		});
	};

	onSearchResultItemClick = user => {
		const invitedUsers = [...this.state.invitedUsers];
		invitedUsers.push(user);
		this.setState({
			invitedUsers,
			searchBox: '',
		});

		this.setSearchedUsers(this.state.searchBox);
	};

	removeInvitedUser = id => {
		const invitedUsers = [...this.state.invitedUsers];
		invitedUsers.splice(
			invitedUsers.findIndex(x => x.id === id),
			1
		);
		this.setState({ invitedUsers });
		this.setSearchedUsers(this.state.searchBox);
	};

	inviteParticipants = () => {
		if (this.state.invitedUsers.length + this.state.emails.length > 0 || this.state.phoneNumbers.length > 0 || this.state.phone || this.state.emailInput) {
			const { invitedUsers, emails, phoneNumbers } = this.state;
			const participants = invitedUsers.map(user => {
				return new ConferenceParticipant(v4(), user.userId, ObjectType.USER);
			});

			if (this.state.emailInput) {
				emails.push(this.state.emailInput);
			}

			if (this.state.phone) {
				phoneNumbers.push(`+${this.state.phone}`);
			}

			if (emails.length || phoneNumbers) {
				this.props.inviteParticipants(participants, emails, phoneNumbers, this.props.siteName);
			}

			this.setState({
				searchBox: '',
				isSearchLoading: false,
				invitedUsers: [],
				searchedUsers: [],
				emails: [],
				phoneNumbers: [],
				emailInput: '',
				phone: '',
			});
			this.props.onModalClose();
		}
	};

	inviteDialout = async () => {
		this.setState({ sipErrorMessage: '', isDialOutLoading: true, isDialOutDisabled: true });
		const response = await dialOut(this.state.dialOutTestNumber ? this.state.dialOutTestNumber : `+${this.state.dialOutPhone}`, this.props.conferenceId);
		if (response.error) {
			this.setState({ sipErrorMessage: 'Phone number could not be reached.' });
		} else {
			this.dialOutDisabledTimer = setTimeout(() => {
				this.setState({ isDialOutDisabled: false });
			}, 10000);
			this.setState({ isDialOutLoading: false });
		}
	};

	removeEmail = index => {
		const allEmails = this.state.emails;
		allEmails.splice(index, 1);
		this.setState({
			emails: allEmails,
		});
	};

	removePhone = index => {
		const { phoneNumbers } = this.state;
		phoneNumbers.splice(index, 1);
		this.setState({
			phoneNumbers,
			phone: '',
			dialOutPhone: '',
		});
	};

	addEmail = evt => {
		const { value } = evt.target;
		this.setState({
			emailInput: value,
		});
		if (evt.key === 'Enter' && value !== '') {
			const allEmails = this.state.emails;
			if (!isEmail(value)) {
				// TODO display a modal or an error message
				console.error('Enter a valid email');
				return;
			}
			allEmails.push(value);
			this.setState({
				emails: allEmails,
				emailInput: '',
			});
		}
	};

	addDialOutTestNumber = evt => {
		const { value } = evt.target;
		this.setState({ dialOutTestNumber: value });
	};

	addDialOutNumber = phone => {
		this.setState({ dialOutPhone: phone });
	};

	showAndHideCopiedMessage = () => {
		this.setState({
			showCopiedMessage: true,
		});
		setTimeout(() => {
			this.setState({
				showCopiedMessage: false,
			});
		}, 3000);
	};

	copyLink = () => {
		let textArea = document.createElement('textarea');
		textArea.value = this.props.conferenceLink;
		document.body.appendChild(textArea);
		textArea.select();
		document.execCommand('Copy');
		textArea.remove();
		this.showAndHideCopiedMessage();
	};

	onPhoneKeyDown = e => {
		if (e.keyCode === this.keyCodes.ENTER && this.state.phone) {
			const { phoneNumbers } = this.state;
			phoneNumbers.push(`+${this.state.phone}`);
			this.setState({
				phoneNumbers,
				phone: '',
			});
		}
	};

	render() {
		return (
			<Modal
				display={true}
				className='invite-people'
				position='left'
				primaryButtonLabel='Invite people'
				onModalSubmit={this.inviteParticipants}
				onModalClose={this.props.onModalClose}
				submitButtonText='Send Invite'>
				<div>
					<h3>Participants</h3>
					{this.props.children}
					<Form title='Add one or more people via Email, SMS, Link' onSubmit={event => event.preventDefault()} className='call-invite-people-form'>
						{this.state.invitedUsers.length > 0 &&
							this.state.invitedUsers.map(x => (
								<div className='invited-member' key={x.id}>
									<Badge text={`${x.firstName} ${x.lastName}`} variant='green' />
									<button type='button' onClick={() => this.removeInvitedUser(x.id)}>
										<i className='material-icons'>close</i>
									</button>
								</div>
							))}
						{this.state.emails.length > 0 &&
							this.state.emails.map((email, index) => (
								<div className='invited-member' key={email}>
									<Badge text={email} variant='green' />
									<button type='button' onClick={() => this.removeEmail(index)}>
										<i className='material-icons'>close</i>
									</button>
								</div>
							))}
						<Input
							placeholder='Add email'
							onChange={this.addEmail}
							onKeyUp={this.addEmail}
							value={this.state.emailInput}
							type='search'
							error='Press enter to add email.'
							validationOptions={{}}
							className='invite-add-email'
						/>
						<div className='input'>
							{this.state.phoneNumbers.length > 0 &&
								this.state.phoneNumbers.map((phone, index) => (
									<div className='invited-member' key={phone}>
										<Badge text={phone} variant='green' />
										<button type='button' onClick={() => this.removePhone(index)}>
											<i className='material-icons'>close</i>
										</button>
									</div>
								))}
							<PhoneInput
								onlyCountries={['us', 'xk']}
								placeholder=''
								country='us'
								value={this.state.phone}
								onChange={phone => this.setState({ phone })}
								onKeyDown={this.onPhoneKeyDown}
							/>
							<small>Press enter to add phone number.</small>
						</div>
						<div className='invite-copy'>
							{/* <Input label='Link Generated' value={this.props.conferenceLink} type='text' readOnly={true} error='' validationOptions={{}} /> */}
							<button type='button' onClick={this.copyLink}>
								<img src={`${amwellIconLink}copy-link.svg`} alt='icon' />
								{this.state.showCopiedMessage ? <span>Copied</span> : <span>Copy Invite Link</span>}
							</button>
						</div>
						{/* <button type='button'>
							<i className='material-icons'>dialpad</i>Dial Out
						</button> */}
					</Form>
				</div>
				{this.props.localParticipant.isInitiator && getUserRole() !== UserRoles.GUEST && (
					<div className={this.state.sipError || this.state.sipErrorMessage ? 'padding-t-0' : ''}>
						{!this.state.sipError && (
							<>
								<h3>Call Participants via phone number</h3>
								<div className='input'>
									<PhoneInput
										onlyCountries={['us', 'xk']}
										inputStyle={{ height: '50px' }}
										dropdownStyle={{ zIndex: 10 }}
										country='us'
										value={this.state.dialOutPhone}
										onChange={this.addDialOutNumber}
										onKeyDown={this.onPhoneKeyDown}
									/>
									<div style={{ paddingTop: 8 }}>
										<Input
											placeholder='SIP Dial Out'
											onChange={this.addDialOutTestNumber}
											value={this.state.dialOutTestNumber}
											type='search'
											error='Press enter to add dial out number.'
											validationOptions={{}}
											className='invite-add-email'
										/>
									</div>
									<div className='invite-copy'>
										{this.state.isDialOutLoading && <Loader />}
										{!this.state.isDialOutLoading && (
											<button className='dial-out-button' type='button' disabled={this.state.isDialOutDisabled} onClick={() => this.inviteDialout()}>
												<span>Call participant</span>
											</button>
										)}
									</div>
								</div>
							</>
						)}
						{this.state.sipError && <p className='sip-error-message'>{this.state.sipError}</p>}
						{this.state.sipErrorMessage && <p className='sip-error-message'>{this.state.sipErrorMessage}</p>}
					</div>
				)}
			</Modal>
		);
	}
}
