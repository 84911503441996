import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from 'state/organization/actions';
import { withRouter } from 'react-router-dom';
import { Button, Table, Tabs, DescriptionBox, Modal, Form, Input, Alert } from 'components';
import { deleteOrgUnit, uploadLogo, saveLogo, getLogoUrl } from 'api/organization';
import { DeviceListLevel, SectorTypes, DeviceCommands, UserRoles, SettingTypes, DefaultCameraTypes } from 'constants/enums';
import EditSectorModal from 'components/Common/EditSectorModal';
import { getDeviceList, deleteDevice, sendCommand, forceUpdateDevice, getLatestAppRelease, getDeviceSetting, updateDeviceSetting } from 'api/devices';
import { mapSectionsToRender, findSectorById, getParentSector, getCurrentHealthSystemInfo } from 'infrastructure/helpers/commonHelpers';
import { amwellIconLink, APP_CONFIG } from 'constants/global-variables';
import { CSVLink } from 'react-csv';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { getUserRole } from 'infrastructure/auth';
import RebootLogExport from 'components/RebootLogExport';
import DefaultCameraModal from 'components/DefaultCameraModal';

class Hospital extends Component {
	state = {
		devices: [],
		isHospitalModalOpen: false,
		selectedDevice: {},
		selectedOrg: {},
		isDeleteDeviceModalOpen: false,
		isRebootDeviceModalOpen: false,
		isUpdateDeviceModalOpen: false,
		isDeleteOrgUnitModalOpen: false,
		sectorData: {},
		rebootReason: '',
		parentSectorName: '',
		parentSectorType: '',

		isDeleteDeviceModalLoading: false,
		isSettingsModalLoading: false,

		latestAppRelease: null,
		alert: {
			message: false,
			messageAlert: '',
			variantAlert: '',
		},
		isDeviceAutoAnswerModalOpen: false,
		isSelectDefaultCameraModalOpen: false,
		isUploadLogoModalOpen: false,
		src: '',
		crop: {
			width: 200,
			height: 200,
		},
		hospitalLogo: '',
		isHospitalLogoModalLoading: false,
		roomId: null,
	};

	async componentDidMount() {
		this.loadHospital();
	}

	componentDidUpdate = prevProps => {
		const { hospitalId } = this.props.match.params;
		if (
			hospitalId !== prevProps.match.params.hospitalId ||
			(!prevProps.showAssignDeviceModalData.assignCompleted && this.props.showAssignDeviceModalData.assignCompleted)
		) {
			this.loadHospital();
		}
	};

	exportAsCsv() {
		return this.state.devices.map(({ serialNumber, macAddress, appVersion, firmwareRevision }) => ({
			serialNumber,
			macAddress,
			appVersion,
			osVersion: firmwareRevision,
		}));
	}

	getCurrentHospital() {
		let hospitals = this.props.treeData.tree;
		let hospital = hospitals.find(item => item.hospitalId === this.props.match.params.hospitalId);
		return {
			sectorName: hospital ? hospital.name : '',
			sectorId: hospital ? hospital.hospitalId : '',
			hospitalName: hospital ? hospital.name : '',
			hospitalId: hospital ? hospital.hospitalId : '',
			sectorType: hospital ? hospital.type : '',
		};
	}

	loadHospital = async () => {
		const devices = await getDeviceList(DeviceListLevel.HOSPITAL, this.props.match.params.hospitalId);
		const latestAppRelease = await getLatestAppRelease(APP_CONFIG.deviceAppId);
		this.setState({
			devices,
			latestAppRelease,
		});
	};

	displayDevices = () => {
		if (!this.state.devices.length) {
			return [];
		}
		const userRole = getUserRole();
		return this.state.devices.map(({ serialNumber, solHelloDeviceId, firmwareRevision, appVersion, osVersion, macAddress }) => {
			const { short_version: shortVersion, min_os: minOS } = this.state.latestAppRelease;
			const deviceOsVersion = +osVersion;
			const minOsVersion = +minOS;
			return {
				serialNumber,
				macAddress,
				appVersion: <div className='app-version'>{appVersion}</div>,
				firmwareRevision,
				actions: (
					<div className='wrapped'>
						{' '}
						<i
							className='material-icons-outlined boxed-icon'
							id={solHelloDeviceId}
							data-tooltip='Default Camera'
							data-position='top'
							style={{ background: '#30BB7D', userSelect: 'none', WebkitUserSelect: 'none' }}
							onClick={() => this.toggleSelectDefaultCamera(solHelloDeviceId)}>
							video_call
						</i>
						<i
							className='material-icons-outlined boxed-icon'
							data-cy='autoAnswerBtn'
							data-tooltip='Auto answer'
							data-position='top'
							style={{ background: '#D4AB60', userSelect: 'none', WebkitUserSelect: 'none' }}
							onClick={() => this.openAutoAnswerSettingModal(solHelloDeviceId)}>
							<img src={`${amwellIconLink}auto-answer.svg`} alt='auto answer icon' />
						</i>
						&nbsp;
						<i
							className='material-icons-outlined boxed-icon'
							data-cy='rebootDeviceBtn'
							id={solHelloDeviceId}
							data-tooltip='Reboot device'
							data-position='top'
							style={{ background: '#718093' }}
							onClick={() => this.toggleRebootDeviceModal({ deviceId: solHelloDeviceId, serialNumber })}>
							refresh
						</i>
						&nbsp;
						<RebootLogExport solHelloDeviceId={solHelloDeviceId} serialNumber={serialNumber} />
						&nbsp;
						{(userRole === UserRoles.ADMIN || userRole === UserRoles.SUPERUSER) && (
							<>
								<i
									className='material-icons boxed-icon'
									data-cy='reassignDeviceBtn'
									id={solHelloDeviceId}
									data-tooltip='Reassign Device'
									data-position='top'
									style={{ backgroundColor: 'var(--blue-2)' }}
									onClick={() => this.props.toggleAssignDeviceModal({ deviceId: solHelloDeviceId, show: true })}>
									swap_vert
								</i>
								&nbsp;
								<i
									className='material-icons-outlined boxed-icon'
									data-cy='unassignDeviceBtn'
									id={solHelloDeviceId}
									data-tooltip='Unassign device'
									data-position='top'
									onClick={() => this.toggleDeleteDeviceModal({ deviceId: solHelloDeviceId, serialNumber })}>
									delete
								</i>
							</>
						)}
					</div>
				),
			};
		});
	};

	initDeleteOrg = data => {
		this.setState({
			selectedOrg: data,
		});
		this.toggleDeleteOrgUnitModal();
	};

	unAssignDevice = async () => {
		const {
			selectedDevice: { deviceId },
		} = this.state;

		if (deviceId) {
			this.setState({
				isDeleteDeviceModalLoading: true,
			});

			await deleteDevice(deviceId);
		}

		this.loadHospital();
		this.toggleDeleteDeviceModal();
		this.props.getTreeData();

		this.setState({
			isDeleteDeviceModalLoading: false,
		});
	};

	rebootDevice = async () => {
		const {
			selectedDevice: { deviceId },
		} = this.state;

		if (deviceId) {
			await sendCommand(deviceId, DeviceCommands.REBOOT, this.state.rebootReason);
		}

		this.toggleRebootDeviceModal();
	};

	forceUpdateDevice = async () => {
		const {
			selectedDevice: { deviceId, appVersion },
			latestAppRelease: { install_url: appInstallUrl, short_version: latestAppVersion },
		} = this.state;

		if (appVersion && latestAppVersion && appVersion !== latestAppVersion) {
			await forceUpdateDevice({
				deviceId,
				dynamicData: appInstallUrl,
			});
		}

		this.toggleUpdateDeviceModal();
	};

	deleteOrgUnitSubmit = async () => {
		const response = await deleteOrgUnit(this.state.selectedOrg.level, this.state.selectedOrg.id);
		if (!response.error) {
			this.toggleDeleteOrgUnitModal();
			this.props.getTreeData();
			if (this.state.selectedOrg.level === DeviceListLevel.HOSPITAL) {
				this.props.history.push('/health-system');
				this.props.updateBreadcrumb([]);
			}
		}
	};

	toggleAlert = (type, messageAlert, variantAlert) => {
		this.setState(prevState => ({
			...prevState.alert,
			[type]: true,
			messageAlert,
			variantAlert,
		}));

		setTimeout(() => {
			this.setState({
				[type]: false,
			});
		}, 3000);
	};

	toggleHospitalModal = () => {
		this.setState({
			isHospitalModalOpen: !this.state.isHospitalModalOpen,
		});
	};

	toggleEditSectorModal = data => {
		this.setState(
			{
				isEditSectorModalOpen: !this.state.isEditSectorModalOpen,
				roomId: data ? data.roomId : null,
			},
			async () => {
				if (this.state.isEditSectorModalOpen) {
					const sector = findSectorById(this.props.treeData.tree, data[`${data.sectorType}Id`]);
					if (!sector) {
						this.toggleAlert('message', 'Sector is not found..', 'error');
						return;
					}
					this.props.setCurrentSectorLocation(sector.treeLocation);
					const currentHealthSystem = this.getCurrentHealthSystem(this.props.allHealthSystems, getCurrentHealthSystemInfo().currentHealthSystemId);
					const sectorParent =
						data.sectorType === SectorTypes.HOSPITAL
							? { type: SectorTypes.HEALTHSYSTEM, name: currentHealthSystem.name }
							: getParentSector(this.props.treeData.tree, sector);
					const hospitalLogo = await getLogoUrl(data.hospitalId);
					this.setState({
						sectorData: data,
						parentSectorName: sectorParent.name,
						parentSectorType: sectorParent.type,
						hospitalLogo,
					});
				} else {
					this.setState({ fileError: false });
				}
			}
		);
	};

	getCurrentHealthSystem = (healthSystems, currentHealthSystemId) => {
		return healthSystems.find(({ id }) => id === currentHealthSystemId);
	};

	toggleDeleteDeviceModal = (selectedDevice = {}) => {
		this.setState({
			selectedDevice,
			isDeleteDeviceModalOpen: !this.state.isDeleteDeviceModalOpen,
		});
	};

	toggleRebootDeviceModal = (selectedDevice = {}) => {
		this.setState({
			selectedDevice,
			isRebootDeviceModalOpen: !this.state.isRebootDeviceModalOpen,
			rebootReason: '',
		});
	};

	toggleUpdateDeviceModal = (selectedDevice = {}) => {
		this.setState({
			selectedDevice,
			isUpdateDeviceModalOpen: !this.state.isUpdateDeviceModalOpen,
		});
	};

	toggleDeleteOrgUnitModal = () => {
		this.setState({
			isDeleteOrgUnitModalOpen: !this.state.isDeleteOrgUnitModalOpen,
		});
	};

	setInputValues = event => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	updateTree = async () => {
		this.props.getTreeData();
		this.toggleEditSectorModal();
	};

	onSelectFile = ({ target }) => {
		const input = target;
		const [file] = input.files;
		if (!file) {
			return;
		}

		if (file.size > 600000 || !file.type.includes('png')) {
			this.setState({ fileError: true });
			input.value = '';
			return;
		}

		const reader = new FileReader();
		reader.addEventListener('load', () => this.setState({ src: reader.result }));
		reader.readAsDataURL(file);

		this.setState({
			isUploadLogoModalOpen: true,
			isEditSectorModalOpen: false,
			file,
		});
	};

	onImageLoaded = image => {
		this.imageRef = image;
	};

	onCropChange = crop => {
		this.setState({ crop });
	};

	makeClientCrop = async () => {
		this.setState({
			isHospitalLogoModalLoading: true,
			crop: {
				width: 200,
				height: 200,
			},
		});
		if (this.imageRef && this.state.crop.width && this.state.crop.height) {
			const imageData = await this.uploadLogo();
			if (imageData) {
				const logoData = {
					picture: imageData.originalUrl,
				};
				saveLogo(this.state.sectorData.hospitalId, logoData);
			}
		}
	};

	uploadLogo = async () => {
		const { data, error } = await uploadLogo(this.state.file, this.getImageCrop());
		let errorStateParameters = {};

		if (error) {
			errorStateParameters = {
				fileError: error.request.status === 400,
				isEditSectorModalOpen: true,
			};
		}

		this.setState({
			isHospitalLogoModalLoading: false,
			isUploadLogoModalOpen: false,
			src: '',
			...errorStateParameters,
		});

		return data;
	};

	getImageCrop = () => {
		const { crop } = this.state;

		const image = this.imageRef;
		const xScale = image.naturalWidth > image.width ? image.naturalWidth / image.width : 1;
		const yScale = image.naturalHeight > image.height ? image.naturalHeight / image.height : 1;

		return {
			width: crop.width * xScale,
			height: crop.height * yScale,
			x: crop.x * xScale,
			y: crop.y * yScale,
		};
	};

	toggleHospitalLogoCrop = () => {
		this.setState({
			isUploadLogoModalOpen: !this.state.isUploadLogoModalOpen,
			crop: {
				width: 200,
				height: 200,
			},
		});
	};

	closeAutoAnswerSettingModal = () => {
		this.setState({
			selectedDevice: {},
			isDeviceAutoAnswerModalOpen: false,
			isAutoAnswerSettingLoading: false,
		});
	};

	openAutoAnswerSettingModal = async deviceId => {
		this.setState({
			isAutoAnswerSettingLoading: true,
			isDeviceAutoAnswerModalOpen: true,
		});
		let { deviceSettings, error } = await getDeviceSetting(deviceId, SettingTypes.AUTO_ANSWER);
		if (error) {
			this.toggleAlert('message', 'Failed to load Auto answer setting!', 'error');
			return;
		}
		let isAutoAnswerOn = false;
		if (deviceSettings.length) {
			isAutoAnswerOn = deviceSettings[0].value === 'true';
		}
		this.setState({
			selectedDevice: { deviceId, isAutoAnswerOn },
			isAutoAnswerSettingLoading: false,
		});
	};

	updateDeviceSettings = async type => {
		const { isAutoAnswerOn, deviceId, defaultCamType } = this.state.selectedDevice;

		let value;
		if (type === SettingTypes.AUTO_ANSWER) {
			value = !isAutoAnswerOn;
		} else {
			value = defaultCamType;
			this.setState({
				isSettingsModalLoading: true,
			});
		}

		const { error } = await updateDeviceSetting(deviceId, value.toString(), type);
		if (error) {
			this.toggleAlert('message', 'Could not be saved!', 'error');
		} else {
			this.setState(
				prevState => ({
					selectedDevice: {
						...prevState.selectedDevice,
						...(type === SettingTypes.AUTO_ANSWER && { isAutoAnswerOn: !prevState.selectedDevice.isAutoAnswerOn }),
					},
				}),
				() => this.toggleAlert('message', 'Changes Saved', 'dark')
			);
		}
		this.setState({
			isSettingsModalLoading: false,
			...(type === SettingTypes.CAMERA_TYPE && { isSelectDefaultCameraModalOpen: false }),
		});
	};

	getDescriptionBoxData = sections => {
		return [
			{ title: 'Rooms in this hospital', description: sections.rooms.length },
			{ title: 'Enrolled Devices', description: this.state.devices ? this.state.devices.length : 0 },
		];
	};

	getLinks = () => {
		return [{ link: 'Devices' }, { link: 'Rooms', active: true }];
	};

	getComponents = sections => {
		return [
			<Table
				headers={[{ title: 'Serial Number' }, { title: 'MAC Address' }, { title: 'App Version' }, { title: 'OS Version' }, { title: '' }]}
				rows={this.displayDevices()}
				isEditable={false}
			/>,
			<Table headers={[{ title: 'Room Names' }]} rows={sections.rooms} isEditable={false} />,
		];
	};

	getCameraTypeDeviceSetting = async deviceId => {
		if (deviceId) {
			let { deviceSettings, error } = await getDeviceSetting(deviceId, SettingTypes.CAMERA_TYPE);
			if (error) {
				this.toggleAlert('message', 'Failed to load default camera setting!', 'error');
				return;
			}
			let defaultCamType = DefaultCameraTypes.HELLO.toString();
			if (deviceSettings.length) {
				defaultCamType = deviceSettings[0].value.toString();
			}
			this.setState(prevState => ({
				selectedDevice: { ...prevState, deviceId, defaultCamType },
			}));
		}
	};

	toggleSelectDefaultCamera = async deviceId => {
		this.setState({
			isSelectDefaultCameraModalOpen: !!deviceId,
			isSettingsModalLoading: true,
		});
		await this.getCameraTypeDeviceSetting(deviceId);
		this.setState({
			isSettingsModalLoading: false,
		});
	};

	onChangeDefaultCamera = defaultCamType => {
		if (defaultCamType) {
			this.setState(prevState => ({
				selectedDevice: { ...prevState.selectedDevice, defaultCamType },
			}));
		}
	};

	render() {
		const hospital = this.getCurrentHospital();
		const sections = mapSectionsToRender(this.props.treeData.tree, hospital, this.toggleEditSectorModal, this.initDeleteOrg);
		const userRole = getUserRole();
		const descriptionBoxData = this.getDescriptionBoxData(sections);

		return (
			<div className='organization'>
				<h3>
					<span>{hospital.hospitalName}</span>
					{userRole === UserRoles.ADMIN && (
						<>
							<Button text='Edit Hospital Details' onClick={() => this.toggleEditSectorModal(hospital)} />
							<Button
								text='Delete Hospital'
								variant='red'
								onClick={() => this.initDeleteOrg({ level: DeviceListLevel.HOSPITAL, id: this.props.match.params.hospitalId, name: hospital.hospitalName })}
							/>
						</>
					)}

					{this.state.devices.length > 0 && (
						<CSVLink
							data-cy='exportAsCSVBtn'
							className='button white'
							data={this.exportAsCsv()}
							filename={`${hospital.hospitalName}-${(+new Date()).toString()}.csv`}>
							Export as CSV
						</CSVLink>
					)}
				</h3>
				<DescriptionBox data={descriptionBoxData} />
				<Tabs links={this.getLinks()} components={this.getComponents(sections)} />
				<Modal
					modalSelector='unassignDeviceModal'
					display={this.state.isDeleteDeviceModalOpen}
					isLoading={this.state.isDeleteDeviceModalLoading}
					position='center'
					submitButtonText='Unassign'
					onModalSubmit={this.unAssignDevice}
					onModalClose={this.toggleDeleteDeviceModal}>
					<Form title='Unassign device' onSubmit={event => event.preventDefault()}>
						<p>Are you sure you want to unassign device {this.state.selectedDevice.serialNumber}? Neither you or patient won't be able to make any calls.</p>
					</Form>
				</Modal>

				<Modal
					modalSelector='rebootDeviceModal'
					display={this.state.isRebootDeviceModalOpen}
					position='center'
					submitButtonText='Reboot'
					onModalSubmit={this.rebootDevice}
					onModalClose={this.toggleRebootDeviceModal}>
					<Form title='Reboot device' onSubmit={event => event.preventDefault()}>
						<p>Why do you want to reboot device {this.state.selectedDevice.serialNumber}?</p>
						<Input
							type='text'
							value={this.state.rebootReason}
							validationOptions={{}}
							placeholder='Describe the reboot reason. . .'
							onChange={e => this.setState({ rebootReason: e.target.value })}
							inputSelector='rebootReasonInput'
						/>
					</Form>
				</Modal>

				<Modal
					display={this.state.isUpdateDeviceModalOpen}
					position='center'
					submitButtonText='Update'
					onModalSubmit={this.forceUpdateDevice}
					onModalClose={this.toggleUpdateDeviceModal}>
					<Form title='Force update device' onSubmit={event => event.preventDefault()}>
						<p>Are you sure you want to force update device {this.state.selectedDevice.serialNumber}?</p>
					</Form>
				</Modal>

				<Modal
					modalSelector='deleteSectorModal'
					display={this.state.isDeleteOrgUnitModalOpen}
					position='center'
					submitButtonText='Delete'
					onModalSubmit={this.deleteOrgUnitSubmit}
					onModalClose={this.toggleDeleteOrgUnitModal}>
					<Form title='Warning' onSubmit={event => event.preventDefault()}>
						<p>
							Are you sure you want to delete {this.state.selectedOrg.name}? Any devices related to it will be deleted and neither Virtual Care Providers or
							patients won't be able to make any calls.
						</p>
					</Form>
				</Modal>

				<Modal
					className='crop-modal'
					display={this.state.isUploadLogoModalOpen}
					position='center'
					submitButtonText='Save'
					onModalSubmit={this.makeClientCrop}
					onModalClose={this.toggleHospitalLogoCrop}
					isLoading={this.state.isHospitalLogoModalLoading}>
					{this.state.src && (
						<div>
							<ReactCrop src={this.state.src} crop={this.state.crop} ruleOfThirds={true} onImageLoaded={this.onImageLoaded} onChange={this.onCropChange} />
						</div>
					)}
				</Modal>

				{this.state.isEditSectorModalOpen && (
					<EditSectorModal
						onToggleRoomAlert={this.toggleAlert}
						onEditSector={this.updateTree}
						onModalClose={this.toggleEditSectorModal}
						isEditSectorModalOpen={this.state.isEditSectorModalOpen}
						sectorData={this.state.sectorData}
						parentSectorName={this.state.parentSectorName}
						parentSectorType={this.state.parentSectorType}
						onSelectFile={this.onSelectFile}
						onFileInputFocus={() => this.setState({ fileError: false })}
						hospitalLogo={this.state.hospitalLogo}
						fileError={this.state.fileError}
						roomId={this.state.roomId}
					/>
				)}
				<Modal
					modalSelector='toggleDeviceAutoAnswerSettingModal'
					display={this.state.isDeviceAutoAnswerModalOpen}
					position='right'
					hideCloseButton={true}
					submitButtonText=''
					isLoading={this.state.isSettingsModalLoading}
					onModalClose={this.closeAutoAnswerSettingModal}>
					<Form title='Enable Auto-answer' onSubmit={event => event.preventDefault()}>
						<div className='auto-answer-container'>
							<p>Auto-answer</p>
							{this.state.selectedDevice?.isAutoAnswerOn ? (
								<i
									className='material-icons'
									data-cy='autoAnswerOn'
									onClick={() => this.updateDeviceSettings(SettingTypes.AUTO_ANSWER)}
									data-tooltip='On'
									data-position='center'>
									toggle_on
								</i>
							) : (
								<i
									className='material-icons-outlined'
									data-cy='autoAnswerOff'
									onClick={() => this.updateDeviceSettings(SettingTypes.AUTO_ANSWER)}
									data-tooltip='Off'
									data-position='center'>
									toggle_off
								</i>
							)}
						</div>
						<p>If you enable this option, as soon as a provider makes a call, the device will ring for a few seconds and it will auto answer.</p>
					</Form>
				</Modal>
				<DefaultCameraModal
					display={this.state.isSelectDefaultCameraModalOpen}
					isLoading={this.state.isSettingsModalLoading}
					onModalClose={() => this.toggleSelectDefaultCamera()}
					onModalSubmit={this.updateDeviceSettings}
					onChange={this.onChangeDefaultCamera}
					selectedDevice={this.state.selectedDevice}
				/>
				<Alert display={this.state.message} fixed={true} hideCloseButton={true} message={this.state.messageAlert} variant={this.state.variantAlert} />
			</div>
		);
	}
}

export default connect(
	state => state.organization,
	dispatch => bindActionCreators(actionCreators, dispatch)
)(withRouter(Hospital));
